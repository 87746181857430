import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authService from '../../../services/authService';
import PanelWithImageLayout from '../../components/layouts/PanelWithImageLayout';

/*
 * The magic login page
 * @returns {JSX.Element} - The magic login page.
 */
export default function MagicLoginPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const invalidMagicLinkError = t('toast.error.invalidMagicLink');

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('link');
  if (token === null) {
    toast.error(invalidMagicLinkError);
    navigate('/login');
    return <></>;
  }

  const redirectAfterLogin = useCallback(async () => {
    try {
      await authService.loginMagicLink(token);
    } catch (error) {
      toast.error(invalidMagicLinkError);
      navigate('/login');
    }

    const businessName = queryParams.get('businessName');
    const businessType = queryParams.get('businessType');
    const businessDescription = queryParams.get('businessDescription');
    const connectedMembers = queryParams.get('connectedMembers') === 'true';
    const communityLink = queryParams.get('communityLink');

    if (businessName && businessType) {
      navigate('/onboarding', {
        state: { businessName, businessType, businessDescription, connectedMembers, communityLink },
      });
    } else {
      navigate('/');
    }
  }, [token, navigate, location]);

  return (
    <PanelWithImageLayout image={`${process.env.PUBLIC_URL}/assets/misc/connect.svg`} border>
      <PanelWithImageLayout.Header>
        <h1 className="font-serif text-2xl font-medium text-primary-900">
          {t('page.magicLogin.continue')}
        </h1>
      </PanelWithImageLayout.Header>
      <div className="flex h-full w-full flex-1 flex-col gap-4">
        <div className="flex gap-1 rounded-full bg-secondary-200 px-2">
          <button
            type="button"
            className="flex w-full items-center rounded-b-md p-1 px-4 py-2 text-sm font-bold"
            onClick={() => redirectAfterLogin()}>
            <RiArrowRightFill className="icon mr-2 h-5 w-5" />
            <span className="inline whitespace-nowrap">{t('general.continue')}</span>
          </button>
        </div>
      </div>
    </PanelWithImageLayout>
  );
}
