import React from 'react';
import { useSelector } from 'react-redux';
import { Communications } from '../../types/communication';
import useSelectedBusiness from './useSelectedBusiness';
import { RootState } from '../../redux/reducers';

export default function useCommunications(): Communications | null {
  const { businessId } = useSelectedBusiness() ?? {};
  if (!businessId) return null;

  return useSelector((state: RootState) => state.communications.communications[businessId] ?? null);
}
