import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { subscriptionService } from '../../../services/subscriptionService';

export default function PaymentCompletionPage(): JSX.Element {
  const [status, setStatus] = useState<string>('loading');
  const { t } = useTranslation();
  const business = useSelectedBusiness();
  const navigate = useNavigate();

  useEffect((): void => {
    const params = new URLSearchParams(window.location.search);
    const redirectStatus: string = params.get('redirect_status')!;
    const priceId = params.get('priceId');
    const customerId = params.get('customerId');

    if (!redirectStatus) {
      navigate('/overview');
    }

    if (priceId && customerId && business && business.businessId) {
      setStatus('loading');
      subscriptionService
        .createSubscription(business.businessId, priceId, customerId)
        .then(() => navigate('/subscriptions/manage'));
    } else {
      setStatus(redirectStatus);
    }
  }, []);

  if (status === 'loading') {
    return (
      <div className="mx-auto my-auto flex flex-col">
        <h1 className="mx-auto text-3xl font-bold">{t(`page.payment.loading`)}</h1>
      </div>
    );
  }
  if (status === 'succeeded') {
    return (
      <div className="mx-auto my-auto flex flex-col">
        <h1 className="mx-auto text-3xl font-bold">{t(`toast.success.subscriptionPayment`)}</h1>
        <p className="mx-auto text-xl font-bold">
          {t(`toast.success.subscriptionPaymentSubtitle`)}
        </p>
      </div>
    );
  }
  if (status === 'failed') {
    return (
      <div className="mx-auto my-auto flex flex-col">
        <h1 className="mx-auto text-3xl font-bold">Er is iets misgegaan</h1>
        <p className="mx-auto text-xl font-bold">Probeer het later opnieuw!</p>
      </div>
    );
  }
  return <div>PaymentCompletionPage</div>;
}
