import React, { useEffect, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ModalLayout from '../layouts/ModalLayout';
import ModalHeader from '../misc/ModalHeader';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import CancelSaveButton from '../misc/CancelSaveButton';
import { RootState } from '../../../redux/reducers';
import usePersonalConnections from '../../../hooks/account/usePersonalConnections';
import ConnectionsList from '../misc/ConnectionsList';
import connectionService from '../../../services/connectionService';
import userService from '../../../services/userService';
import SearchBar from '../forms/SearchBar';
import inviteService from '../../../services/inviteService';

interface InviteConnectionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function InviteConnectionModal({
  open,
  setOpen,
}: InviteConnectionModalProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const connections = useSelector((state: RootState) => state.connections);
  const personalConnections = usePersonalConnections();

  const [userIds, setUserIds] = useState<string[]>([]);
  const [notInvitedUserIds, setNotInvitedUserIds] = useState<number[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (!connections.length) connectionService.getConnections();
    if (!personalConnections.length) userService.getConnections();
  }, []);

  useEffect(() => {
    setUserIds([]);
    (async () => {
      const result = await inviteService.getNotInvitedConnections(
        personalConnections.map((c) => c.userId),
      );
      setNotInvitedUserIds(result);
    })();
  }, [open]);

  const handleSubmit = async () => {
    await inviteService.inviteConnections(userIds);
    toast.success(t('component.modal.addConnection.sent', { count: userIds.length }));
    setOpen(false);
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="z-50 mx-6 flex w-fit min-w-[450px] max-w-[650px] flex-col gap-[20px] rounded-[20px] bg-secondary-50 px-10 py-6 pb-4 pt-5">
      <ModalHeader
        icon={FaPaperPlane}
        title={t('component.modal.addConnection.title')}
        subtitle={t('component.modal.addConnection.subtitle')}
      />

      <div className="w-full border-b border-primary-300" />

      <SearchBar search={search} setSearch={setSearch} />
      <ConnectionsList
        selected={userIds}
        setSelected={setUserIds}
        hasSearch={false}
        className="border-none py-0"
        search={search}
        connections={personalConnections.map(({ userId, firstName, lastName }) => ({
          id: userId,
          alias: `${firstName}+${lastName}`,
          disabled:
            connections.map((c) => c.userId).includes(userId) ||
            !notInvitedUserIds.includes(userId),
        }))}
      />

      <div className="w-full border-b border-primary-300" />

      <div className="flex flex-col items-end gap-4 sm:flex-row sm:items-center sm:justify-between">
        <p>
          {userIds.length} {t('component.modal.addConnection.selected')}
        </p>
        <CancelSaveButton
          buttonText={t('component.modal.addConnection.invite')}
          onCancel={() => setOpen(false)}
          disabled={userIds.length === 0}
          onSave={handleSubmit}
        />
      </div>
    </ModalLayout>
  );
}
