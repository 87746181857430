import React from 'react';
import { CRMIntegration, CRMType } from '../../../../types/CRMIntegration';
import EBoekhoudenAuthParamsModal from './eboekhouden/AuthParamsModal';

interface AuthParamsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  crmIntegration: CRMIntegration;
  onSubmitted: (auth: unknown) => void;
}

export default function AuthParamsModal({
  open,
  setOpen,
  crmIntegration,
  onSubmitted,
}: AuthParamsModalProps): JSX.Element {
  if (crmIntegration.crm === CRMType.E_BOEKHOUDEN) {
    return <EBoekhoudenAuthParamsModal open={open} setOpen={setOpen} onSubmitted={onSubmitted} />;
  }

  return <></>;
}
