import React, { useEffect, useState } from 'react';
import { HiCheck, HiTrash } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ImageInput from './ImageInput';
import businessService from '../../../services/businessService';
import ApiImage from '../misc/ApiImage';
import { UploadedPictureModel } from '../../../services/model/businessService.model';

interface BusinessImageInputProps {
  image: string | undefined;
  setImage: (image: string | undefined) => void;
  className?: string;
}

export default function BusinessImageInput({
  image,
  setImage,
  className,
}: BusinessImageInputProps): JSX.Element {
  const { t } = useTranslation();
  const [images, setImages] = useState<UploadedPictureModel[]>([]);

  useEffect(() => {
    businessService.getPicturesForBusiness().then(setImages);
  }, []);

  const handlePictureOpload = async (picture: string | undefined) => {
    if (!picture) {
      setImage(undefined);
    } else {
      const file = await fetch(picture).then((res) => res.blob() as Promise<File>);
      businessService.uploadPicture(file).then((path) => {
        setImage(path.picture);
        setImages([...images, path]);
      });
    }
  };

  return (
    <>
      <div className="flex gap-6">
        {images.length < 5 && (
          <ImageInput
            iconClassName="size-12"
            className={`object-cover ${className}`}
            image={image}
            setImage={handlePictureOpload}
          />
        )}

        {images.map((p) => (
          <div
            key={p.id}
            className={`relative aspect-square rounded-[10px] ${className} ${
              image === p.picture ? 'outline outline-2 outline-primary' : ''
            }`}>
            <div
              onClick={() => setImage(image === p.picture ? undefined : p.picture)}
              className="h-full w-full">
              <ApiImage
                key={p.id}
                path={p.picture}
                className="h-full w-full cursor-pointer rounded-[8px] object-cover"
              />
            </div>
            <button
              type="button"
              onClick={() =>
                businessService.deletePicture(p.id).then(() => {
                  setImages(images.filter((i) => i.id !== p.id));
                  if (image === p.picture) setImage(undefined);
                })
              }
              className="absolute -right-2 -top-2 rounded-full bg-secondary-200 p-1">
              <HiTrash className="h-4 w-4 text-primary-900" />
            </button>
            <div
              className={classNames(
                'absolute left-1 top-1 flex h-5 w-5 items-center justify-center rounded-full bg-secondary-50/50',
                { '!bg-secondary-50': image === p.picture },
              )}>
              {' '}
              {image === p.picture && <HiCheck className="bg-primary-50" />}
            </div>
          </div>
        ))}
      </div>
      {images.length === 5 && (
        <p className="">{t('page.createEvent.fields.coverPhoto.maxPhotos')}</p>
      )}
    </>
  );
}
