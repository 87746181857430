import { RootState } from '../redux/reducers';
import { store } from '../redux/store';
import {
  CreateEventModel,
  Event,
  EventParticipationAnswer,
  RecurrenceEditType,
  UpdateEventModel,
} from '../types/event';
import httpService from './httpService';
import {
  createEvent as createEventRedux,
  deleteEvent as deleteEventRedux,
  setAttendance as setAttendanceRedux,
  setEvents,
  updateEvent as updateEventRedux,
} from '../redux/slices/eventSlice';
import { addDateToEvent } from '../redux/transformers';

async function createEvent(
  event: CreateEventModel,
  participantsIds: number[] = [],
  inviteAll = true,
): Promise<Event> {
  const { data } = await httpService.post<Event>(`/business/events`, {
    ...event,
    participantsIds,
    inviteAll,
  });
  const transformed = addDateToEvent(data);
  const { selectedBusiness } = (store.getState() as RootState).application;
  store.dispatch(createEventRedux({ businessId: selectedBusiness!, event: transformed }));
  return transformed;
}

function generateEventInviteLink(eventId: number): Promise<string> {
  return httpService.post(`/events/${eventId}/generate-invite-link`).then(({ data }) => data);
}

function getUpcomingEvents(): Promise<Event[]> {
  return httpService.get<Event[]>('/business/events/upcoming').then(({ data }) => {
    const events = data.map(addDateToEvent);
    const { selectedBusiness } = (store.getState() as RootState).application;
    store.dispatch(setEvents({ businessId: selectedBusiness!, events }));
    return events;
  });
}

async function getEventAnalytics(from: Date, to: Date): Promise<Event[]> {
  const { data } = await httpService.get<Event[]>('/business/events/analytics', {
    params: { from, to },
  });
  const events = data.map(addDateToEvent);
  const { selectedBusiness } = (store.getState() as RootState).application;
  store.dispatch(setEvents({ businessId: selectedBusiness!, events }));
  return events;
}

async function prependPastEvents(): Promise<Event[]> {
  return httpService.get<Event[]>('/business/events/past').then(({ data }) => {
    const pastEvents = data.map(addDateToEvent);
    const { selectedBusiness } = (store.getState() as RootState).application;
    const upcomingEvents = (store.getState() as RootState).events.eventMap[selectedBusiness!];
    const merged = [...pastEvents, ...upcomingEvents];
    store.dispatch(setEvents({ businessId: selectedBusiness!, events: merged }));
    return merged;
  });
}

async function deleteEvent(
  eventId: number,
  deleteRecurrences: RecurrenceEditType = RecurrenceEditType.SINGLE,
): Promise<void> {
  await httpService.delete(`/events/${eventId}/${deleteRecurrences}`);
  const { selectedBusiness } = (store.getState() as RootState).application;
  store.dispatch(deleteEventRedux({ businessId: selectedBusiness!, eventId }));
}

async function updateEvent(
  eventId: number,
  event: UpdateEventModel,
  updateRecurrences?: RecurrenceEditType,
): Promise<Event> {
  const { data } = await httpService.put<Event>(`/events/${eventId}`, {
    ...event,
    updateRecurrences,
  });
  const transformed = addDateToEvent(data);
  const { selectedBusiness } = (store.getState() as RootState).application;
  store.dispatch(updateEventRedux({ businessId: selectedBusiness!, event: transformed }));
  return transformed;
}

async function setAttendance(
  eventId: number,
  participantId: number,
  answer: EventParticipationAnswer,
): Promise<void> {
  await httpService.post(`/events/${eventId}/attendance/${participantId}`, { answer });
  const { selectedBusiness } = (store.getState() as RootState).application;
  store.dispatch(setAttendanceRedux({ businessId: selectedBusiness!, participantId, answer }));
}

async function remindUserForEvent(eventId: number, participantId: number): Promise<void> {
  await httpService.post(`/events/${eventId}/remind/${participantId}`);
}

function deleteParticipant(eventId: number, participantId: number): Promise<Event> {
  const {
    application: { selectedBusiness },
    events: { eventMap },
  } = store.getState() as RootState;

  const participantIds = eventMap[selectedBusiness!]
    ?.find((e) => e.id === eventId)
    ?.participants.map((p) => p.id)
    .filter((id) => id !== participantId);

  if (!participantIds) throw new Error('Event Not Found');
  return updateParticipants(eventId, participantIds);
}

async function updateParticipants(eventId: number, participantsIds: number[]): Promise<Event> {
  await httpService.put(`/events/${eventId}/participants`, { participantsIds });
  return fetchEvent(eventId);
}

async function fetchEvent(eventId: number): Promise<Event> {
  const { data } = await httpService.get<Event>(`/events/${eventId}`);
  const transformed = addDateToEvent(data);
  const { selectedBusiness } = (store.getState() as RootState).application;
  store.dispatch(updateEventRedux({ businessId: selectedBusiness!, event: transformed }));
  return transformed;
}

async function pickDateOptions(eventId: number, dateOptionIds: number[]): Promise<Event[]> {
  const { data } = await httpService.post(`/events/${eventId}/date-options/convert/bulk`, {
    dateOptionIds,
  });
  await getUpcomingEvents();
  return data;
}

export default {
  createEvent,
  getUpcomingEvents,
  prependPastEvents,
  getEventAnalytics,
  deleteEvent,
  updateEvent,
  setAttendance,
  remindUserForEvent,
  updateParticipants,
  fetchEvent,
  deleteParticipant,
  pickDateOptions,
  generateEventInviteLink,
};
