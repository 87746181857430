import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { PropsOf } from '@headlessui/react/dist/types';
import PrimaryButton from '../buttons/PrimaryButton';
import PrimaryLinkButton from '../buttons/PrimaryLinkButton';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  className?: string;
  primaryButton?: ReactElement<PropsOf<typeof PrimaryButton>> | ReactElement<PropsOf<typeof PrimaryLinkButton>>;
}

export default function PageHeader({ title, subtitle, className, primaryButton }: PageHeaderProps): JSX.Element {
  return (
    <header className={twMerge('flex flex-col gap-1 pb-4', className)}>
      <div className="flex justify-between items-center">
        <h1 className="font-serif text-3xl font-semibold">{title}</h1>
        {primaryButton}
      </div>
      {subtitle && <p className="text-gray-500 lg:w-1/2 pb-6">{subtitle}</p>}
      {!subtitle && <p className="lg:w-3/5 " />}
      {/* Button middle */}
      {/* Check length */}
      {/* Make search specific */}
    {/* Icon animation down instead of diagonal on score cards */}
    {/* Make avatars task list style */}
    </header>
  );
}
