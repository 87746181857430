import React from 'react';
import { twMerge } from 'tailwind-merge';
import { IconType } from 'react-icons';
import { FaCheck } from 'react-icons/fa';
import { FaCakeCandles, FaTicket } from 'react-icons/fa6';
import { FiMapPin, FiPhone } from 'react-icons/fi';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { formatAddress, formatFields } from '../../../utils/formatUtils';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';

import IconBadge from '../icons/IconBadge';

interface ConnectionWithIconsProps {
  connection: Connection;
}

function IconBadgeAdjusted({ icon, className = '' }: { icon: IconType; className?: string }) {
  return <IconBadge icon={icon} className={twMerge('h-fit w-fit rounded-[6px] p-1', className)} />;
}

export default function ConnectionWithIcons({ connection }: ConnectionWithIconsProps): JSX.Element {
  const transformedConnection = { ...connection, fields: formatFields(connection.fields) };
  const {
    fields: { phoneNumber, address, birthDate, custom, email, communicationName },
  } = transformedConnection;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        {Object.keys(custom).length > 0 &&
          (communicationName || phoneNumber || address || birthDate || email) && (
            <p className="text-xs font-normal">Algemeen</p>
          )}
        {communicationName && (
          <div className="flex items-center py-1">
            <IconBadgeAdjusted icon={FaTicket} />
            <p className="px-3 text-sm">{communicationName}</p>
          </div>
        )}
        {birthDate && (
          <div className="flex items-center py-1">
            <IconBadgeAdjusted icon={FaCakeCandles} />
            <p className="px-3 text-sm">{birthDate}</p>
          </div>
        )}
      </div>
      {phoneNumber && (
        <div className="flex items-center py-1">
          <IconBadgeAdjusted icon={FiPhone} />
          <p className="px-3 text-sm">{phoneNumber}</p>
        </div>
      )}
      {email && (
        <div className="flex items-center py-1">
          <IconBadgeAdjusted icon={HiOutlineEnvelope} />
          <p className="px-3 text-sm">{email}</p>
        </div>
      )}
      {address && (
        <div className="flex">
          <IconBadgeAdjusted icon={FiMapPin} className="my-1" />
          <p className="px-3 pt-1 text-sm">{formatAddress(address)}</p>
        </div>
      )}
      {Object.keys(custom).map((field): JSX.Element => {
        const currentField =
          transformedConnection.fields.custom[
            field as keyof typeof transformedConnection.fields.custom
          ];
        if (!currentField) return <></>;
        return (
          <div className="flex flex-col">
            <p className="whitespace-nowrap pt-2 text-xs font-normal">
              {capitalizeFirstLetter(field)}
            </p>
            <div className="flex-transformedConnection flex items-center">
              <IconBadgeAdjusted icon={FaCheck} />
              <p className="whitespace-nowrap pl-2 text-sm/6 font-normal">{currentField}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
