import React, { useEffect } from 'react';
import classNames from 'classnames';
import { AttendantData, Event, EventParticipationAnswer } from '../../../types/event';
import eventUtils from '../../../utils/eventUtils';
import { COLOR_PRIMARY, COLOR_PRIMARY_300, COLOR_PRIMARY_900 } from '../../../constants';
import ProfilePicture from '../misc/ProfilePicture';
import AttendanceTooltip from './components/AttendanceTooltip';

type PodiumGraphProps = {
  events: Event[];
};

type RankingPlace = 'first' | 'second' | 'third';

type PlaceData = {
  id: number;
  place: RankingPlace;
  color: string;
  heightClassname: string;
  podiumOffsetClassname: string;
  name: string;
  userData: AttendantData;
};

type Ranking = PlaceData[];

export default function PodiumGraph({ events }: PodiumGraphProps): JSX.Element {
  const [top3, setTop3] = React.useState<Ranking>([]);

  useEffect(() => {
    const [first, second, third] = eventUtils.transformToAttendanceToTop3(events);
    setTop3(() => [
      toPlaceData('second', second),
      toPlaceData('first', first),
      toPlaceData('third', third),
    ]);
  }, [events]);

  return (
    <div className="flex min-h-[20rem] w-full flex-col">
      <div className="flex items-end justify-center gap-4">
        {top3.map((entry, index) => (
          <div className="group relative w-full max-w-20">
            <div key={`${entry.id}_pfp_podium_${index}`} className="flex flex-col items-center">
              <ProfilePicture
                className="mb-3 h-10 w-10"
                picture={entry.userData.picture}
                alias={entry.name.split(' ').join('+')}
              />
              <div
                style={{ backgroundColor: entry.color }}
                className={classNames('w-full max-w-14 rounded-t-xl', entry.heightClassname)}
              />
              {entry.place !== 'third' && (
                <div
                  className={classNames(
                    'w-full rounded-t-xl border-l-2 border-r-2 border-t-2 border-secondary-50 bg-secondary-200',
                    entry.podiumOffsetClassname,
                  )}
                />
              )}
            </div>
            <AttendanceTooltip
              className="invisible absolute left-1/2 top-[calc(100%+0.5rem)] z-20 w-52 -translate-x-1/2 opacity-0 transition group-hover:visible group-hover:opacity-100"
              key={`tooltip_${entry.id}`}
              active
              payload={[
                {
                  payload: {
                    ...entry.userData,
                    place: entry.place,
                    bgColor: entry.color,
                  },
                },
              ]}
            />
          </div>
        ))}
      </div>

      <div className="mx-auto h-10 w-3/4 rounded-t-xl border-2 border-secondary-50 bg-secondary-200 shadow-lg shadow-secondary-200 max-lg:w-full max-md:w-3/4 max-sm:w-full" />
    </div>
  );
}

const PLACE_TO_COLOR_MAP = {
  first: COLOR_PRIMARY_300,
  second: COLOR_PRIMARY,
  third: COLOR_PRIMARY_900,
};

const PLACE_TO_HEIGHT_MAP = {
  first: 'h-64',
  second: 'h-48',
  third: 'h-32',
};

const PLACE_TO_PODIUM_OFFSSET = {
  first: 'h-8',
  second: 'h-6',
  third: 'h-0',
};

function toPlaceData(place: RankingPlace, payload: [string, AttendantData] | undefined): PlaceData {
  if (!payload) {
    return {
      id: -1,
      place,
      name: '',
      color: PLACE_TO_COLOR_MAP[place],
      heightClassname: PLACE_TO_HEIGHT_MAP[place],
      podiumOffsetClassname: 'h-0',
      userData: {
        id: -1,
        name: '',
        yesFraction: 0,
        [EventParticipationAnswer.YES]: 0,
        [EventParticipationAnswer.NO]: 0,
        [EventParticipationAnswer.MAYBE]: 0,
        [EventParticipationAnswer.PENDING]: 0,
      },
    };
  }

  return {
    id: payload[1].id,
    podiumOffsetClassname: PLACE_TO_PODIUM_OFFSSET[place],
    place,
    name: payload[0],
    color: PLACE_TO_COLOR_MAP[place],
    heightClassname: PLACE_TO_HEIGHT_MAP[place],
    userData: payload[1],
  };
}
