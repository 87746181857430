/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Connection, ConnectionType } from '../../../redux/slices/connectionsSlice';
import Button from '../buttons/Button';
import localContactService from '../../../services/localContactService';
import HoverButton from '../buttons/HoverButton';
import { PendingBadge, UnverifiedBadge, VerifiedBadge } from '../icons/ConnectionTypeBadge';

interface ConnectionStatusProps {
  connection: Connection;
  interactive?: boolean;
  className?: string;
}

export default function ConnectionStatus({
  connection,
  interactive = false,
  className = '',
}: ConnectionStatusProps): JSX.Element {
  if (connection.type === ConnectionType.PERSONAL_MOMENT_CONNECTION)
    return <VerifiedStatus className={className} />;
  if (connection.type === ConnectionType.B2C_CONNECTION)
    return <VerifiedStatus className={className} />;
  if (connection.type === ConnectionType.LOCAL_CONTACT && connection.inviteSent)
    return <PendingStatus className={className} />;
  return (
    <UnverifiedStatus connection={connection} interactive={interactive} className={className} />
  );
}

interface StatusProps {
  className: string;
}

function VerifiedStatus({ className }: StatusProps) {
  return <VerifiedBadge className={className} />;
}

function PendingStatus({ className }: StatusProps) {
  return <PendingBadge className={className} />;
}

type UnverifiedStatusProps = StatusProps & {
  connection: Connection;
  interactive: boolean;
};

function UnverifiedStatus({ connection, interactive, className }: UnverifiedStatusProps) {
  const { t } = useTranslation();

  if (!interactive) return <UnverifiedBadge className={className} />;

  return (
    <HoverButton clickable={false}>
      <HoverButton.Button>
        <UnverifiedBadge className={className} />
      </HoverButton.Button>

      <HoverButton.Div
        className={classNames(
          'absolute -top-7 left-[180%] flex h-fit w-[400px] flex-col items-start justify-center gap-4 whitespace-normal rounded-[20px] bg-primary-900 px-4 py-5 transition-all',
        )}>
        <div className="z-10 flex flex-col gap-1">
          <h1 className="font-serif text-[22px] leading-6 text-secondary-50">
            {t('component.connectionStatus.pending.title')}
          </h1>
          <p className="text-sm font-normal text-secondary-50">
            {t('component.connectionStatus.pending.subtitle')}
          </p>
        </div>
        {connection.fields.EMAIL && (
          <Button
            variant="secondary"
            onClick={(e) => {
              localContactService
                .inviteContacts([connection.id])
                .then(() => toast.success(t('toast.success.inviteSent_one')));
              e.stopPropagation();
            }}>
            {t('component.connectionStatus.pending.button')}
          </Button>
        )}
        <div className="absolute -left-2 top-6 h-10 w-10 rotate-45 rounded-[4px] bg-primary-900" />
      </HoverButton.Div>
    </HoverButton>
  );
}
