import React from 'react';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';

export default function AboutPage(): JSX.Element {
  const { t } = useBusinessTranslation();
  return (
    <div className="flex min-h-[calc(100vh-202px)] w-full flex-1 flex-col items-center py-10">
      <div className="w-full max-w-5xl">
        <h2 className="mb-2 text-4xl font-medium">{t('page.about.about.title')}</h2>
        <p>{t('page.about.about.text')}</p>
        <h2 className="mb-2 mt-8 text-4xl font-medium">{t('page.about.why.title')}</h2>
        <p>{t('page.about.why.text')}</p>
        <h2 className="mt-4 text-2xl font-medium">{t('page.about.privacy.title')}</h2>
        <p>{t('page.about.privacy.text')}</p>

        <h2 className="mt-4 text-2xl font-medium">{t('page.about.life.title')}</h2>
        <p>{t('page.about.life.text')}</p>
        <h2 className="mb-2 mt-8 text-4xl font-medium">{t('page.about.contact.title')}</h2>
        <p>
          {t('page.about.contact.text')}
          <a
            href="mailto:?to=info@veraconnect.nl"
            target="_blank"
            rel="noreferrer"
            className="font-medium underline">
            info@veraconnect.nl
          </a>
          .
        </p>
      </div>
    </div>
  );
}
