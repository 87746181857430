import { useTranslation } from 'react-i18next';
import React from 'react';
import Slider from './Slider';

interface ExtraSliderOptionProps {
  textKey?: string;
  active: boolean;
  onChange: (active: boolean) => void;
  children?: React.ReactNode;
  items?: { label: string; value: boolean }[];
  label?: string;
}

export default function ExtraOption({
  textKey,
  active,
  children,
  onChange,
  items,
  label,
}: ExtraSliderOptionProps) {
  const { t } = useTranslation();

  const labelString =
    label || (textKey ? t(`page.createEvent.additional.titles.${textKey}`) : undefined);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex w-full items-center justify-between">
        <Slider
          items={
            items ?? [
              { label: t('general.no'), value: false },
              { label: t('general.yes'), value: true },
            ]
          }
          label={labelString}
          className="w-full"
          value={active}
          onChange={onChange}
        />
      </div>
      {active && <div className="flex w-full items-center gap-2">{children}</div>}
    </div>
  );
}
