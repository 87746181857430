import React, { ComponentProps, useEffect, useLayoutEffect, useState } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaCheckCircle,
  FaCircle,
  FaPencilAlt,
  FaRegClock,
  FaUserPlus,
} from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaXmark } from 'react-icons/fa6';
import dayjs from 'dayjs';
import StepIndicator from '../../components/misc/StepIndicator';
import Button from '../../components/buttons/Button';
import VeraPanel from '../../components/misc/VeraPanel';
import {
  CreateEventModel,
  DateOption,
  EventCustomField,
  EventType,
  EventViewType,
  Frequency,
} from '../../../types/event';
import IconBadge from '../../components/icons/IconBadge';
import { eventTypeIcons } from '../../../utils/iconUtils';
import {
  CustomFieldType,
  CustomLabelField,
  CustomMultipleChoiceField,
} from '../../../services/model/inviteService.model';
import Labeled from '../../components/misc/Labeled';
import eventService from '../../../services/eventService';
import AutoCompleteLocationInput from '../../components/forms/AutoCompleteLocationInput';
import EventDatesForm, { EventDatesInfo } from '../../components/forms/EventDatesForm';
import ModalLayout from '../../components/layouts/ModalLayout';
import dateUtils from '../../../utils/dateUtils';
import eventUtils from '../../../utils/eventUtils';
import EventNotificationForm from '../../components/forms/EventNotificationForm';
import { AdditionalEventDataMap } from '../../../types/misc';
import DropdownSelect from '../../components/forms/DropdownSelect';
import BusinessImageInput from '../../components/forms/BusinessImageInput';
import Slider from '../../components/misc/Slider';
import { DURATIONS_MS } from '../../../constants';
import { formatUrl, isValidHttpUrl } from '../../../utils/validationUtils';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';
import connectionService from '../../../services/connectionService';
import { RootState } from '../../../redux/reducers';
import { Connection } from '../../../redux/slices/connectionsSlice';
import Avatar from '../../components/misc/Avatar';
import { getUserId } from '../../../services/httpService';
import SearchBar from '../../components/forms/SearchBar';
import { searchFilter } from '../../../utils/filterUtils';
import Input from '../../components/misc/Input';
import ExtraOption from '../../components/misc/ExtraOption';
import DatePicker from '../../components/forms/DatePicker';

const { ONE_HOUR } = DURATIONS_MS;

interface CreateEventPageProps {
  type: EventViewType;
}
export default function CreateEventPage({ type }: CreateEventPageProps) {
  const location = useLocation();
  const [step, setStep] = useState<number>(0);
  const [event, setEvent] = useState<Partial<CreateEventModel>>(location.state?.event ?? {});

  const handleStep = (diff: 1 | -1) => setStep((prev) => prev + diff);

  const props: ViewProps = { setStep: handleStep, event, setEvent, type };

  useLayoutEffect(
    () => setEvent({ ...event, isDatePicker: type === EventViewType.DATE_PICKER }),
    [],
  );

  const steps = [
    <ChooseTypeView {...props} />,
    <FillDetailsView {...props} />,
    type === EventViewType.DATE_PICKER ? (
      <ChooseDateOptionsView {...props} />
    ) : (
      <ChooseDateView {...props} />
    ),
    <AdditionalDataView {...props} />,
    <ParticipantsView {...props} />,
  ];

  return (
    <div className="flex h-full w-full flex-1 flex-col">
      <StepIndicator
        steps={steps.length}
        currentStep={step}
        onStepChange={setStep}
        className="mb-6"
      />
      {steps[step]}
    </div>
  );
}

type CreateEventLayoutProps = {
  disabled?: boolean;
  showNext?: boolean;
  showPrevious?: boolean;
  setStep: (step: 1 | -1) => void;
  children: React.ReactNode;
  customButton?: React.ReactNode;
} & ComponentProps<typeof VeraPanel>;

function CreateEventLayout({
  disabled = false,
  showNext = true,
  showPrevious = true,
  setStep,
  customButton,
  children,
  ...props
}: CreateEventLayoutProps) {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-col gap-4 md:gap-2">
      <VeraPanel {...props}>{children}</VeraPanel>
      <div className="mt-4 flex w-full justify-end gap-2 border-t border-primary-300 py-4">
        {showPrevious && (
          <Button variant="tertiary" onClick={() => setStep(-1)}>
            <FaArrowLeft className="size-5" />
            {t('general.back')}
          </Button>
        )}
        {showNext && (
          <Button variant="primary" disabled={disabled} onClick={() => !disabled && setStep(1)}>
            {t('general.continue')}
            <FaArrowRight className="size-5" />
          </Button>
        )}
        {customButton && customButton}
      </div>
    </div>
  );
}

interface ViewProps {
  setStep: (step: 1 | -1) => void;
  event: Partial<CreateEventModel>;
  setEvent: (event: Partial<CreateEventModel>) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  type: EventViewType;
}

function ChooseTypeView({ setStep, event, setEvent }: ViewProps) {
  const { t } = useTranslation();

  const setSelected = (type: EventType | undefined) => setEvent({ ...event, type });
  const selected = event.type;

  return (
    <CreateEventLayout
      title={t('page.createEvent.type.title')}
      subtitle={t('page.createEvent.type.subtitle')}
      setStep={setStep}
      showPrevious={false}
      disabled={!selected}>
      {Object.values(EventType).map((type) => (
        <div
          key={type}
          onClick={() => setSelected(type === selected ? undefined : type)}
          className={classNames(
            'flex w-full cursor-pointer justify-between gap-2 rounded-[8px] border border-secondary-200 px-4 py-2 transition-all',
            { 'bg-secondary-200': selected === type },
          )}>
          <IconBadge icon={eventTypeIcons[type]} className="rounded-[6px] p-1" />
          <div className="flex w-full items-center justify-between">
            <h3 className="font-serif text-lg">
              {t(`page.createEvent.type.type.${type.toLowerCase()}.title`)}
            </h3>
            <input type="checkbox" checked={selected === type} />
          </div>
        </div>
      ))}
    </CreateEventLayout>
  );
}

function FillDetailsView({ setStep, event, setEvent }: ViewProps) {
  const { t } = useTranslation();

  const { title, description, picture, location, onlineLocation, locationUrl } = event;

  const [errors, setErrors] = useState<Record<string, string>>({});

  useLayoutEffect(() => {
    if (title && description && location && picture) return;
    setEvent({
      ...event,
      title: title ?? '',
      description: description ?? '',
      location: location ?? '',
      picture: picture ?? undefined,
    });
  }, []);

  useEffect(() => {
    setErrors({});
  }, [event]);

  const validate = () => {
    const foundErrors: Record<string, string> = {};

    if (!title) {
      foundErrors.title = t('page.createEvent.fields.title.error');
    }

    if (onlineLocation && locationUrl && !isValidHttpUrl(locationUrl)) {
      foundErrors.locationUrl = t('page.createEvent.fields.location.online.error');
    }

    if (Object.keys(foundErrors).length) {
      setErrors(foundErrors);
      return false;
    }

    return true;
  };

  return (
    <CreateEventLayout
      title={t('page.createEvent.info.title')}
      subtitle={t('page.createEvent.info.subtitle')}
      setStep={(diff) => {
        if (diff < 0) {
          setStep(diff);
          return;
        }

        if (!validate()) return;

        if (onlineLocation && locationUrl) {
          setEvent({ ...event, locationUrl: formatUrl(locationUrl) }); // Format the locationUrl on submit inc case of online location
        }

        setStep(diff);
      }}
      disabled={Object.keys(errors).length > 0}>
      <Input
        id="title"
        label={t('general.title')}
        type="text"
        value={title}
        onChange={(e) => setEvent({ ...event, title: e.target.value })}
        placeholder={t('page.createEvent.fields.title.placeholder')}
        error={errors.title}
        required
      />
      <Slider
        label={t('general.location')}
        items={[
          { label: t('page.createEvent.fields.location.offline.title'), value: false },
          { label: t('page.createEvent.fields.location.online.title'), value: true },
        ]}
        value={onlineLocation}
        onChange={(isOnline) => {
          setEvent({
            ...event,
            onlineLocation: isOnline,
            location: undefined,
            locationUrl: undefined,
          });
        }}
      />
      {onlineLocation ? (
        <Input
          type="text"
          placeholder={t('page.createEvent.fields.location.online.placeholder')}
          value={locationUrl ?? ''}
          onChange={(e) => setEvent({ ...event, locationUrl: e.target.value })}
          error={errors.locationUrl}
        />
      ) : (
        <AutoCompleteLocationInput
          placeholder={t('page.createEvent.fields.location.offline.placeholder')}
          location={location ?? ''}
          setLocation={({ location: loc, locationUrl: url }) =>
            setEvent({ ...event, location: loc, locationUrl: url })
          }
        />
      )}
      <Input
        id="description"
        label={t(`general.description`)}
        type="textarea"
        className="resize-none"
        placeholder={t('page.createEvent.fields.description.placeholder')}
        value={description}
        onChange={(e) => setEvent({ ...event, description: e.target.value })}
      />
      <Labeled label={t('general.picture')}>
        <BusinessImageInput
          image={picture}
          className="h-[100px] w-[100px]"
          setImage={(image) => setEvent({ ...event, picture: image })}
        />
      </Labeled>
    </CreateEventLayout>
  );
}

function ChooseDateOptionsView({ setStep, event, setEvent }: ViewProps) {
  const { dateOptions } = event;

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (dateOptions) return;
    setEvent({ ...event, dateOptions: [] });
  }, []);

  if (!dateOptions) return null;

  const handleOptionChange =
    (index: number) => (option: Omit<DateOption, 'id' | 'answers'> | undefined) => {
      const newOptions = [...dateOptions];
      if (!option) newOptions.splice(index, 1);
      else newOptions[index] = option;
      setEvent({
        ...event,
        dateOptions: eventUtils.sortDateOptions(newOptions as DateOption[], 'time'),
      });
    };

  const latestDateOption = dateOptions[dateOptions.length - 1];

  return (
    <>
      <CreateEventLayout
        title={t('page.createEvent.dateOptions.title')}
        subtitle={t('page.createEvent.dateOptions.subtitle')}
        setStep={setStep}
        disabled={dateOptions?.length < 2}
        className="gap-4">
        <h2 className="text-xl font-medium">{t('page.createEvent.dateOptions.options')}</h2>
        {dateOptions.map((o, index) => (
          <DateOptionCard key={index} option={o} setOption={handleOptionChange(index)} />
        ))}

        <Button variant="tertiary" onClick={() => setOpen(true)}>
          <AiOutlinePlus className="size-5" />
          {t('page.createEvent.dateOptions.button')}
        </Button>

        {dateOptions.length > 0 && (
          <Labeled label={t('component.eventDatesForm.deadline')}>
            <DatePicker
              className="w-full"
              date={event.deadline}
              minDateTime={dayjs(new Date())}
              minutesStep={5}
              maxDate={latestDateOption?.startTime ? dayjs(latestDateOption.startTime) : undefined}
              setDate={(date) => setEvent({ ...event, deadline: date })}
            />
          </Labeled>
        )}
      </CreateEventLayout>
      <DateOptionsModal
        open={open}
        type="CREATE"
        setOpen={setOpen}
        setOptions={(options) =>
          setEvent({
            ...event,
            dateOptions: eventUtils.sortDateOptions(
              [...(dateOptions ?? []), ...options] as DateOption[],
              'time',
            ),
          })
        }
      />
    </>
  );
}

interface DateOptionCardProps {
  option: Omit<DateOption, 'id' | 'answers'>;
  setOption: (option: Omit<DateOption, 'id' | 'answers'> | undefined) => void;
}

function DateOptionCard({ option, setOption }: DateOptionCardProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-col gap-2">
        <p className="font-serif text-xl">{eventUtils.formatDate(option)}</p>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <IconBadge icon={FaRegClock} className="rounded-[6px]" />
            <p className="text-sm">{eventUtils.formatDate(option, 'time')}</p>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => setModalOpen(true)}
              className="flex-shrink-0 rounded-full p-3"
              variant="primary">
              <FaPencilAlt className="h-4 w-4" />
            </Button>
            <Button
              className="flex-shrink-0 rounded-full p-3"
              variant="secondary"
              onClick={() => setOption(undefined)}>
              <HiTrash className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
      <DateOptionsModal
        open={modalOpen}
        type="EDIT"
        setOpen={setModalOpen}
        setOption={setOption}
        option={option}
      />
    </>
  );
}

interface DateOptionsModalCreateProps {
  setOptions: (options: Omit<DateOption, 'id' | 'answers'>[]) => void;
  type: 'CREATE';
}

interface DateOptionsModalEditProps {
  option: Omit<DateOption, 'id' | 'answers'>;
  setOption: (option: Omit<DateOption, 'id' | 'answers'>) => void;
  type: 'EDIT';
}
type DateOptionsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
} & (DateOptionsModalCreateProps | DateOptionsModalEditProps);

function DateOptionsModal({ open, setOpen, type, ...props }: DateOptionsModalProps) {
  const { option, setOption } = props as DateOptionsModalEditProps;
  const { setOptions } = props as DateOptionsModalCreateProps;

  const { t } = useTranslation();

  const [info, setInfo] = useState<EventDatesInfo | Omit<DateOption, 'id' | 'answers'>>();
  const [view, setView] = useState<'FORM' | 'DATE'>('DATE');

  useLayoutEffect(() => {
    if (!open) return;
    setInfo(type === 'CREATE' ? { isRecurring: false } : option);
  }, [open]);

  const handleCreate = () => {
    if (type !== 'CREATE') throw new Error('Cannot create a date option in edit mode');
    const {
      frequency,
      recurrenceEndDate,
      startTime: startTimeFirst,
      endTime: endTimeFirst,
    } = info as EventDatesInfo;
    if (!startTimeFirst) return;

    const startTimes = dateUtils.getDatesBetween(
      startTimeFirst,
      recurrenceEndDate ?? startTimeFirst,
      frequency ?? Frequency.DAILY,
    );

    const difference = endTimeFirst ? endTimeFirst.getTime() - startTimeFirst.getTime() : undefined;

    const dateOptions = startTimes.map((startTime) => {
      const endTime = difference ? new Date(startTime.getTime() + difference) : undefined;
      return { startTime, endTime };
    });

    setOptions(dateOptions);
    setOpen(false);
  };

  const handleEdit = () => {
    if (type !== 'EDIT') throw new Error('Cannot edit a date option in create mode');
    setOption(info as Omit<DateOption, 'id' | 'answers'>);
    setOpen(false);
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className={classNames('z-50 mx-6 flex flex-col gap-6 rounded-[20px] bg-secondary-50', {
        'w-full min-w-[380px] max-w-[600px] px-12 py-8': view === 'FORM',
      })}>
      <EventDatesForm
        info={info as unknown as EventDatesInfo}
        setInfo={setInfo}
        includeRecurrence={type === 'CREATE'}
        setView={setView}
        isDatePicker
        includeDeadline={false}
      />
      {view === 'FORM' && (
        <Button
          disabled={!info?.startTime}
          onClick={type === 'CREATE' ? handleCreate : handleEdit}
          variant="primary"
          className="w-full justify-center">
          {t(`component.modal.dateOptions.${type.toLowerCase()}`)}
        </Button>
      )}
    </ModalLayout>
  );
}

function ChooseDateView({ setStep, event, setEvent }: ViewProps) {
  const { startTime, isRecurring } = event;

  const { t } = useTranslation();

  const [errors, setErrors] = useState<Record<string, string>>({});

  useLayoutEffect(() => {
    if (startTime && isRecurring) return;

    setEvent({
      ...event,
      isRecurring: isRecurring ?? false,
      ...(!startTime && {
        startTime: dateUtils.roundToNearest5Minutes(new Date(new Date().getTime() + ONE_HOUR)),
      }),
    });
  }, []);

  useEffect(() => {
    setErrors({});
  }, [event]);

  const validate = () => {
    const foundErrors: Record<string, string> = {};

    if (!startTime) {
      foundErrors.startTime = t('page.createEvent.fields.startDate.required');
    }

    if (event.endTime && startTime && event.endTime < startTime) {
      foundErrors.endTime = t('page.createEvent.fields.endDate.invalid');
    }

    if (startTime && startTime < new Date()) {
      foundErrors.startTime = t('page.createEvent.fields.startDate.invalid');
    }

    if (!isRecurring && event.deadline && event.deadline < new Date()) {
      foundErrors.deadline = t('page.createEvent.fields.deadline.invalid');
    }

    if (!isRecurring && event.deadline && startTime && event.deadline > startTime) {
      foundErrors.deadline = t('page.createEvent.fields.deadline.beforeStartDate');
    }

    if (Object.keys(foundErrors).length) {
      setErrors(foundErrors);
      return false;
    }

    return true;
  };

  return (
    <CreateEventLayout
      title={t('page.createEvent.date.title')}
      subtitle={t('page.createEvent.date.subtitle')}
      setStep={(diff) => {
        if (diff < 0) {
          setStep(diff);
          return;
        }

        if (!validate()) return;

        if (startTime?.getTime() === event.endTime?.getTime()) {
          setEvent({ ...event, endTime: undefined }); // Unset endTime if it is the same as startTime
        }

        setStep(diff);
      }}
      disabled={startTime === undefined}
      className="gap-4">
      <EventDatesForm
        info={event as EventDatesInfo}
        setInfo={(info) => setEvent({ ...event, ...info })}
        errors={errors}
      />
    </CreateEventLayout>
  );
}

function AdditionalDataView({ setStep, event, setEvent }: ViewProps) {
  const { customFields } = event;
  const { t } = useTranslation();

  const [options, setOptions] = useState<AdditionalEventDataMap>({
    poll: !!event.customFields,
    capacity: !!event.maximumAttendees,
    notificationSettings: !!event.notificationSettings,
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  useLayoutEffect(() => {
    if (customFields) return;
    setEvent({ ...event, customFields: customFields ?? undefined });
  }, []);

  useEffect(() => {
    setErrors({});
  }, [event]);

  const validate = () => {
    const foundErrors: Record<string, string> = {};

    if (
      customFields?.some(
        (f) =>
          !f.label || (f.type === CustomFieldType.MULTIPLE_CHOICE && f.options.some((o) => !o)),
      )
    ) {
      foundErrors.customFields = 'true';
    }

    if (Object.keys(foundErrors).length) {
      setErrors(foundErrors);
      return false;
    }

    return true;
  };

  return (
    <CreateEventLayout
      setStep={(diff) => {
        if (diff < 0) {
          setStep(diff);
          return;
        }

        if (!validate()) return;

        setStep(diff);
      }}
      title={t('page.createEvent.additional.title')}
      subtitle={t('page.createEvent.additional.subtitle')}
      disabled={Object.keys(errors).length > 0}>
      <div className="flex flex-col gap-6">
        <ExtraOption
          textKey="poll"
          active={options.poll}
          onChange={(a) => {
            const value = Boolean(a);
            setOptions({ ...options, poll: value });
            setEvent({
              ...event,
              customFields: value ? [eventUtils.EMPTY_CUSTOM_FIELD] : undefined,
            });
          }}>
          <EventPollForm
            fields={customFields ?? [eventUtils.EMPTY_CUSTOM_FIELD]}
            setFields={(fields) => {
              setEvent({ ...event, customFields: fields });
              if (fields.length === 0) setOptions({ ...options, poll: false });
            }}
            showErrors={!!errors.customFields}
          />
        </ExtraOption>

        <ExtraOption
          textKey="capacity"
          active={options.capacity}
          onChange={(a) => {
            const value = Boolean(a);
            setOptions({ ...options, capacity: value });
            setEvent({ ...event, maximumAttendees: undefined });
          }}>
          <Input
            type="number"
            min="1"
            value={event.maximumAttendees?.toString().replace(/^0+/, '') || '0'}
            onChange={(e) => {
              if (!Number.isNaN(+e.target.value))
                setEvent({ ...event, maximumAttendees: +e.target.value });
            }}
          />
        </ExtraOption>
        <ExtraOption
          textKey="notification"
          active={options.notificationSettings}
          onChange={(a) => {
            const value = Boolean(a);
            if (value) {
              setEvent({
                ...event,
                notificationSettings: eventUtils.getInitialNotificationSettings(
                  event.isDatePicker!,
                ),
              });
            } else {
              setEvent({ ...event, notificationSettings: undefined });
            }
            setOptions({ ...options, notificationSettings: value });
          }}>
          <EventNotificationForm
            settings={event.notificationSettings ?? {}}
            setSettings={(settings) => setEvent({ ...event, notificationSettings: settings })}
          />
        </ExtraOption>
      </div>
    </CreateEventLayout>
  );
}

interface EventPollFormProps {
  fields: EventCustomField[];
  setFields: (fields: EventCustomField[]) => void;
  showErrors?: boolean;
}

function EventPollForm({ fields, setFields, showErrors }: EventPollFormProps) {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col gap-6">
      {fields.map((field, index) => (
        <CustomFieldForm
          key={index}
          field={field}
          setField={(f) => {
            const newFields = [...fields];
            if (f) newFields[index] = f;
            if (!f) newFields.splice(index, 1);
            setFields(newFields);
          }}
          showErrors={showErrors}
        />
      ))}
      <Button
        variant="tertiary"
        onClick={() =>
          setFields([
            ...fields,
            {
              label: '',
              mandatory: false,
              options: ['', ''],
              type: CustomFieldType.MULTIPLE_CHOICE,
            } as EventCustomField,
          ])
        }>
        <AiOutlinePlus className="size-5" />
        {t('general.add')}
      </Button>
    </div>
  );
}

interface CustomFieldFormProps {
  field: EventCustomField;
  setField: (field: EventCustomField | undefined) => void;
  showErrors?: boolean;
}

function CustomFieldForm({ field, setField, showErrors }: CustomFieldFormProps) {
  const { t } = useTranslation();

  const getForm = () => {
    switch (field.type) {
      case CustomFieldType.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceForm
            field={field}
            setField={(f) => setField(f as EventCustomField)}
            showErrors={showErrors}
          />
        );
      case CustomFieldType.TEXT:
        return (
          <TextForm
            field={field}
            setField={(f) => setField(f as EventCustomField)}
            showErrors={showErrors}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">
        <DropdownSelect
          className="w-full"
          options={[CustomFieldType.TEXT, CustomFieldType.MULTIPLE_CHOICE].map((type) => ({
            value: type,
            label: t(`component.eventPollForm.types.${type}`),
          }))}
          value={field.type}
          setValue={(f) => {
            if (f === field.type) return;
            if (f === CustomFieldType.MULTIPLE_CHOICE)
              setField({
                ...field,
                type: CustomFieldType.MULTIPLE_CHOICE,
                options: ['', ''],
                mandatory: false,
              });
            else
              setField({
                ...field,
                type: CustomFieldType.TEXT,
                options: undefined,
              } as unknown as EventCustomField);
          }}
        />
        <Button
          variant="tertiary"
          className="flex-shrink-0 rounded-full p-3.5"
          onClick={() => setField(undefined)}>
          <HiTrash className="size-5" />
        </Button>
      </div>
      {getForm()}
    </div>
  );
}

interface MultipleChoiceFormProps {
  field: CustomMultipleChoiceField | undefined;
  setField: (field: CustomMultipleChoiceField | undefined) => void;
  showErrors?: boolean;
}

function MultipleChoiceForm({ field, setField, showErrors }: MultipleChoiceFormProps) {
  const { t } = useTranslation();
  if (!field) return null;

  const { options } = field;

  return (
    <div className="flex w-full flex-col gap-4">
      <TextForm
        showErrors={showErrors}
        field={field}
        setField={(f) => setField(f as CustomMultipleChoiceField)}
      />
      <Labeled label={t('component.eventPollForm.options')}>
        <div className="flex flex-col gap-2">
          {options.map((option, index) => (
            <div className="flex items-start gap-2">
              <Input
                placeholder={t('component.eventPollForm.optionPlaceholder', { count: index + 1 })}
                key={index}
                type="text"
                value={option}
                onChange={(e) => {
                  const newOptions = [...options];
                  newOptions[index] = e.target.value;
                  setField({ ...field, options: newOptions });
                }}
                {...(showErrors && {
                  error: !option ? t('component.eventPollForm.errors.emptyOption') : undefined,
                })}
                appendButton={
                  <Button
                    className="flex-shrink-0 rounded-full p-3.5"
                    disabled={options.length <= 2}
                    onClick={() =>
                      setField({ ...field, options: options.filter((_, i) => i !== index) })
                    }>
                    <AiOutlineClose className="h-4 w-4" />
                  </Button>
                }
              />
            </div>
          ))}
        </div>
      </Labeled>
      <Button
        className="p-0 underline"
        onClick={() => {
          setField({ ...field, options: [...options, ''] });
        }}>
        {t('component.eventPollForm.addOption')}
      </Button>
    </div>
  );
}

interface TextFormProps {
  field: CustomLabelField;
  setField: (field: CustomLabelField | undefined) => void;
  showErrors?: boolean;
}

function TextForm({ field, setField, showErrors }: TextFormProps) {
  const { t } = useTranslation();
  const { label, mandatory } = field;
  return (
    <>
      <Labeled label={t('component.eventPollForm.question.title')}>
        <div className="flex gap-2">
          <Input
            placeholder={t('component.eventPollForm.question.placeholder')}
            type="text"
            value={label}
            onChange={(e) => setField({ ...field, label: e.target.value })}
            {...(showErrors && {
              error: !label ? t('component.eventPollForm.errors.emptyQuestion') : undefined,
            })}
          />
          <div className="w-[46px]" />
        </div>
      </Labeled>
      <Labeled label={`${t('general.mandatory')}?`}>
        <Slider
          items={[
            { label: t('general.no'), value: false },
            { label: t('general.yes'), value: true },
          ]}
          value={mandatory}
          onChange={(value) => setField({ ...field, mandatory: value })}
        />
      </Labeled>
    </>
  );
}

export const inviteButtonKey = 'inviteButton';

function ParticipantsView({ setStep, event, setEvent, type }: ViewProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { participantsIds } = event;

  const [adding, setAdding] = useState<boolean>(false); // Whether the user is adding participants or not

  const userId = getUserId();
  const connections = useSelector((state: RootState) => state.connections).filter(
    (c) => c.userId && c.userId !== userId,
  ) as Connection[];

  useLayoutEffect(() => {
    if (participantsIds && participantsIds.length > 0) return;
    setEvent({ ...event, participantsIds: connections.map((c) => +c.userId!) });
  }, []);

  useBusinessEffect(() => {
    connectionService.getConnections();
  }, []);

  const handleSubmit = () => {
    const toInvite = participantsIds ?? [];
    const inviteAll = connections.length === toInvite.length;

    eventService
      .createEvent(event as CreateEventModel, toInvite, inviteAll)
      .then(() => navigate(`/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}`));
  };

  const toggleParticipant = (idOfUser: number) =>
    setEvent({
      ...event,
      participantsIds: event.participantsIds?.includes(idOfUser)
        ? event.participantsIds?.filter((id) => id !== idOfUser)
        : [...(event.participantsIds ?? []), idOfUser],
    });

  const participants = connections.filter((c) => participantsIds?.includes(+c.userId!));

  return (
    <CreateEventLayout
      setStep={setStep}
      title={t('page.createEvent.participants.title')}
      subtitle={t('page.createEvent.participants.subtitle')}
      disabled={adding}
      showNext={false}
      customButton={
        <Button variant="primary" onClick={handleSubmit} disabled={adding}>
          {t('general.create')}
          <FaArrowRight className="size-5" />
        </Button>
      }>
      {adding ? (
        <ParticipantsAddingList
          connections={connections}
          event={event}
          toggleParticipant={toggleParticipant}
          setAdding={setAdding}
        />
      ) : (
        <ParticipantsList
          participants={participants}
          setAdding={setAdding}
          toggleParticipant={toggleParticipant}
        />
      )}
    </CreateEventLayout>
  );
}

const MIN_SEARCH = 5;

type ParticipantListProps = {
  participants: Connection[];
  setAdding: (adding: boolean) => void;
  toggleParticipant: (id: number) => void;
};

function ParticipantsList({ participants, setAdding, toggleParticipant }: ParticipantListProps) {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const filteredParticipants = participants.filter(({ fields }) =>
    searchFilter(fields.FIRST_NAME + fields.LAST_NAME, search),
  );

  return (
    <div className="flex flex-col gap-4">
      {participants.length > MIN_SEARCH && (
        <SearchBar
          search={search}
          setSearch={setSearch}
          inputProps={{
            placeholder: t('page.createEvent.participants.searchParticipantsPlaceholder'),
          }}
        />
      )}
      <div className="grid h-full grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
        <div className="flex flex-col w-fit h-fit items-center gap-2 hover:opacity-80">
          <Button
            key={inviteButtonKey}
            variant="primary"
            onClick={() => setAdding(true)}
            className="h-[150px] w-[150px] items-center justify-center">
            <FaUserPlus className="w-16 h-16" />
          </Button>
          <span
            onClick={() => setAdding(true)}
            className="cursor-pointer font-[600] text-primary-900">
            {t('page.createEvent.participants.invite')}
          </span>
        </div>

        {filteredParticipants.map(({ id, fields, picture, userId: Uid }) => (
          <div key={id} className="relative h-fit w-fit">
            <div className="flex h-fit w-fit flex-col gap-2">
              <Avatar
                src={picture}
                alias={`${fields.FIRST_NAME}+${fields.LAST_NAME}`}
                className="h-[150px] w-[150px] rounded-[20px] text-3xl"
              />
              <p className='max-w-[150px] font-semibold text-center'>{`${fields.FIRST_NAME} ${fields.LAST_NAME}`}</p>
            </div>
            <button
              type="button"
              onClick={() => toggleParticipant(+Uid!)}
              className="absolute -right-2 -top-2 rounded-full bg-secondary p-1 text-secondary-50 shadow-xl transition-opacity hover:opacity-90">
              <FaXmark className="size-8" />
            </button>
          </div>
        ))}

        {filteredParticipants.length === 0 && participants.length > 0 && (
          <div className="col-span-3 flex w-2/3 flex-col gap-2 justify-self-center text-center md:col-span-4">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noResults.title')}
            </h2>
            <span>{t('page.createEvent.participants.noResults.subtitle')}</span>
          </div>
        )}

        {participants.length === 0 && (
          <div className="col-span-3 flex w-2/3 flex-col gap-2 justify-self-center text-center md:col-span-4">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noParticipants.title')}
            </h2>
            <span>{t('page.createEvent.participants.noParticipants.subtitle')}</span>
          </div>
        )}
      </div>
    </div>
  );
}

interface ParticipantsAddingListProps {
  connections: Connection[];
  event: Partial<CreateEventModel>;
  toggleParticipant: (id: number) => void;
  setAdding: (adding: boolean) => void;
}

function ParticipantsAddingList({
  connections,
  event,
  toggleParticipant,
  setAdding,
}: ParticipantsAddingListProps) {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const filteredConnections = connections.filter(({ fields }) =>
    searchFilter(fields.FIRST_NAME + fields.LAST_NAME, search),
  );

  return (
    <div className="flex flex-col gap-4">
      {connections.length > MIN_SEARCH && (
        <SearchBar
          search={search}
          setSearch={setSearch}
          inputProps={{ placeholder: t('page.createEvent.participants.searchContactsPlaceholder') }}
        />
      )}
      <div className="flex h-full flex-col gap-4">
        {filteredConnections.map(
          ({ fields: { FIRST_NAME, LAST_NAME }, id, picture, userId: Uid }) => (
            <div
              key={id}
              onClick={() => toggleParticipant(Uid!)}
              className="flex w-full cursor-pointer items-center justify-between transition-opacity hover:opacity-80 active:opacity-65">
              <div className="flex items-center gap-2">
                <Avatar
                  src={picture}
                  alias={`${FIRST_NAME}+${LAST_NAME}`}
                  className="size-24 rounded-xl text-3xl"
                />
                <p>{`${FIRST_NAME} ${LAST_NAME}`}</p>
              </div>
              {event.participantsIds?.includes(+Uid!) ? (
                <FaCheckCircle className="size-8 text-secondary" />
              ) : (
                <FaCircle className="size-8 text-secondary-200" />
              )}
            </div>
          ),
        )}

        {filteredConnections.length === 0 && connections.length > 0 && (
          <div className="flex w-full flex-col gap-2 justify-self-center text-center">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noResults.title')}
            </h2>
            <span>{t('page.createEvent.participants.noResults.subtitle')}</span>
          </div>
        )}

        {connections.length === 0 && (
          <div className="flex w-full flex-col gap-2 justify-self-center text-center">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noConnections.title')}
            </h2>
            <span>{t('page.createEvent.participants.noConnections.subtitle')}</span>
          </div>
        )}

        <Button variant="primary" onClick={() => setAdding(false)} className="mt-auto">
          {t('page.createEvent.participants.invite')}
          <FaUserPlus className="size-5" />
        </Button>
      </div>
    </div>
  );
}
