import React from 'react';
import { HiOutlineKey } from 'react-icons/hi';
import { FiSettings } from 'react-icons/fi';
import {
  FaCalendar,
  FaCalendarCheck,
  FaEuroSign,
  FaListCheck,
  FaRectangleList,
  FaStar,
  FaTicket,
} from 'react-icons/fa6';
import { FaFileAlt, FaThumbtack } from 'react-icons/fa';
import { HiOutlineChatBubbleLeftEllipsis, HiOutlineHome, HiUserGroup } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { TbScoreboard } from 'react-icons/tb';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';
import DropdownItem from './DropdownItem';
import Navigation from './Navigation';
import { isCommunity, isMoments } from '../../../../../constants';
import { RootState } from '../../../../../redux/reducers';
import { OnboardingStep, SubscriptionType } from '../../../../../types/misc';
import useActiveSubscription from '../../../../../hooks/business/useActiveSubscription';

export default function DropdownPanel(): JSX.Element {
  const { t } = useBusinessTranslation();
  const {
    application: { onboardingStep },
    connections,
  } = useSelector((state: RootState) => state);
  const activeSubscription = useActiveSubscription();
  const hasSubscription = activeSubscription !== SubscriptionType.FREE;
  const connectionsWithAddress = connections.filter((c) => c.fields.ADDRESS);

  const agenda = [
    {
      icon: <FaCalendar className="h-5 w-5 flex-shrink-0" />,
      name: t('page.event.regular.title'),
      path: '/events',
    },
    {
      icon: <FaThumbtack className="flex-shrink-0/> h-5 w-5" />,
      name: t('page.event.date_picker.title'),
      path: '/events/date-picker',
    },
    {
      icon: <FaFileAlt className="h-5 w-5 flex-shrink-0" />,
      name: t('page.agenda.report.title'),
      path: '/events/report',
    },
  ];

  const lists = [
    {
      icon: <TbScoreboard className="h-5 w-5 flex-shrink-0" />,
      name: t('page.lists.score.title'),
      condition: isCommunity,
      path: '/lists/score',
    },
    {
      icon: <FaListCheck className="h-5 w-5 flex-shrink-0" />,
      name: t('page.lists.task.title'),
      condition: isCommunity,
      path: '/lists/task',
    },
  ];

  const general = [
    {
      icon: <FaTicket className="h-5 w-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.orderAddressLabels'),
      condition: isMoments,
      path: '/payment/address-label',
      state: { connections: connectionsWithAddress },
    },
    {
      icon: <FaEuroSign className="h-5 w-5 flex-shrink-0" />,
      name: t('page.settings.subscriptions.title'),
      condition: isCommunity && !hasSubscription,
      path: '/settings/subscriptions',
    },
    {
      icon: <FaStar className="h-5 w-5 flex-shrink-0" />,
      name: t('page.settings.subscriptions.manageSubscriptionLink'),
      condition: isCommunity && hasSubscription,
      path: '/subscriptions/manage',
    },
    {
      icon: <HiOutlineKey className="h-5 w-5 flex-shrink-0" />,
      name: t('page.settings.admins.title'),
      path: '/settings/admins',
    },
    {
      icon: <FiSettings className="h-5 w-5 flex-shrink-0" />,
      name: t('page.settings.general.title'),
      id: 'settings',
      path: '/settings',
    },
  ];

  return (
    <>
      <Navigation
        id="overview"
        icon={<HiOutlineHome className="h-5 w-5" />}
        name={t('general.overview')}
        path="/overview"
      />
      <Navigation
        id="contacts"
        icon={<HiUserGroup className="h-5 w-5" />}
        name={t('page.dashboard.clientList.title')}
        path="/contacts"
      />
      {isCommunity && (
        <Navigation
          id="communication"
          icon={<HiOutlineChatBubbleLeftEllipsis className="h-5 w-5" />}
          name={t('general.communication')}
          path="/communication"
        />
      )}
      {isCommunity && (
        <DropdownItem
          duration={onboardingStep !== undefined ? 100 : undefined}
          id="events"
          open={onboardingStep === OnboardingStep.EVENTS || undefined}
          name={t('general.agenda')}
          icon={FaCalendarCheck}>
          {agenda.map((r) => (
            <Navigation {...r} key={r.path} open />
          ))}
        </DropdownItem>
      )}
      {isCommunity && (
        <DropdownItem
          id="scorelists"
          duration={onboardingStep !== undefined ? 100 : undefined}
          name={t('page.lists.title')}
          icon={FaRectangleList}>
          {lists.map((r) => (
            <Navigation {...r} key={r.path} open />
          ))}
        </DropdownItem>
      )}
      {general
        .filter((r) => r.condition === undefined || r.condition)
        .map((r) => (
          <Navigation {...r} key={r.path} />
        ))}
    </>
  );
}
