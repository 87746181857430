import React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { TbDownload } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/stringUtils';
import Button from '../buttons/Button';
import { InvoiceData } from '../../../types/payments';
import { downloadInvoice } from '../../../utils/subscriptionUtils';
import {
  BaseTable,
  BaseTableBody,
  BaseTableDataRow,
  BaseTableHeader,
  BaseTableHeaderRow,
  BaseTableHelperText,
  BaseTableRowSkeleton,
} from './BaseTable';

type InvoiceTableProps = { invoices: InvoiceData[]; loading: boolean };

export default function InvoicesTable({ invoices, loading }: InvoiceTableProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.invoices' });
  const { t: manageT } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });
  const [idsDownloading, setIdsDownloading] = React.useState<Set<string>>(new Set());

  const handleDownload = async (id: string) => {
    setIdsDownloading((prev) => new Set(prev).add(id));
    downloadInvoice(id).finally(() =>
      setIdsDownloading((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      }),
    );
  };

  return (
    <div className="ml-8">
      <BaseTable>
        <BaseTableHeader>
          <BaseTableHeaderRow>
            <th>{manageT('lastPayments')}</th>
            <th>{manageT('total')}</th>
            <th>{manageT('status')}</th>
          </BaseTableHeaderRow>
        </BaseTableHeader>
        <BaseTableBody>
          <BaseTableHelperText>{t('helper')}</BaseTableHelperText>
          {loading && (
            <BaseTableRowSkeleton />
          )}
          {!loading &&
            invoices.map((invoice) => (
              <BaseTableDataRow>
                <td>{formatDate(new Date(invoice.date * 1000))}</td>
                <td>&euro;{(invoice.amount / 100).toFixed(2)}</td>
                <td>
                  <div className="flex items-center">
                    <p className="uppercase">{invoice.status}</p>
                    <Button
                      className="hover:opacity-75 disabled:opacity-75"
                      disabled={idsDownloading.has(invoice.id)}
                      onClick={() => handleDownload(invoice.id)}>
                      {idsDownloading.has(invoice.id) ? (
                        <CgSpinner size={20} className="animate-spin" />
                      ) : (
                        <TbDownload size={20} />
                      )}
                    </Button>
                  </div>
                </td>
              </BaseTableDataRow>
            ))}
        </BaseTableBody>
      </BaseTable>
    </div>
  );
}
