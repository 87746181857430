import React from 'react';
import { twMerge } from 'tailwind-merge';

interface VeraPanelProps {
  image?: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
  className?: string;
}
export default function VeraPanel({
  image = `${process.env.PUBLIC_URL}/assets/figures/phone_hi.svg`,
  title,
  subtitle,
  children,
  className = '',
}: VeraPanelProps): JSX.Element {
  return (
    <>
      <div className="flex w-full flex-col items-center justify-center gap-2 md:flex-row lg:w-[60%]">
        <img alt="Vera" src={image} className="w-[28%]" />
        <div className="flex w-full flex-col gap-1 lg:mt-10">
          <h1 className="font-serif text-3xl font-semibold">{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
      <div
        className={twMerge('mx-auto flex h-full w-full max-w-[720px] flex-col gap-2', className)}>
        {children}
      </div>
    </>
  );
}
