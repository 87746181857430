import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/misc/PageHeader';
import Button from '../../components/buttons/Button';
import CommunicationsTable from '../../components/tables/CommunicationsTable';
import communicationsService from '../../../services/communicationsService';
import { OptionsButtonOption } from '../../../types/misc';
import { communicationTypeIcons } from '../../../utils/iconUtils';
import { CommunicationType } from '../../../types/communication';
import OptionsButton from '../../components/buttons/OptionsButton';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import PremiumRequiredCard from '../../components/cards/PremiumRequiredCard';
import ModalLayout from '../../components/layouts/ModalLayout';
import { hasRequiredSubscriptionForCommunication } from '../../../utils/subscriptionUtils';
import useCommunications from '../../../hooks/business/useCommunications';
import EmptyScreenView from '../../components/misc/EmptyScreenView';

export default function CommunicationsPage() {
  const { t } = useTranslation();
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const navigate = useNavigate();
  const activeSubscription = useActiveSubscription();
  const { emails, notifications, postcards } = useCommunications() ?? { emails: [], notifications: [], postcards: [] };

  useEffect(() => {
    communicationsService.getEmails();
    communicationsService.getNotifications();
    communicationsService.getPostcards();
  }, []);

  const options: OptionsButtonOption[] = Object.values(CommunicationType).map((type) => ({
    icon: communicationTypeIcons[type],
    name: t(`page.communicationOverview.${type.toLocaleLowerCase()}`),
    onClick: () => {
      if (!hasRequiredSubscriptionForCommunication(type, activeSubscription)) {
        setPremiumModalOpen(true);
        return;
      }

      navigate('/communication/create', { state: { type } })
    },
  }));

  return (
    <>
      <PageHeader
        title={t('page.communicationOverview.title')}
        subtitle={t('page.communicationOverview.subtitle')}
        primaryButton={
        <OptionsButton options={options}>
          <Button variant="primary">
            {t('page.communicationOverview.create')}
            <FaPlus className="h-5 w-5" />
          </Button>
        </OptionsButton>
      } />
      <div className="flex flex-col gap-6">
        {emails.length === 0 && notifications.length === 0 && postcards.length === 0 ? <EmptyScreenView /> : <CommunicationsTable />}

        <ModalLayout closeButton open={premiumModalOpen} setOpen={setPremiumModalOpen}>
          <PremiumRequiredCard />
        </ModalLayout>
      </div>
    </>
  );
}
