import React from 'react';

interface PanelWithImageLayoutProps {
  children: React.ReactNode;
  border?: boolean;
  image: string;
}

function PanelWithImageLayout({
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  border = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  image,
}: PanelWithImageLayoutProps): JSX.Element {
  const header = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Header,
  );

  return (
    <div className="mx-auto my-10 flex h-full w-full max-w-7xl flex-1 flex-col justify-between gap-10 md:flex-row">
      <div className="sticky top-[96px] z-10 hidden h-full min-h-[400px] w-[65%] place-items-center items-center justify-center overflow-hidden rounded-3xl bg-primary-300 py-24 md:flex md:min-h-[600px]">
        <img
          src={`${process.env.PUBLIC_URL}/assets/figures/phone.svg`}
          alt="Vera Phone"
          className="w-[40%]"
        />
      </div>
      <div className="rounded-3x flex h-fit min-h-[400px] w-full flex-col gap-4 bg-secondary-50 pt-4 md:min-h-[600px] md:w-[43%] md:min-w-[420px]">
        <div className="w-full">{header}</div>
        <div className="flex h-full w-full flex-col rounded-b-3xl px-4 md:px-0">
          {React.Children.toArray(children).filter(
            (child) => React.isValidElement(child) && child.type !== Header,
          )}
        </div>
      </div>
    </div>
  );
}

interface HeaderProps {
  children: React.ReactNode;
}

function Header({ children }: HeaderProps): JSX.Element {
  return (
    <div className="flex h-fit w-full flex-col gap-2.5 rounded-3xl bg-secondary-50 px-4 md:rounded-b-none md:p-0">
      {children}
    </div>
  );
}

PanelWithImageLayout.Header = Header;

export default PanelWithImageLayout;
