import React, { useEffect } from 'react';
import classNames from 'classnames';
import Labeled from './Labeled';
import { COLOR_SECONDARY_50 } from '../../../constants';

type Props<T> = {
  items: {
    label: string;
    value: T;
    color?: string;
    activeTextColor?: string;
  }[];
  label?: string;
  disabled?: boolean;
  onChange?: (value: T) => void;
  value?: string | number | boolean;
  className?: string;
};

export default function Slider<T>({
  items,
  label,
  disabled,
  onChange,
  value,
  className,
}: Props<T>): JSX.Element {
  const [activeIndex, setActiveIndex] = React.useState(
    value !== undefined ? items.findIndex((item) => item.value === value) : 0,
  );

  useEffect(() => {
    if (value !== undefined) {
      const index = items.findIndex((item) => item.value === value);
      setActiveIndex(index);
    }
  }, [value]);

  const isActive = (index: number) => index === activeIndex;

  const width = 100 / items.length;

  function slider() {
    return (
      <div
        className={classNames(
          'relative flex flex-row justify-around rounded-xl border-2 border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50',
          className,
        )}>
        {items.map((item, index) => (
          <button
            style={{
              width: `${width}%`,
              ...(isActive(index) && { color: item.activeTextColor }),
            }}
            disabled={disabled}
            className={classNames(
              'relative z-10 h-full min-w-32 p-4 transition-[color,opacity] active:opacity-50',
              { 'opacity-50': disabled },
            )}
            type="button"
            onClick={() => {
              setActiveIndex(index);
              onChange?.(item.value);
            }}>
            {item.label}
          </button>
        ))}

        <div
          className={classNames('absolute top-0 h-full p-2 transition-[left]', {
            'opacity-50': disabled,
          })}
          style={{ width: `${width}%`, left: `${width * activeIndex}%` }}>
          <div
            className="h-full rounded-xl transition-colors duration-300"
            style={{ backgroundColor: items[activeIndex].color ?? COLOR_SECONDARY_50 }}
          />
        </div>
      </div>
    );
  }

  return label ? <Labeled label={label}>{slider()}</Labeled> : slider();
}
