import React from 'react';

export default function QuestionCircleSvg({
  bgColor = '#EDD5C9',
  textColor = '#0A1B89',
}: {
  bgColor?: string;
  textColor?: string;
}): JSX.Element {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" rx="9" fill={bgColor} />
      <path
        d="M7.94727 11.5717V11.4773C7.95389 10.8608 8.01521 10.3703 8.13121 10.0057C8.25053 9.6411 8.41957 9.34612 8.63832 9.12074C8.85707 8.89536 9.12056 8.68987 9.4288 8.50426C9.62766 8.37831 9.80664 8.23745 9.96573 8.08168C10.1248 7.9259 10.2508 7.74692 10.3436 7.54474C10.4364 7.34257 10.4828 7.11884 10.4828 6.87358C10.4828 6.5786 10.4132 6.32339 10.274 6.10795C10.1348 5.89252 9.94916 5.7268 9.71715 5.6108C9.48846 5.49148 9.23325 5.43182 8.95153 5.43182C8.69632 5.43182 8.45271 5.48485 8.2207 5.59091C7.9887 5.69697 7.79646 5.86269 7.644 6.08807C7.49154 6.31013 7.40371 6.59683 7.3805 6.94815H5.86914C5.89234 6.35156 6.04315 5.84777 6.32156 5.43679C6.59996 5.02249 6.96786 4.70928 7.42525 4.49716C7.88595 4.28504 8.39471 4.17898 8.95153 4.17898C9.56138 4.17898 10.095 4.29332 10.5524 4.52202C11.0098 4.7474 11.3644 5.06392 11.6163 5.47159C11.8715 5.87595 11.9991 6.34825 11.9991 6.88849C11.9991 7.25971 11.9411 7.59446 11.8251 7.89276C11.7091 8.18774 11.5434 8.45123 11.3279 8.68324C11.1158 8.91525 10.8606 9.12074 10.5623 9.29972C10.2806 9.47538 10.0519 9.65767 9.87624 9.84659C9.70389 10.0355 9.57795 10.2592 9.4984 10.5178C9.41886 10.7763 9.37577 11.0961 9.36914 11.4773V11.5717H7.94727ZM8.69798 14.5945C8.4262 14.5945 8.19253 14.4983 7.99698 14.3061C7.80143 14.1106 7.70366 13.8752 7.70366 13.6001C7.70366 13.3284 7.80143 13.0964 7.99698 12.9041C8.19253 12.7086 8.4262 12.6108 8.69798 12.6108C8.96644 12.6108 9.19845 12.7086 9.394 12.9041C9.59286 13.0964 9.69229 13.3284 9.69229 13.6001C9.69229 13.7824 9.64589 13.9498 9.55309 14.1023C9.4636 14.2514 9.34428 14.3707 9.19514 14.4602C9.04599 14.5497 8.88027 14.5945 8.69798 14.5945Z"
        fill={textColor}
      />
    </svg>
  );
}
