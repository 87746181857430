import React from 'react';
import { useSelector } from 'react-redux';
import { AiOutlinePlus } from 'react-icons/ai';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { TagState } from '../../../types/Tag';
import { RootState } from '../../../redux/reducers';
import TagButton from '../buttons/TagButton';
import { removeTag } from '../../../utils/connectionUtils';

interface TagDisplayProps {
  connection: Connection;
  withHover?: boolean;
}
export default function TagDisplay({
  connection,
  withHover = false,
}: TagDisplayProps): JSX.Element {
  const tags = useSelector((state: RootState) => state.tags);

  // const textColors = {
  //   '#D9F3F2': '#188682',
  //   '#FFE1E1': '#b55151',
  //   '#FFF0E0': '#a47443',
  //   '#D8E6F2': '#3770a1',
  //   '#F2E4D8': '#c06f28',
  //   '#F2F1D8': '#7c7922',
  // };

  const handleRemoveTag = async (tagId: string): Promise<void> => {
    await removeTag(tagId, { id: connection.id, type: connection.type });
  };

  return (
    <div className="flex-transformedConnection flex-justify-center relative flex h-full w-full flex-wrap items-center gap-2">
      {connection.tags.slice(0, 2).map((tag: string): JSX.Element => {
        const tagElement = tags.find((t: TagState): boolean => t.tagId === tag);
        if (tagElement) {
          return (
            <div
              key={tagElement.tagId}
              className="group/tag content relative flex h-fit w-fit place-items-center items-center justify-center rounded-lg border border-primary-900 px-3 py-1 text-sm text-gray-600">
              {' '}
              <span className="font-medium">{tagElement.name}</span>
              <button
                type="button"
                onClick={() => handleRemoveTag(tagElement.tagId)}
                className={`mt-[2px] ${
                  withHover &&
                  'absolute inset-y-0 right-1 ml-2 rounded-lg bg-secondary-200 opacity-0 transition-all group-hover/tag:opacity-100'
                }`}>
                {' '}
                <AiOutlinePlus className="icon h-5 w-5 rotate-45 text-primary-900" />
              </button>
            </div>
          );
        }
        return <></>;
      })}
      <TagButton connections={[connection]}>
        <div className="group flex cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-primary px-1 py-1 hover:border-opacity-80 hover:bg-primary-300 hover:bg-opacity-20">
          <AiOutlinePlus
            className="h-4 w-4 text-primary group-hover:text-opacity-80"
            strokeWidth={2}
          />
          {connection.tags.length > 2 ? <span>{connection.tags.length - 2}</span> : null}
        </div>
      </TagButton>
    </div>
  );
}
