import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import businessService from '../../../services/businessService';
import ModalLayout from '../layouts/ModalLayout';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import CancelSaveButton from '../misc/CancelSaveButton';

interface DeleteBusinessModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function DeleteBusinessModal({ open, setOpen }: DeleteBusinessModalProps) {
  const navigate = useNavigate();
  const [inputCheck, setInputCheck] = useState<string>('');

  const business = useSelectedBusiness();
  const { t } = useBusinessTranslation();

  const handleClick = async (): Promise<void> => {
    if (inputCheck === business?.name) {
      setOpen(false);
      await businessService.deleteBusiness();
      toast.success(t('toast.success.deletedBusiness'));
      navigate('/');
    } else {
      toast.error(t('toast.error.wrongName'));
    }
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="h-a z-50 mx-6 w-fit min-w-[400px] max-w-[700px] rounded-[20px] bg-secondary-50 px-10 py-6 pb-4 pt-5">
      <div>
        <h2 className="mb-2 text-3xl font-semibold">{t('component.modal.deleteBusiness.title')}</h2>
        <p className="mb-2">{t('component.modal.deleteBusiness.subtitle')}</p>
        <ul data-testid="delete-list" className="mb-4 list-inside list-disc text-primary-900">
          <li>{t('component.modal.deleteBusiness.point1')}</li>
          <li>{t('component.modal.deleteBusiness.point2')}</li>
        </ul>
        <p className="mb-5">
          {t('component.modal.deleteBusiness.confirm', { name: business?.name })}
        </p>
        <input
          type="text"
          placeholder={business?.name}
          className="mb-5 flex rounded border border-gray-300 px-4 py-2"
          onChange={(e): void => setInputCheck(e.target.value)}
        />
      </div>

      <div className="mt-4 flex w-full justify-end">
        <CancelSaveButton
          onCancel={() => setOpen(false)}
          onSave={handleClick}
          disabled={inputCheck !== business?.name}
          buttonText={t('general.delete')}
        />
      </div>
    </ModalLayout>
  );
}
