import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MomentType, ProfileDataType, ProfileField } from '../../../../types/Profile';
import { momentTypeIcons } from '../../../../utils/iconUtils';

interface MomentProps {
  field: ProfileField;
  handleClick: (active: boolean, field: ProfileField) => void;
  active: boolean;
}
export default function Moment({ field, handleClick, active }: MomentProps): JSX.Element {
  const { t } = useTranslation();
  const [year, month, day] = field.moment?.date.split('-') || field.birthDate?.split('-') || [];
  return (
    <div
      key={field.id!}
      onClick={() => handleClick(active, field)}
      className={classNames(
        'mr-6 flex w-full cursor-pointer items-center justify-between rounded-lg border border-secondary-200 px-2 py-1 transition-all',
        {
          'bg-secondary-200': active,
        },
      )}>
      <div className="flex items-center gap-4">
        <div className="flex items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
          {
            momentTypeIcons[
              (field.moment?.momentType || MomentType.CHILDBIRTH) as keyof typeof momentTypeIcons
            ]
          }
        </div>
        <div>
          <p>
            {field.moment?.momentType === MomentType.WEDDING
              ? t('form.userData.wedding')
              : field.dataType === ProfileDataType.BIRTHDATE
                ? t('form.userData.birthDateOwner')
                : t('form.userData.birthDateOther', { name: field.moment?.name })}
          </p>
          <p>{`${day}-${month}-${year}`}</p>
        </div>
      </div>
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 rounded-full border-primary bg-secondary-50 text-primary-300 accent-primary-900 focus:ring-0 focus:ring-transparent"
        checked={active}
      />
    </div>
  );
}
