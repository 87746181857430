import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiCalendar } from 'react-icons/fi';
import { HiUserGroup } from 'react-icons/hi2';
import { FaCalendar } from 'react-icons/fa';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaRegClock } from 'react-icons/fa6';
import PageHeader from '../../components/misc/PageHeader';
import SearchBar from '../../components/forms/SearchBar';
import eventService from '../../../services/eventService';
import useEvents from '../../../hooks/business/useEvents';
import { Event, EventParticipationAnswer, EventViewType } from '../../../types/event';
import eventUtils from '../../../utils/eventUtils';
import Button from '../../components/buttons/Button';
import PrimaryLinkButton from '../../components/buttons/PrimaryLinkButton';
import EmptyScreenView from '../../components/misc/EmptyScreenView';

interface EventsPageProps {
  type: EventViewType;
}

export default function EventsPage({ type }: EventsPageProps) {
  const [search, setSearch] = useState<string>('');
  const events = useEvents(type);
  const { t } = useTranslation();

  useEffect(() => {
    eventService.getUpcomingEvents();
  }, []);

  const filteredEvents = eventUtils
    .sortEvents(events)
    .filter((e) =>
      e.title
        .toLowerCase()
        .replaceAll(' ', '')
        .includes(search.toLowerCase().replaceAll(' ', '')),
    );

  return (
    <>
      <PageHeader
        title={t(`page.event.${type.toLocaleLowerCase()}.title`)}
        subtitle={t(`page.event.${type.toLocaleLowerCase()}.subtitle`)}
        primaryButton={<PrimaryLinkButton
          text={t(`page.event.${type.toLocaleLowerCase()}.add`)} icon={FaCalendar}
          to={`/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}/create`} />}
      />
      <div className="flex flex-1 gap-6 flex-col pb-4">
        <SearchBar inputProps={{ placeholder: t(`page.event.${type.toLocaleLowerCase()}.search`) }} search={search} setSearch={setSearch} className="w-[380px]" />
        <div className="flex flex-wrap gap-4">
          {filteredEvents.length === 0 ? (
            <EmptyScreenView />
          ) : (
            filteredEvents.map((e) => (
              <EventCard key={e.id} event={e} />
            ))
          )}
        </div>
      </div>
    </>
  );
}

interface EventCardProps {
  event: Event;
}

function EventCard({ event }: EventCardProps) {
  const { t } = useTranslation();
  return (
    <Link
      className="group flex h-[230px] w-[250px] cursor-pointer flex-col overflow-hidden rounded-[20px] border border-primary-300"
      to={`/events${event.isDatePicker ? '/date-picker' : ''}/details`}
      state={{ eventId: event.id }}>
      <div className="relative flex h-[90px] w-full justify-end bg-primary-300" />
      {/* <img src={URL} alt="Event" className="object-cover w-full h-full absolute" />
      <div className="flex flex-col gap-2 items-end m-2">
        <div className="bg-secondary-200 gap-1 text-sm flex items-center justify-center py-[2px] px-[6px] rounded-[6px] h-fit w-fit z-[1]">
          <HiOutlineTicket />
          Gratis
        </div>
        <div className="bg-secondary-200 gap-1 text-sm flex items-center justify-center py-[2px] px-[6px] rounded-[6px] h-fit w-fit z-[1]">
          <HiUserGroup className="w-4 h-4" />
          323
        </div>
      </div> */}
      <div className="flex w-full flex-1 flex-col gap-4 bg-primary-900 p-4 text-secondary-50 transition-all group-hover:bg-opacity-80">
        <div className="flex h-fit w-full items-center justify-between">
          <p className="font-serif text-xl text-secondary-50">{event.title}</p>
          <FaArrowRight className="h-5 w-5" />
        </div>
        <div className="flex flex-col gap-2">
          {event.isDatePicker ? (
            <>
              <div className="flex w-full items-center gap-2">
                <FiCalendar className="h-4 w-4" />
                <p className="text-sm text-secondary-50">
                  {`${format(event.dateOptions[0]?.startTime, 'dd-MM-yyyy')} + ${
                    event.dateOptions.length
                  } ${t('general.options')}`}
                </p>
              </div>
              <div className="flex w-full items-center gap-2">
                <HiUserGroup className="h-4 w-4" />
                <p className="text-sm text-secondary-50">
                  {`${
                    event.participants.filter((p) =>
                      p.answers?.some((a) => a.answer !== EventParticipationAnswer.PENDING),
                    ).length
                  } / ${event.memberCount}`}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="flex w-full items-center gap-2">
                <FiCalendar className="h-4 w-4" />
                <p className="text-sm text-secondary-50">
                  {`${format(event.startTime, 'dd-MM-yyyy')}${
                    event.endTime ? ` -> ${format(event.startTime, 'dd-MM-yyyy')}` : ''
                  }`}
                </p>
              </div>
              <div className="flex gap-2">
                <FaRegClock className="h-4 w-4" />
                <p className="text-sm text-secondary-50">{eventUtils.formatDate(event, 'time')}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  );
}
