import classNames from 'classnames';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';
import { BUTTON_STYLE, BUTTON_STYLE_COLLAPSE, LABEL_STYLE, LABEL_STYLE_COLLAPSE } from './style';
import Icon from '../../../icons/Icon';
import HoverButton from '../../../buttons/HoverButton';

interface DropDownItemProps {
  icon?: IconType;
  name: string;
  children?: React.ReactNode;
  id?: string;
  open?: boolean;
  duration?: number;
}

export default function DropdownItem({
  icon,
  name,
  children,
  id = '',
  open: openProp,
  duration,
}: DropDownItemProps): JSX.Element | null {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex w-full flex-col">
      <HoverButton clickable={false} className="w-full">
        <HoverButton.Button>
          <div
            id={id}
            className={twMerge(BUTTON_STYLE, BUTTON_STYLE_COLLAPSE, 'justify-between')}
            onClick={openProp === undefined ? () => setOpen(!open) : () => {}}>
            <div className="flex gap-2">
              <Icon icon={icon} className="h-5 w-5" />
              <p className={twMerge(LABEL_STYLE, LABEL_STYLE_COLLAPSE, 'font-semibold')}>{name}</p>
            </div>
            <FaChevronDown
              className={classNames('h-4 w-4 transition-all lg:group-[.collapsed]:hidden', {
                'rotate-180': openProp ?? open,
              })}
            />
          </div>
        </HoverButton.Button>
        <HoverButton.Div className="transition-50 left-20 top-0 hidden w-[250px] flex-col rounded-[6px] bg-primary-900 px-3 py-2 group-[.open]:hidden lg:flex">
          {children}
          <div className="absolute -left-1 top-[15%] h-[15px] w-[15px] -translate-y-[50%] rotate-45 rounded-[2px] bg-primary-900" />
        </HoverButton.Div>
      </HoverButton>
      <div
        style={{ transitionDuration: `${duration ?? 500}ms` }}
        className={classNames('transition-all', {
          'lg:group-[.open]:py-2': openProp ?? open,
        })}>
        <div
          style={{ transitionDuration: `${duration ?? ((openProp ?? open) ? 1000 : 500)}ms` }}
          className={classNames(
            'ml-4 flex h-fit flex-col gap-2 overflow-hidden lg:overflow-auto lg:group-[.collapsed]:hidden lg:group-[.open]:overflow-hidden',
            {
              'max-h-[0px] ease-[cubic-bezier(0,1,0,1)]':
                openProp === undefined ? !open : !openProp,
              'max-h-[2000px] ease-[ease-in-out]': openProp ?? open,
            },
          )}>
          {children}
        </div>
      </div>
    </div>
  );
}
