import React from 'react';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';
import Icon from './Icon';

interface IconBadgeProps {
  icon: IconType;
  className?: string;
  iconClassName?: string;
}

export default function IconBadge({ icon, className, iconClassName }: IconBadgeProps) {
  return (
    <div
      className={twMerge(
        'flex h-10 w-10 flex-shrink-0 rounded-[6px] bg-secondary text-secondary-50',
        className,
      )}>
      <Icon
        icon={icon}
        className={twMerge('mx-auto my-auto inline-block h-5 w-5', iconClassName)}
        aria-hidden
      />
    </div>
  );
}
