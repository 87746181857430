import React from 'react';
import {
  CustomField,
  CustomFieldResponses,
  CustomFieldType,
} from '../../../services/model/inviteService.model';
import { filterCustomLabelFields } from '../../../utils/filterUtils';

interface CustomFieldsFormProps {
  fields: CustomField[];
  response: CustomFieldResponses;
  setResponse: (response: CustomFieldResponses) => void;
  includeMandatory?: boolean;
}

export default function CustomFieldsForm({
  fields,
  response,
  setResponse,
  includeMandatory = false,
}: CustomFieldsFormProps): JSX.Element {
  const renderField = (field: CustomField, i: number) => {
    switch (field.type) {
      case CustomFieldType.TEXT:
        return (
          <div key={i} className="w-full overflow-visible">
            <p className="mb-1 pr-2 font-medium">
              {field.label}
              {includeMandatory && field.mandatory && '*'}
            </p>
            <input
              type="text"
              value={response[field.customFieldId!]}
              onChange={(e) => {
                const newResponse = { ...response };
                newResponse[field.customFieldId!] = e.target.value;
                setResponse(newResponse);
              }}
            />
          </div>
        );
      case CustomFieldType.MULTIPLE_CHOICE:
        return (
          <div className="w-full overflow-visible">
            <p className="mb-1 pr-2 font-medium">
              {field.label}
              {includeMandatory && field.mandatory && '*'}
            </p>
            {field.options.map((option, j) => {
              return (
                <div key={j} className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    checked={response[field.customFieldId!] === option}
                    onChange={() => {
                      const newResponse = { ...response };
                      newResponse[field.customFieldId!] =
                        response[field.customFieldId!] !== option ? option : '';
                      setResponse(newResponse);
                    }}
                  />
                  <p className="py-2 text-base font-light">{option}</p>
                </div>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  if (filterCustomLabelFields(fields).length === 0) return <></>;
  return <div className="flex flex-col gap-4">{fields.map(renderField)}</div>;
}
