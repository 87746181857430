import {
  CustomField,
  CustomFieldType,
  CustomLabelField,
} from '../services/model/inviteService.model';

export function filterCustomLabelFields(fields: CustomField[]): CustomLabelField[] {
  if (!fields) return [];
  return fields.filter((field) =>
    [CustomFieldType.MULTIPLE_CHOICE, CustomFieldType.TEXT].includes(field.type),
  ) as CustomLabelField[];
}

export function filterBySearch<T extends Record<string, any>>(
  items: T[],
  search: string,
  ...propertiesToCompare: (keyof T)[]
): T[] {
  if (!search) return items;
  const searchLower = search.toLowerCase();

  return items.filter((item) => {
    return propertiesToCompare.some((property) => {
      const itemString = item[property].toLowerCase();
      return itemString.includes(searchLower);
    });
  });
}

/**
 * A function that performs a string comparison with a configurable fuzzy ratio
 * @param string {string} - The string to compare
 * @param term {string} - The term to compare the string to
 * @param ratio {number} - The ratio to compare the string to the term (0-1) where 1 is a perfect match
 * @returns {boolean} - Whether the string matches the term
 */
export const fuzzySearch = (string: string = '', term: string = '', ratio: number = 1): boolean => {
  const compare = term
    .toLowerCase()
    .replaceAll(/[^a-z0-9]/gi, '')
    .replaceAll(' ', '');
  string = string
    .toLowerCase()
    .replaceAll(/[^a-z0-9]/gi, '')
    .replaceAll(' ', '');

  let matches = 0;

  if (string.startsWith(compare) || string.includes(compare) || string.indexOf(compare) > -1)
    return true;

  for (let i = 0; i < compare.length; i += 1) {
    if (string.indexOf(compare[i]) > -1) matches += 1;
    else matches -= 1;
  }

  return matches / string.length >= ratio || term === '';
};

export function searchFilter(searchField: string, searchPhrase: string, ratio = 0.8): boolean {
  if (!searchPhrase || !searchField) return true;
  return fuzzySearch(searchField, searchPhrase, ratio);
}
