import { Address } from './Profile';

export interface ScheduledEmail {
  emailId: number;
  title: string;
  body: string;
  sendDate: Date;
  isSent: boolean;
  businessId: number;
  localContactIds: number[];
  connectionIds: number[];
}

export interface ScheduledNotification {
  notificationId: number;
  message: string;
  sendDate: Date;
  isSent: boolean;
  businessId: number;
  connectionIds: number[];
}

export interface Communications {
  emails: ScheduledEmail[];
  notifications: ScheduledNotification[];
  postcards: PostcardOrder[];
}

export enum CommunicationType {
  EMAIL = 'EMAIL',
  NOTIFICATION = 'NOTIFICATION',
  POSTCARD = 'POSTCARD',
}

export interface PostcardOrder {
  orderId: number;
  message: string;
  connectionIds: number[];
  localContactIds: number[];
  momentConnectionIds: number[];
  senderAddress: Address;
  template: string;
  sendDate: Date;
  clientSecret: string;
  code: string;
  fileName?: string;
  finish: PostcardFinish;
}

export enum PostcardFinish {
  GLOSSY = 'GLOSSY',
  MATTE = 'MATTE',
}
