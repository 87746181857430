import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton, { PrimaryButtonProps } from './PrimaryButton';

export default function PrimaryLinkButton({ to, ...props }: PrimaryButtonProps & { to: string }): JSX.Element {
  return (
    <Link to={to}>
      <PrimaryButton {...props} />
    </Link>
  );
}
