import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PrivacyAndConditionsModal from '../modals/PrivacyAndConditionsModal';
import Logo from '../icons/Logo';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <div data-testid="footer" className="rounded-t-[20px] bg-primary-900">
        <div className="flex flex-col">
          <div data-testid="Container1" className="w-full border-b border-secondary-50 py-5">
            <div className="mx-auto flex w-full max-w-7xl flex-col justify-between gap-10 px-5 md:flex-row md:gap-4 xl:px-0">
              <Logo dark />
            </div>
          </div>
          <div className="text-medium desktop:px-0 flex h-fit min-h-[64px] w-full items-center bg-primary-900 px-4 py-4 md:py-0">
            <div className="flex w-full max-w-7xl flex-col justify-between md:mx-auto md:flex-row md:items-center">
              <p className="text-secondary-50">{`Copyright ® ${new Date().getFullYear()} Vera Connect`}</p>
              <div className="mt-4 flex w-fit flex-col md:mt-0 md:flex-row md:items-center md:gap-6">
                <Link className="text-secondary-50" to="/about">
                  {t('footer.about')}
                </Link>
                <p
                  onClick={(): void => setModalOpen(true)}
                  className="cursor-pointer text-secondary-50">
                  {t('footer.termsAndPrivacy')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrivacyAndConditionsModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}
