import React, { useLayoutEffect } from 'react';
import QRCode from 'qrcode';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { FaQrcode } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import WhatsappButton from '../buttons/WhatsappButton';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import ShareButton from '../buttons/ShareButton';
import QrModal from '../modals/QrModal';
import inviteService from '../../../services/inviteService';
import Button from '../buttons/Button';
import { CONSUMER_URL } from '../../../constants';
import useBusinessInvite from '../../../hooks/business/useBusinessInvite';
import QuestionCircle from './QuestionCircle';

interface InvitationLinkProps {
  link?: string;
}

export default function InvitationLink({ link }: InvitationLinkProps): JSX.Element {
  const [qrCode, setQrCode] = React.useState<string>('');
  const [showQRCode, setShowQRCode] = React.useState<boolean>(false);

  const business = useSelectedBusiness();
  const invite = useBusinessInvite();
  const [message, setMessage] = React.useState<string>(invite?.message ?? '');
  const { t } = useTranslation();
  const inviteLink = `${CONSUMER_URL}/vc/${link || business?.inviteFields?.friendlyId}`;

  const getShareText = () => {
    if (!invite) return '';
    return message || invite.message
      ? `${message || invite.message} \n\n${
          invite.linkExpiry
            ? `(Geldig tot ${invite.linkExpiry.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}) \n`
            : ''
        }${inviteLink}`
      : `Mag ik je gegevens voor "${business?.name.replace(
          '&',
          'en',
        )}"? Hiervoor kun je deze link gebruiken: \n\n${
          invite.linkExpiry
            ? `(Geldig tot ${invite.linkExpiry.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}) \n`
            : ''
        }${inviteLink}`;
  };

  const wrapFunction = async (func: () => void) => {
    const success = await onClick();
    if (success) func();
  };

  const onClick = async () => {
    if (!invite) return false;
    if (!message) {
      toast.error(t('toast.error.personalMessage'));
      return false;
    }

    if (invite?.message !== message) await inviteService.updateInvite({ ...invite, message });
    return true;
  };

  const clipBoardHandler = async () => {
    navigator.clipboard.writeText(inviteLink);
    toast.success(t('toast.success.copiedToClipboard'));
  };

  useLayoutEffect((): void => {
    const generateQRCode = async (): Promise<void> => {
      try {
        const qr = await QRCode.toDataURL(inviteLink);
        setQrCode(qr);
      } catch (err) {
        console.error(err);
      }
    };
    generateQRCode();
    getShareText();
    inviteService.fetchInvite();
  }, [inviteLink, invite?.message]);

  const handleQrCodebutton = async () => {
    setShowQRCode(true);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4 flex flex-col gap-1 border-b border-primary-300 pb-4">
        <div className="mb-1 flex items-center">
          <p className="mr-1 text-lg font-medium">{t('form.invite.personalMessage.title')}</p>

          <QuestionCircle className="right-0 w-[300px] md:left-0">
            {t('form.invite.personalMessage.explanation')}
          </QuestionCircle>
        </div>
        <textarea
          className="h-36 resize-none"
          placeholder={t('form.invite.personalMessage.placeholder')}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
      </div>
      <div className="mb-2 flex items-center justify-center gap-2 pb-2">
        <input
          data-testid="invite-link-input"
          type="text"
          className="copy-link-input custom-text-selection !border-1 flex-1 overflow-x-scroll !rounded-full !border-primary !bg-secondary-50 !bg-opacity-100"
          value={inviteLink}
          readOnly
        />
        <Button
          variant="secondary"
          onClick={() => wrapFunction(clipBoardHandler)}
          className="rounded-full p-3">
          <MdContentCopy className="h-5 w-5" />
        </Button>
      </div>
      <div className="mb-2 flex gap-2">
        {isMobile ? (
          <ShareButton
            title="Deel informatie via Vera Connect"
            text={getShareText()}
            url={inviteLink}
            onClick={onClick}
          />
        ) : (
          <WhatsappButton text={getShareText()} onClick={onClick} />
        )}
        <Button variant="secondary" onClick={() => wrapFunction(handleQrCodebutton)}>
          <FaQrcode className="h-5 w-5" />
          {' QR'}
        </Button>
      </div>
      <QrModal qrCode={qrCode} open={showQRCode} setOpen={setShowQRCode} />
    </div>
  );
}
