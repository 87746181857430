/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ProfileField } from '../../../../types/Profile';
import useDelayedEffect from '../../../../hooks/effects/useDelayedEffect';

interface PhoneNumberInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  validate?: boolean;
}
export default function PhoneNumberInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  validate = false,
}: PhoneNumberInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (
      !field.phoneNumber?.prefix &&
      !field.phoneNumber?.suffix &&
      !field.phoneNumber?.countryCode
    ) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    setError(
      parsePhoneNumber(`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`)?.isValid()
        ? ''
        : t('invalid.invalid'),
    );
  }, [`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`, validate]);

  const handleChange = (s: any): void => {
    if (!s) {
      setField({ ...field, phoneNumber: { prefix: '', suffix: '', countryCode: '' } });
      return;
    }
    const number = parsePhoneNumber(s);
    if (!number || !number.isValid()) {
      setField({ ...field, phoneNumber: { prefix: s, suffix: '', countryCode: '' } });
      return;
    }

    const prefix = `+${number.countryCallingCode}`;
    const suffix = s.replace(prefix, '');

    setField({
      ...field,
      phoneNumber: {
        countryCode: number.country!,
        prefix,
        suffix,
      },
    });
  };

  return (
    <div className="relative flex w-full flex-col">
      <label htmlFor={field.id} className="mb-1 font-medium">
        {t('dataType.phoneNumber')} {mandatory ? '*' : ''}
      </label>
      <PhoneInput
        autoComplete="tel"
        className={classNames('w-full pl-2', { invalid: error })}
        international
        countryCallingCodeEditable
        defaultCountry="NL"
        placeholder={t('dataType.phoneNumber')}
        onChange={handleChange}
        value={`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`}
      />
      <p className="absolute right-1 top-0 text-sm text-danger transition-all">{error}</p>
    </div>
  );
}
