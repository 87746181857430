import React, { useState } from 'react';
import { Id, toast } from 'react-toastify';
import { HiXMark } from 'react-icons/hi2';
import { BiRefresh } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import userService from '../../../services/userService';
import { crmTypeToTitleCase } from '../../../utils/stringUtils';
import {
  CRMAuthStatus,
  CRMConnnectionMechanism,
  CRMIntegration,
  CrmUser,
} from '../../../types/CRMIntegration';
import Button from '../buttons/Button';
import AuthParamsModal from '../modals/integrations/AuthParamsModal';
import ModalLayout from '../layouts/ModalLayout';
import ConnectionsList from '../misc/ConnectionsList';
import CancelSaveButton from '../misc/CancelSaveButton';
import PageHeader from '../misc/PageHeader';

export default function ConfigureIntegrationsPanel(): JSX.Element {
  const location = useLocation();
  const { integration } = location.state as { integration: CRMIntegration };
  const [openAuthModals, setOpenAuthModals] = useState<{ [key: string]: boolean }>({});
  const [connections, setConnections] = useState<CrmUser[]>([]);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [selectedConnections, setSelectedConnections] = useState<string[]>([]);

  const handleDelete = (crmIntegration: CRMIntegration): void => {
    const loading: Id = toast.loading(
      `Disconnecting from ${crmTypeToTitleCase(crmIntegration.crm)}`,
    );
    userService
      .disconnectFromCRM(crmIntegration)
      .then((): void => {
        toast.success(`Successfully disconnected from ${crmIntegration.crm}`);
      })
      .finally((): void => toast.dismiss(loading));
  };

  const handleAdd = (crmIntegration: CRMIntegration): void => {
    if (crmIntegration.connectionMechanism === CRMConnnectionMechanism.MANUAL) {
      setOpenAuthModals({ [crmIntegration.crm]: true });
    } else {
      const loading: Id = toast.loading(`Connecting to ${crmTypeToTitleCase(crmIntegration.crm)}`);
      userService
        .connectToCRM(crmIntegration)
        .then((): void => {
          toast.success(`Successfully connected to ${crmIntegration.crm}`);
        })
        .finally((): void => toast.dismiss(loading));
    }
  };

  const handleAuthSubmitted = (crmIntegration: CRMIntegration, auth: unknown): void => {
    if (crmIntegration.connected) {
      const loading: Id = toast.loading(
        `Updating ${crmTypeToTitleCase(crmIntegration.crm)} authentication`,
      );
      userService
        .updateCRMIntegration(crmIntegration, { auth })
        .then((): void => {
          toast.success(`Successfully updated ${crmIntegration.crm} authentication`);

          setOpenAuthModals({ [crmIntegration.crm]: false });
        })
        .finally((): void => toast.dismiss(loading));
    } else {
      const loading: Id = toast.loading(`Connecting to ${crmTypeToTitleCase(crmIntegration.crm)}`);
      userService
        .connectToCRM(crmIntegration, auth)
        .then((): void => {
          toast.success(`Successfully connected to ${crmIntegration.crm}`);
          setOpenAuthModals({ [crmIntegration.crm]: false });
        })
        .finally((): void => toast.dismiss(loading));
    }
  };

  const handleInvite = (): void => {
    const loading: Id = toast.loading('Inviting users');
    userService
      .inviteCrmUsers(integration.crm, selectedConnections)
      .then((): void => {
        toast.success('Successfully invited users');
        setInviteModalOpen(false);
      })
      .finally((): void => toast.dismiss(loading));
  };

  if (!integration) return <></>;

  return (
    <>
      <div className="flex flex-row gap-2 pb-6 max-sm:flex-col">
        <div className="flex-1">
          <PageHeader
            title={crmTypeToTitleCase(integration.crm)}
            subtitle="Invite all your members from your CRM to join your community"
          />
        </div>
        {integration.connected && (
          <div className="flex justify-between">
            {integration.authStatus === CRMAuthStatus.INVALID ? (
              <Button variant="primary" onClick={(): void => handleAdd(integration)}>
                <BiRefresh className="inline-block h-5 w-5" />
                {' Update'}
              </Button>
            ) : (
              <Button variant="primary" onClick={(): void => handleDelete(integration)}>
                <HiXMark className="inline-block h-5 w-5" />
                {' Disconnect'}
              </Button>
            )}
          </div>
        )}
        {integration.connectionMechanism === CRMConnnectionMechanism.MANUAL && (
          <AuthParamsModal
            crmIntegration={integration}
            open={openAuthModals[integration.crm] ?? false}
            setOpen={(open) => setOpenAuthModals({ [integration.crm]: open })}
            onSubmitted={(auth) => handleAuthSubmitted(integration, auth)}
          />
        )}
      </div>
      <div className="min-h-[calc(100vh-65px)] place-items-center pt-6">
        <div className="flex flex-col gap-6 lg:flex-row">
          <div className="w-96 shrink-0 gap-3 rounded-[20px] border-2 border-secondary-200 bg-secondary-200 bg-opacity-50 px-4 py-5 shadow-[0px_4px_4px_0px_#EDD5C940]">
            <div className="p-5">
              <h2 className="font-serif text-[20px]">Invite members</h2>
              <p className="text-[17px] font-medium">
                Start inviting members from your CRM to join your community
              </p>
            </div>
            <Button
              variant="primary"
              className="ml-auto mt-auto"
              onClick={(): Promise<void> =>
                userService
                  .getUsers(integration)
                  .then(setConnections)
                  .finally(() => setInviteModalOpen(true))
              }>
              {' Start '}
            </Button>
          </div>
          <div
            key={integration.crm}
            data-testid={`${integration.crm}-card`}
            className="w-96 shrink-0 gap-3 rounded-[20px] border-2 border-secondary-200 bg-secondary-200 bg-opacity-50 px-4 py-5 shadow-[0px_4px_4px_0px_#EDD5C940]">
            <div className="p-5">
              <h2 className="font-serif text-[20px]">More coming soon</h2>
            </div>
          </div>
        </div>
      </div>
      <ModalLayout
        open={inviteModalOpen}
        setOpen={setInviteModalOpen}
        className="h-a z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col rounded-[20px] bg-secondary-50 px-10 py-6 pb-4 pt-5">
        <ConnectionsList
          connections={connections.map((c) => ({
            ...c,
            alias: c.name,
            id: c.connectionId,
            disabled: !c.email,
          }))}
          selected={selectedConnections}
          setSelected={setSelectedConnections}
        />
        <div className="mt-6 flex w-full justify-end">
          <CancelSaveButton
            onCancel={() => setInviteModalOpen(false)}
            disabled={!selectedConnections.length}
            onSave={() => {
              handleInvite();
              setInviteModalOpen(false);
            }}
          />
        </div>
      </ModalLayout>
    </>
  );
}
