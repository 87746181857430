import httpService from './httpService';
import { Task } from './model/taskService.model';

export const taskService = {
  createTask: async (businessId: number, description: string, dueDate: Date, assignees?: number[]) => {
    return httpService.post(`/tasks`, {
      businessId,
      description,
      dueDate,
      assignees
    });
  },

  getAllTasksForBusiness: async (businessId: number) : Promise<Task[]> => {
    const res = await httpService.get(`/tasks/all/${businessId}`);
    return res.data;
  },

  getTaskById: async (taskId: number) => {
    return httpService.get(`/tasks/${taskId}`);
  },

  updateTask: async (taskId: number, updates: { description?: string; dueDate?: Date; done?: boolean }) => {
    return httpService.put(`/tasks/${taskId}`, updates);
  },

  deleteTask: async (taskId: number) => {
    return httpService.delete(`/tasks/${taskId}`);
  },

  assignMemberToTask: async (taskId: number, userId: number, businessId: number) => {
    return httpService.post(`/tasks/assign`, {
      taskId,
      userId,
      businessId,
    });
  },

  removeMemberFromTask: async (taskId: number, userId: number, businessId: number) => {
    return httpService.post(`/tasks/remove-assignee`, {
      taskId,
      userId,
      businessId,
    });
  },
};
