import { Menu, Transition } from '@headlessui/react';
import { HiMiniEllipsisHorizontal } from 'react-icons/hi2';
import React, { Children, Fragment } from 'react';
import { TbX } from 'react-icons/tb';
import { IconType } from 'react-icons';
import { PropsOf } from '@headlessui/react/dist/types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

export type ActionMenuProps = {
  children?: React.ReactNode;
}

export function ActionMenu({ children }: ActionMenuProps): JSX.Element {
  const { t } = useTranslation('');
  const numberOfOptions = Children.toArray(children).length;

  return (
    <Menu as="div" className="relative flex items-center">
      {({close}) => (
        <>
          <Menu.Button className="flex items-center justify-center">
            <HiMiniEllipsisHorizontal className="icon h-8 w-8 font-bold" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              className="absolute -translate-x-[calc(100%-2rem)] top-6 z-10 h-fit max-h-48 overflow-y-scroll min-w-52 rounded-md bg-secondary-50 shadow-lg">
              <div className="px-4 py-2 font-semibold flex items-center justify-between">
                <p>{t('general.options')}</p>
                <button className="flex justify-center items-center hover:opacity-80" type="button" onClick={close}>
                  <TbX className="w-5 h-5 flex-shrink-0" />
                </button>
              </div>
              {numberOfOptions === 0 && <p className="text-sm px-4 py-2 text-gray-500">{t('general.noOptions')}</p>}
              {children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export type ActionMenuButtonProps = { icon: IconType, text: string } & Omit<PropsOf<'button'>, 'type'>

export function ActionMenuButton({ icon: Icon, text, className, ...props }: ActionMenuButtonProps): JSX.Element {
  return (
    <Menu.Item>
      <button
        type="button"
        className={twMerge('flex whitespace-nowrap w-full items-center gap-2 px-4 py-2 text-sm font-semibold hover:bg-secondary-200 transition-colors last:rounded-b-md', className)}
        {...props}>
        <Icon className="icon h-5 w-5 flex-shrink-0" />
        {text}
      </button>
    </Menu.Item>
  );
}
