import httpService, { getUserId } from './httpService';
import { Address } from '../types/Profile';
import { getSelectedBusiness } from '../hooks/business/useSelectedBusiness'
import { Association } from '../types/race';

async function generatePictureBody(picture?: string) {

  const formData = new FormData();
  if(picture){
    const file = await fetch(picture!).then((res) => res.blob() as Promise<File>);
    formData.append('file', file);
  }
  return formData
}

export const associationsRaceService = {
  createAssociation: async (
    name: string,
    description: string,
    website: string,
    picture?: string,
    address?: Address,
  ) => {
    const formData: FormData = await generatePictureBody(picture);
    formData.append('association', JSON.stringify({ name, description, website, address }));
    formData.append('userId', getUserId().toString())
    formData.append('communityId', getSelectedBusiness()?.businessId? '' : toString())
    const res = await httpService.post(
      `/association`,
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        },
      },
    );
    return res.data;
  },

  joinAssociation: async (associationId: number): Promise<void> => {
    const res = await httpService.post(
      `/association/${associationId}/join/${getSelectedBusiness()?.businessId}`,
    );
    return res.data;
  },

  updateAssociation: async (
    associationId: number,
    name?: string,
    description?: string,
    website?: string,
    picture?: string,
    address?: Address,
  ) => {
    const formData = await generatePictureBody(picture);
    formData.append('association', JSON.stringify({name, description, website, address}));
    formData.append('userId', getUserId().toString())
    formData.append('associationId', associationId.toString())
    formData.append('communityId', getSelectedBusiness()?.businessId? '' : toString())
    const res = await httpService.post(`/update-association`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      },
    },);
    return res.data;
  },

  deleteAssociation: async (associationId: number) => {
    const res = await httpService.delete(`/association/${associationId}`, {
      params: { communityId: getSelectedBusiness()?.businessId, userId: getUserId() },
    });
    return res.data;
  },

  retrieveAssociation: async (associationId: number) => {
    const res = await httpService.get(`/association/${associationId}`);
    return res.data;
  },

  getAllAssociations: async () => {
    const res = await httpService.get('/associations');
    return res.data;
  },

  getUsersInCommunity: async (communityId: number): Promise<number> => {
    const res = await httpService.get(`/community-users/${communityId}`);
    return res.data;
  },

  getCurrentTrialPeriod: async (associationId: number): Promise<number> => {
    const res = await httpService.get(`/current-trial/${associationId}`);
    return res.data;
  },

  leaveAssociation: async (associationId: number): Promise<void> => {
    const res = await httpService.post(
      `/association/${associationId}/leave/${getSelectedBusiness()?.businessId}`,
    );
    return res.data;
  },

  getUnapproved: async (): Promise<Association[]> => {
    const res = await httpService.get(`/unapproved-association`);
    return res.data;
  },

  approve: async (associationId: number): Promise<void> => {
    await httpService.put(`/association-approval/${associationId}`, { isApproved: true });
  },
  declineApproval: async (associationId: number): Promise<void> => {
    await httpService.put(`/association-approval/${associationId}`, { isApproved: false });
  },
};
