import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrivacyAndConditionsModal from '../modals/PrivacyAndConditionsModal';

interface TermsAndConditionsProps {
  state: boolean;
  setState: any;
}

export default function TermsAndConditions({
  state,
  setState,
}: TermsAndConditionsProps): JSX.Element {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="hover:cursor-pointer">
        <input type="checkbox" checked={state} onChange={() => setState(!state)} />
        <label className="text-b-900 dark:text-black-300 ml-2 text-sm font-medium hover:cursor-pointer">
          {t('component.terms.accept')}{' '}
          <span
            data-testid="tac-link"
            className="text-primary hover:cursor-pointer hover:text-opacity-80 hover:underline"
            onClick={() => setModalOpen(true)}>
            {t('component.terms.termsAndConditions')}
          </span>
        </label>
      </div>
      <PrivacyAndConditionsModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}
