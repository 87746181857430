import React, { useState } from 'react';
import { HiLink } from 'react-icons/hi';
import ModalLayout from '../../../layouts/ModalLayout';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';
import Button from '../../../buttons/Button';

interface AdditionalDataModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmitted: (additional: AdditionalData) => void;
  initialData?: AdditionalData;
}

export interface AdditionalData {
  Def1?: string; // maps Def1 to some_field of our customer model
  Def2?: string;
  Def3?: string;
  Def4?: string;
  Def5?: string;
  Def6?: string;
  Def7?: string;
  Def8?: string;
  Def9?: string;
  Def10?: string;
}

export default function AdditionalDataModal({
  open,
  setOpen,
  onSubmitted,
  initialData,
}: AdditionalDataModalProps): JSX.Element {
  const { t } = useBusinessTranslation();

  const [additionalData, setAdditionalData] = useState<AdditionalData>({
    Def1: initialData?.Def1 ?? '',
    Def2: initialData?.Def2 ?? '',
    Def3: initialData?.Def3 ?? '',
    Def4: initialData?.Def4 ?? '',
    Def5: initialData?.Def5 ?? '',
    Def6: initialData?.Def6 ?? '',
    Def7: initialData?.Def7 ?? '',
    Def8: initialData?.Def8 ?? '',
    Def9: initialData?.Def9 ?? '',
    Def10: initialData?.Def10 ?? '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAdditionalData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="relative z-50 w-[45rem] min-w-[300px] rounded-2xl bg-secondary-50 p-8">
      <div className="mb-6">
        <h2 className="text-xl font-semibold">
          {t('auth.params.title', 'Authentication Parameters')}
        </h2>
        <p className="text-sm text-gray-600">
          {t('auth.params.description', 'Please enter your credentials below to continue.')}
        </p>
      </div>

      <div className="space-y-4">
        {Array.from({ length: 10 }, (_, i) => i + 1).map((index) => (
          <div key={`Def${index}-div`} className="w-full overflow-visible">
            <p className="mb-1 pr-2 text-sm"> {t(`auth.params.def-${index}`)}*</p>
            <input
              type="text"
              name={`Def${index}`}
              value={additionalData[`Def${index}` as keyof AdditionalData]}
              onChange={handleInputChange}
            />
          </div>
        ))}
      </div>

      <div className="mt-6 flex">
        <Button variant="primary" onClick={() => onSubmitted(additionalData)}>
          <HiLink className="inline-block h-5 w-5" />
          {' Update'}
        </Button>
        <Button onClick={() => setOpen(false)} className="hover:text-opacity-60">
          {t('general.cancel')}
        </Button>
      </div>
    </ModalLayout>
  );
}
