import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FaBars } from 'react-icons/fa6';
import Logo from '../../icons/Logo';
import SettingsPanel from './components/SettingsPanel';
import useProfilePicture from '../../../../hooks/account/useProfilePicture';
import userService from '../../../../services/userService';
import LogoLetter from '../../icons/LogoLetter';
import SideBarIcon from '../../icons/SideBarIcon';
import useSelectedBusiness from '../../../../hooks/business/useSelectedBusiness';
import ProfilePicture from '../../misc/ProfilePicture';
import useAuth from '../../../../hooks/account/useAuth';
import BusinessButton from './components/BusinessButton';
import DropdownPanel from './components/DropdownPanel';
import { RootState } from '../../../../redux/reducers';

interface ApplicationLayoutProps {
  children?: React.ReactNode;
  collapsed?: boolean;
}

export default function ApplicationLayout({
  children,
  collapsed: preCollapsed = false,
}: ApplicationLayoutProps): JSX.Element {
  const profilePicture = useProfilePicture();
  const location = useLocation();
  const business = useSelectedBusiness();
  const auth = useAuth();
  const { onboardingStep } = useSelector((state: RootState) => state.application);

  const [collapsed, setCollapsed] = useState(preCollapsed);
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    if (onboardingStep === undefined) return;
    setCollapsed(false);
  }, [onboardingStep]);

  useEffect(() => {
    if (!profilePicture) {
      userService.getProfilePicture();
    }
  }, [profilePicture]);

  useEffect(() => setClosed(true), [location.pathname]);

  return (
    <div className="flex h-full min-h-screen w-full flex-col bg-primary-900 p-0 lg:flex-row lg:justify-between lg:p-4">
      <div
        className={classNames(
          'group top-4 z-50 flex h-fit w-full flex-shrink-0 flex-col py-4 transition-all duration-200 lg:sticky',
          {
            collapsed,
            open: !collapsed,
            'lg:w-14': collapsed,
            'items-start px-4 pr-8 lg:w-[300px]': !collapsed,
          },
        )}>
        <div className="flex w-full items-center justify-between">
          <Link to={business ? '/overview' : '/'} className="w-fit">
            {collapsed ? (
              <>
                <LogoLetter dark className="hidden min-h-[58px] lg:block" />
                <Logo dark className="lg:hidden" />
              </>
            ) : (
              <Logo dark className="lg:min-h-[80px] lg:min-w-[230]" />
            )}
          </Link>
          <FaBars
            className="h-6 w-6 cursor-pointer text-secondary-50 lg:hidden"
            onClick={() => setClosed(!closed)}
          />
        </div>
        <div
          className={classNames(
            'no-scrollbar flex w-full flex-col overflow-hidden transition-all lg:max-h-[calc(100vh-150px)] lg:group-[.collapsed]:overflow-visible lg:group-[.open]:overflow-scroll',
            {
              'max-h-[0px] duration-500 ease-[cubic-bezier(0,1,0,1)]': closed,
              'max-h-[2000px] duration-1000 ease-[ease-in-out]': !closed,
            },
          )}>
          {business && (
            <>
              <div className="mt-4 flex w-full flex-col items-center justify-center gap-2 border-t border-primary-300 pt-4">
                <DropdownPanel />
              </div>
            </>
          )}
          <div className="mt-4 flex w-full flex-col items-center justify-center gap-1 border-t border-primary-300 pt-4">
            <SettingsPanel />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'flex min-h-[calc(100vh-72px)] w-full flex-col rounded-t-[20px] bg-secondary-50 px-6 pb-4 lg:mb-2 lg:rounded-[20px]',
          {
            'lg:w-[calc(100%-300px)]': !collapsed,
            'lg:w-[calc(100%-72px)]': collapsed,
          },
        )}>
        <div
          className={classNames(
            'sticky top-0 z-[50] mb-4 flex w-full justify-between border-b border-primary-300 bg-secondary-50 py-4',
            { 'opacity-80 blur-sm': onboardingStep !== undefined },
          )}>
          <div className="flex items-center gap-2">
            <button
              type="button"
              onClick={() => setCollapsed(!collapsed)}
              className="hidden h-fit w-fit flex-shrink-0 cursor-pointer items-center justify-center rounded-full bg-secondary-200 p-2 hover:bg-opacity-80 lg:flex">
              <SideBarIcon className="h-5 w-5" />
            </button>
            <BusinessButton />
          </div>
          {location.pathname !== '/profile' && auth && (
            <Link to="/profile">
              <ProfilePicture
                picture={profilePicture}
                alias={`${auth.firstName}+${auth.lastName}`}
                className="h-10 w-10 rounded-lg hover:bg-opacity-80"
              />
            </Link>
          )}
        </div>
        <div className="flex h-full w-full flex-1 flex-col">{children || <Outlet />}</div>
      </div>
    </div>
  );
}
