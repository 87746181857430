import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FaArrowRight, FaBug, FaLightbulb } from 'react-icons/fa6';
import Button from '../components/buttons/Button';
import sendFeedback from '../../services/feedbackService';
import { SvgQuestionMark } from '../components/misc/SvgQuestionMark';
import * as constants from '../../constants';

enum FeedbackOption {
  BUG = 'BUG',
  FEATURE = 'FEATURE',
}

export default function FeedbackPage(): JSX.Element {
  const [feedback, setFeedback] = useState<string>('');
  const { t } = useTranslation();
  const [placeholder, setPlaceholder] = useState<string>(t('feedback.featurePlaceholder'));
  const [option, setOption] = useState<FeedbackOption>(FeedbackOption.FEATURE);
  const handleSendFeedback = async () => {
    await sendFeedback(feedback, option);
    toast(t('feedback.toast.success'));
  };

  return (
    <div className="grid flex-1 grid-cols-8 gap-6">
      <div className="col-span-8 flex flex-1 flex-col gap-6 lg:col-span-5">
        <div className="flex h-fit items-start justify-between rounded-2xl bg-secondary-200 p-4">
          <div className="flex flex-col">
            <h2 className="mb-2 pl-2 font-serif text-3xl font-semibold">{t('feedback.title')}</h2>
            <div className="pl-2">
              <p>{t('feedback.subtitle')}</p>
            </div>
          </div>
          <SvgQuestionMark width={60} height={90} fill={constants.COLOR_SECONDARY_50} />
        </div>
        <div className="">
          <h2 className="mt-0 font-serif">{t('feedback.option')}</h2>
          <div className="mt-2 flex flex-row gap-4">
            <div className="flex w-fit flex-col items-center justify-center">
              <Button
                className="d-flex flex-column align-items-center"
                id="bug-option"
                onClick={() => {
                  setOption(FeedbackOption.FEATURE);
                  setPlaceholder(t('feedback.featurePlaceholder'));
                }}>
                <div
                  className={`rounded-2xl border border-primary-300 p-3 ${
                    FeedbackOption.FEATURE === option ? 'bg-primary' : ''
                  }`}
                  style={{ height: '72px', width: '72px' }}>
                  <FaLightbulb size={48} color={constants.COLOR_PRIMARY_300} />
                </div>
              </Button>
              <small className="text-s mt-0 text-center">{t('feedback.feature')}</small>
            </div>
            <div className="flex w-fit flex-col items-center justify-center">
              <Button
                className="d-flex flex-column align-items-center"
                id="bug-option"
                onClick={() => {
                  setOption(FeedbackOption.BUG);
                  setPlaceholder(t('feedback.bugPlaceholder'));
                }}>
                <div
                  className={`rounded-2xl border border-primary-300 p-3 ${
                    FeedbackOption.BUG === option ? 'bg-primary' : ''
                  }`}
                  style={{ height: '72px', width: '72px' }}>
                  <FaBug size={48} color={constants.COLOR_PRIMARY_300} />
                </div>
              </Button>
              <small className="text-s mt-0 text-center">{t('feedback.bug')}</small>
            </div>
          </div>
        </div>
        <h2 className="-mt-2 font-serif">{t('feedback.titleWriteFeedback')}</h2>
        <label className="form-label -mt-1 text-sm">{t('feedback.label')}</label>
        <textarea
          id="feedback-input"
          className="h-44 w-full"
          placeholder={placeholder}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <footer className="bg-light py-3">
          <Button
            className="btn btn-primary w-100 border"
            id="send-feedback"
            variant="primary"
            onClick={() => handleSendFeedback()}>
            <FaArrowRight />
            {t('feedback.send')}
          </Button>
        </footer>
      </div>
    </div>
  );
}
