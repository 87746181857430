/* eslint-disable no-useless-return */
import { useEffect, useRef } from 'react';

function useEffectAfterFirstRender(effect: () => void, dependencies: React.DependencyList): void {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return () => {
        return;
      };
    }
    return effect();
  }, dependencies);
}

export default useEffectAfterFirstRender;
