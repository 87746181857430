import { Menu, Transition } from '@headlessui/react';
import { isMobile } from 'react-device-detect';
import { twMerge } from 'tailwind-merge';
import React, { Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { OptionsButtonOption } from '../../../types/misc';
import Icon from '../icons/Icon';

interface OptionsButtonProps {
  children?: React.ReactNode;
  options: OptionsButtonOption[];
  className?: string;
  containerClassName?: string;
  onClick?: any;
}

export default function OptionsButton({
  options,
  children,
  className,
  containerClassName = '',
  onClick,
}: OptionsButtonProps): JSX.Element {
  const navigate = useNavigate();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const timeoutDuration = 100;
  let timeout: any;

  const openMenu = () => buttonRef?.current?.click();
  const closeMenu = () =>
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      }),
    );

  const onMouseEnter = (closed: boolean) => {
    clearTimeout(timeout);
    if (closed) openMenu();
  };
  const onMouseLeave = (open: boolean) => {
    if (open) timeout = setTimeout(() => closeMenu(), timeoutDuration);
  };

  return (
    <Menu data-testid="user-options-menu" as="div" className="relative h-fit w-fit">
      {({ open }) => (
        <div className={`${open ? 'open' : ''} group`}>
          <div className="flex h-9 place-items-center items-center font-normal">
            <button
              type="button"
              onMouseEnter={() => onMouseEnter(!open)}
              onMouseLeave={() => onMouseLeave(open)}
              onTouchStart={() => {
                buttonRef.current?.click();
                if (!isMobile) onClick?.();
              }}
              onClick={() => {
                buttonRef.current?.click();
                if (!isMobile) onClick?.();
              }}
              className={className}>
              {children}
            </button>
            <Menu.Button ref={buttonRef} data-testid="user-options-button" className="invisible" />
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              ref={dropdownRef as any}
              onMouseEnter={() => onMouseEnter(false)}
              onMouseLeave={() => onMouseLeave(open)}
              className={twMerge(
                'absolute right-0 z-50 mt-3.5 flex w-fit flex-shrink-0 flex-col overflow-hidden whitespace-nowrap rounded-[20px] border border-primary-300 bg-primary-900 p-3 focus:outline-none focus:ring-0',
                containerClassName,
              )}>
              {options.map((option) => (
                <Menu.Item key={option.name}>
                  <div
                    className={twMerge(
                      'flex flex-shrink-0 cursor-pointer items-center gap-2 rounded-full px-4 py-2 text-primary-300 hover:bg-primary-300 hover:bg-opacity-20',
                      option.className || '',
                    )}
                    onClick={() => (option.linkTo ? navigate(option.linkTo) : option.onClick?.())}>
                    <Icon icon={option.icon} className="h-5 w-5" />
                    <div className="flex flex-col gap-0.5">
                      <p className="text-base font-medium leading-4 text-primary-300">
                        {option.name}
                      </p>
                      {option.description && (
                        <p className="text-xs text-secondary-50">{option.description}</p>
                      )}
                    </div>
                  </div>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
}
