import React from 'react';
import { twMerge } from 'tailwind-merge';
import { getInitialsFromAlias } from '../../../utils/stringUtils';
import ApiImage from './ApiImage';

interface ProfilePictureProps {
  picture: string | undefined;
  alias: string;
  className?: string;
}
export default function ProfilePicture({ picture, alias, className }: ProfilePictureProps) {
  return picture ? (
    <ApiImage
      alt="profile"
      path={picture}
      className={twMerge('rounded-lg object-cover', className)}
    />
  ) : (
    <div
      aria-hidden
      className={twMerge(
        'flex flex-shrink-0 items-center justify-center rounded-lg bg-secondary text-secondary-50',
        className,
      )}>
      {getInitialsFromAlias(alias)}
    </div>
  );
}
