import { useTranslation } from 'react-i18next';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import {
  PiNumberCircleOneBold,
  PiNumberCircleThreeBold,
  PiNumberCircleTwoBold,
} from 'react-icons/pi';
import { AttendantData, EventParticipationAnswer, ZERO_PLACEHOLDER } from '../../../../types/event';
import { decimalToPercentageString } from '../../../../utils/stringUtils';

type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    payload?: AttendantData & { place?: 'first' | 'second' | 'third'; bgColor?: string };
  }[];
  className?: string;
};

export default function AttendanceTooltip({
  active,
  payload,
  className,
}: CustomTooltipProps): JSX.Element {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div
        style={{ backgroundColor: payload[0].payload?.bgColor }}
        className={twMerge(
          'rounded-md border-2 border-secondary-50 bg-primary-300 p-2 shadow-lg shadow-secondary-200',
          className,
        )}>
        <p className="flex items-center gap-2 pb-2 font-semibold text-secondary-50 underline">
          {payload[0].payload?.place &&
            (payload[0].payload.place === 'first' ? (
              <PiNumberCircleOneBold />
            ) : payload[0].payload.place === 'second' ? (
              <PiNumberCircleTwoBold />
            ) : (
              <PiNumberCircleThreeBold />
            ))}
          {payload[0].payload?.name}
        </p>
        {Object.values(EventParticipationAnswer).map((key) => (
          <p key={`${key}_${payload[0].payload?.id}`} className="text-sm text-secondary-50">
            {/*<ParticipationBadge className="w-5 h-5" type={key} />*/}
            {t(`page.eventDetails.answerTitle.${key.toLowerCase()}`)}:{' '}
            {payload[0].payload?.[key as EventParticipationAnswer]}
            {key === EventParticipationAnswer.YES &&
              payload[0].payload &&
              ` (${decimalToPercentageString(payload[0].payload.yesFraction === ZERO_PLACEHOLDER ? 0 : payload[0].payload.yesFraction)})`}
          </p>
        ))}
      </div>
    );
  }

  return <></>;
}
