import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLocationArrow } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import IconBadge from '../../icons/IconBadge';
import { SelectOption } from '../../../../types/misc';
import CancelSaveButton from '../../misc/CancelSaveButton';
import { isValidLocalContact } from '../../../../utils/validationUtils';
import localContactService from '../../../../services/localContactService';
import { LocalContactInfo } from '../../../../services/model/localContactService.model';
import CountryDropdown from '../../forms/CountryDropdown';
import { ProfileField } from '../../../../types/Profile';
import { BusinessInvite } from '../../../../services/model/inviteService.model';

export function ChooseCountryHeader(): JSX.Element {
  const { t } = useTranslation('', {
    keyPrefix: 'component.modal.importLocalContacts.chooseCountry',
  });

  return (
    <div className="mb-2 flex items-center">
      <IconBadge icon={FaLocationArrow} />

      <div className="ml-4 flex w-full flex-col">
        <h1 className="font-serif text-3xl">{t('title')}</h1>
        <p>{t('subtitle')}</p>
      </div>
    </div>
  );
}

interface ChooseCountryViewProps {
  invite: BusinessInvite;
  contactsWithMissingCountry: LocalContactInfo[];
  setLocalContacts: (contacts: LocalContactInfo[]) => void;
  onSubmitSuccessful?: () => void;
  onMoreDataNeeded?: () => void;
  onSubmitFailed?: () => void;
}

export function ChooseCountryView({
  invite,
  contactsWithMissingCountry,
  setLocalContacts,
  onSubmitSuccessful,
  onMoreDataNeeded,
  onSubmitFailed,
}: ChooseCountryViewProps): JSX.Element {
  const { t } = useTranslation();
  const [country, setCountry] = useState<SelectOption>(null);

  const handleSubmit = async () => {
    if (!country) {
      toast.error(t('toast.error.missingCountry'));
      return;
    }

    const withCountry = contactsWithMissingCountry.map((c) => ({
      ...c,
      receivedData: c.receivedData.map(
        (r): ProfileField => ({
          ...r,
          address: r.address
            ? { ...r.address, country: country.label, countryCode: country.value }
            : undefined,
        }),
      ),
    }));

    if (withCountry.every((c) => isValidLocalContact(c, invite))) {
      await localContactService.createLocalContacts(withCountry);
      toast.success(t('toast.success.importLocalContacts'));
      onSubmitSuccessful?.();
    } else {
      console.log(withCountry);
      setLocalContacts(withCountry);
      onMoreDataNeeded?.();
    }
  };

  return (
    <div className="flex w-full flex-wrap gap-4">
      <div className="w-full">
        <div className="flex flex-col">
          <label className="text-sm font-semibold">{t('dataType.country')}</label>
          <CountryDropdown
            netherlandsFirst
            selectedCountry={country}
            setSelectedCountry={setCountry}
          />
        </div>
      </div>

      <div className="flex w-full justify-end">
        <CancelSaveButton onCancel={() => onSubmitFailed?.()} onSave={handleSubmit} />
      </div>
    </div>
  );
}
