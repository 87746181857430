import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import classNames from 'classnames';
import { FaUserPlus } from 'react-icons/fa6';
import { HiArrowRight } from 'react-icons/hi';
import ModalLayout from '../layouts/ModalLayout';
import usePersonalConnections from '../../../hooks/account/usePersonalConnections';
import { PersonalConnection } from '../../../types/misc';
import { RootState } from '../../../redux/reducers';
import { ProfileDataType, ProfileField } from '../../../types/Profile';
import userService from '../../../services/userService';
import UserDataForm from '../forms/userData/UserDataForm';
import personalMomentConnectionService from '../../../services/personalMomentConnectionService';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { WrongCreatorResponse } from '../../../services/model/personalMomentConnectionService.model';
import { BusinessInvite, CustomFieldType } from '../../../services/model/inviteService.model';
import inviteService from '../../../services/inviteService';
import CommunicationNameInput from '../forms/profile/CommunicationNameInput';
import CancelSaveButton from '../misc/CancelSaveButton';
import IconBadge from '../icons/IconBadge';
import ProfilePicture from '../misc/ProfilePicture';
import SearchBar from '../forms/SearchBar';

const NEVER_REQUESTED_TYPES = [
  ProfileDataType.BUSINESSNAME,
  ProfileDataType.MOMENT,
  ProfileDataType.SOCIAL,
];
interface PersonalMomentConnectionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  connection?: Connection;
}

export default function PersonalMomentConnectionModal({
  open,
  setOpen,
  connection,
}: PersonalMomentConnectionModalProps): JSX.Element {
  const [selected, setSelected] = useState<PersonalConnection | undefined>();

  useEffect(() => {
    if (!open) return;
    setSelected(undefined);
  }, [open]);

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="h-a relative z-50 mx-6 flex w-fit min-w-[450px] max-w-[700px] flex-col rounded-[20px] bg-secondary-50 px-10 py-8 transition-all">
      {selected || connection ? (
        <IndividualView
          selected={selected}
          connection={connection}
          setSelected={setSelected}
          setOpen={setOpen}
        />
      ) : (
        <ConnectionsView setSelected={setSelected} />
      )}
    </ModalLayout>
  );
}

interface ConnectionsViewProps {
  setSelected: (selected: PersonalConnection) => void;
}
function ConnectionsView({ setSelected }: ConnectionsViewProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const [search, setSearch] = useState('');
  const personalConnections = usePersonalConnections();
  const connections = useSelector((state: RootState) => state.connections);

  const filtered = personalConnections
    .filter((c) => !connections.find((con) => con.userId === c.userId))
    .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));

  return (
    <>
      <div className="flex w-full">
        <IconBadge icon={FaUserPlus} />

        <div className="mb-6 ml-4 flex w-full flex-col">
          <h1 className="font-serif text-2xl">
            {t('component.modal.personalMomentContact.add.title')}
          </h1>
          <span>{t('component.modal.personalMomentContact.add.subtitle')}</span>
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 border-y border-primary-300 py-4">
        {filtered.length > 4 && <SearchBar search={search} setSearch={setSearch} />}
        <div className="no-scrollbar flex max-h-[400px] flex-col gap-2 overflow-y-scroll">
          <p className={classNames('pl-2', { hidden: filtered.length })}>
            {t('component.connectionSelector.noConnections')}
          </p>

          {filtered
            .filter((c) =>
              `${c.firstName} ${c.lastName}`.toLowerCase().includes(search.toLowerCase()),
            )
            .map((c, i) => (
              <div
                key={i}
                onClick={() => setSelected(c)}
                className="flex cursor-pointer items-center justify-between rounded-[8px] border border-secondary-200 px-3 py-2">
                <div className="flex items-center gap-2">
                  <ProfilePicture
                    alias={`${c.firstName}+${c.lastName}`}
                    picture={c.picture}
                    className="h-9 w-9"
                  />
                  <p className="font-medium">{`${c.firstName} ${c.lastName}`}</p>
                </div>
                <HiArrowRight className="h-5 w-5 text-primary-900" />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

interface IndividualViewProps {
  selected: PersonalConnection | undefined;
  connection?: Connection;
  setSelected: (selected: PersonalConnection | undefined) => void;
  setOpen: (open: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function IndividualView({ selected, setSelected, setOpen, connection }: IndividualViewProps) {
  const { t } = useBusinessTranslation();
  const connected = usePersonalConnections()
    .map((c) => c.userId)
    .includes(connection?.userId!);
  const [fields, setFields] = useState<ProfileField[]>([]);
  const [selectedFields, setSelectedFields] = useState<ProfileField[]>([]);
  const [wrongAccount, setWrongAccount] = useState<WrongCreatorResponse>();
  const [invite, setInvite] = useState<BusinessInvite>();
  const [communicationName, setCommunicationName] = useState<string>(
    (connection?.fields as any)?.COMMUNICATION_NAME || '',
  );

  useEffect(() => {
    if (connection)
      personalMomentConnectionService
        .getFieldsForPersonalMomentConnection(+connection.id!)
        .then((f) => {
          setSelectedFields(f);
        })
        .catch((e: AxiosError<WrongCreatorResponse> | Error) => {
          if (e instanceof AxiosError && e.response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            setWrongAccount(e.response?.data);
          }
        });

    userService.getSharedDataFromUser((connection || selected)?.userId!).then((fs) => {
      setFields(fs);
      if (connection) return;
      setSelectedFields(
        Object.values(ProfileDataType).flatMap((type: ProfileDataType) => {
          const filtered = fs.filter((f) => f.dataType === type);
          if (NEVER_REQUESTED_TYPES.includes(type) || filtered.length !== 1) return [];
          return filtered;
        }),
      );
    });
    inviteService.fetchInvite().then(setInvite);
  }, []);

  const handleSubmit = async () => {
    if (connection) {
      if (!wrongAccount)
        await personalMomentConnectionService.updatePersonalMomentConnections([
          {
            momentConnectionId: +connection.id,
            communicationName,
            propertyIds: selectedFields.map((f) => +f.id!),
          },
        ]);
      else
        await personalMomentConnectionService
          .deletePersonalMomentConnections([connection.id])
          .then(() =>
            personalMomentConnectionService.createPersonalMomentConnections([
              {
                userId: connection.userId!,
                communicationName,
                propertyIds: selectedFields.map((f) => +f.id!),
              },
            ]),
          );
    } else
      await personalMomentConnectionService.createPersonalMomentConnections([
        {
          userId: selected?.userId!,
          communicationName,
          propertyIds: selectedFields.map((f) => +f.id!),
        },
      ]);

    setOpen(false);
  };

  if (!connected && wrongAccount)
    return (
      <div className="flex w-full">
        <div className="flex w-full flex-col">
          <h1 className="text-xl font-semibold">
            {t(`component.modal.personalMomentContact.notConnected.title`)}
          </h1>
          <span>
            {t(`component.modal.personalMomentContact.notConnected.subtitle`, {
              name: wrongAccount.adminAlias.replace('+', ' '),
            })}
          </span>
        </div>
      </div>
    );

  return (
    <>
      <div className="flex w-full">
        <div className="mb-6 flex w-full flex-col">
          <h1 className="text-xl font-semibold">
            {t(`component.modal.personalMomentContact.${connection ? 'edit' : 'add'}.title`)}
          </h1>
          <span>
            {t(
              `component.modal.personalMomentContact.${connection ? 'edit' : 'add'}.subtitle${
                wrongAccount ? 'NotCreator' : ''
              }`,
              { name: wrongAccount?.adminAlias.replace('+', ' ') },
            )}
          </span>
        </div>
      </div>
      {invite?.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME) && (
        <CommunicationNameInput
          className="mb-6"
          name={communicationName}
          setName={setCommunicationName}
        />
      )}
      <UserDataForm
        fields={fields}
        selectedData={selectedFields}
        setSelectedData={setSelectedFields}
        nonRequestedFields={(invite?.nonRequestedFields || []).concat(NEVER_REQUESTED_TYPES)}
        onePerDataType
        onlyBirthDate
      />

      <div className="mt-6 flex w-full justify-end">
        <CancelSaveButton onCancel={() => setOpen(false)} onSave={handleSubmit} />
      </div>
    </>
  );
}
