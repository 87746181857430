import React from 'react';
import { useTranslation } from 'react-i18next';

import IntegrationsPanel from '../components/panels/IntegrationsPanel';
import PageHeader from '../components/misc/PageHeader';

export default function IntegrationsPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div data-testid="integration-panel" className="overflow-y-scroll">
      <div className="flex flex-row gap-2 pb-6 max-sm:flex-col">
        <div className="flex-1">
          <PageHeader title={t(`page.crm.title`)} subtitle={t(`page.crm.subtitle`)} />
        </div>
      </div>
      <IntegrationsPanel />
    </div>
  );
}
