import React, { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import IconBadge from '../icons/IconBadge';
import { communicationTypeIcons } from '../../../utils/iconUtils';
import { CommunicationType } from '../../../types/communication';

type CommunicationBadgeProps = {
  type: CommunicationType;
  size?: 'sm' | 'lg';
} & Omit<ComponentProps<typeof IconBadge>, 'icon'>;

export default function ParticipationBadge({
  type,
  className,
  iconClassName,
  size = 'lg',
}: CommunicationBadgeProps) {
  const classNamesMap = {
    [CommunicationType.EMAIL]: 'text-secondary-50 bg-primary-300',
    [CommunicationType.NOTIFICATION]: 'text-secondary-50 bg-secondary',
    [CommunicationType.POSTCARD]: 'text-secondary-50 bg-secondary-200',
  };

  const sizeMapBadge = {
    sm: 'w-5 h-5 rounded-[5px]',
    lg: 'w-8 h-8 rounded-[6px]',
  };

  const sizeMapIcon = {
    sm: 'w-5 h-5',
    lg: 'w-5 h-5',
  };

  return (
    <IconBadge
      icon={communicationTypeIcons[type]}
      className={twMerge(sizeMapBadge[size], classNamesMap[type], className)}
      iconClassName={twMerge(sizeMapIcon[size], iconClassName)}
    />
  );
}
