import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ApiImage from '../misc/ApiImage';
import Icon from '../icons/Icon';
import { businessTypeIcons } from '../../../utils/iconUtils';
import Business from '../../../types/business';
import { associationsRaceService } from '../../../services/associationsRaceService';

export default function BusinessCard({ business }: {
  business: Business
}): JSX.Element {
  const [userCount, setUserCount] = React.useState(0);
  const { t } = useTranslation('', { keyPrefix: 'page.race.association' });

  useEffect(() => {
    if (business.businessId !== undefined && business.businessId !== null) {
      associationsRaceService.getUsersInCommunity(business.businessId).then(setUserCount);
    }
  }, [business.businessId]);

  return (
    <div
      className="w-72 rounded-lg border border-primary-900 hover:bg-primary-300 hover:bg-opacity-10 relative transition-all group">
      {business.picture ? (
        <ApiImage
          alt="business-profile"
          path={business.picture}
          className="w-full h-48 rounded-t-lg object-cover"
        />
      ) : (
        <div className="w-full h-48 rounded-t-lg text-primary-900 flex items-center mx-auto">
          <Icon
            className="w-20 h-20 text-primary-900 mx-auto my-auto"
            icon={businessTypeIcons[business.type]}
          />
        </div>
      )}
      <div className=" w-full p-4 py-3 flex border-t border-primary-900 flex-col justify-between gap-2 flex-wrap wrap">
        <p className="font-medium  break-words overflow-hidden w-full">{business.name}</p>
        <p className="font-medium  break-words overflow-hidden">{t('userCount')}: {userCount}</p>
        <p className="" />
      </div>
    </div>
  );
}
