import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../buttons/Button';

export default function PremiumRequiredCard({ description }: { description?: string }) {
  const navigate = useNavigate();
  const { t } = useTranslation('', { keyPrefix: "page.subscriptions" });

  return (
    <div className="flex max-w-lg flex-col items-center justify-center gap-2 rounded-lg bg-white px-20 py-6 shadow-lg">
      <img src="/assets/figures/heart.svg" alt="Alt" className="max-w-md" />
      <h1 className="flex items-center gap-1 text-center font-serif text-2xl font-semibold">
        {t('subscriptionRequired')}
      </h1>
      <p className="py-4 text-center">
        {description || t('subscriptionRequiredDescription')}
      </p>
      <Button
        onClick={() => navigate('/settings/subscriptions')}
        variant="secondary"
        className="rounded-full px-6 text-lg font-bold">
        {t('upgradeButton')}
      </Button>
    </div>
  );
}
