import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Communications,
  PostcardOrder,
  ScheduledEmail,
  ScheduledNotification,
} from '../../types/communication';

export interface CommunicationsState {
  communications: Record<number, Communications>;
  templatesLoading: boolean;
}
const initialState: CommunicationsState = {
  communications: {},
  templatesLoading: false,
};

const empty: Communications = {
  emails: [],
  notifications: [],
  postcards: [],
};

const communicationsSlice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    setTemplatesLoading: (state, action: PayloadAction<boolean>): void => {
      state.templatesLoading = action.payload;
    },
    setEmails: (
      state,
      action: PayloadAction<{ businessId: number; emails: ScheduledEmail[] }>,
    ): void => {
      const { businessId, emails } = action.payload;
      if (!state.communications[businessId]) state.communications[businessId] = empty;

      state.communications[businessId].emails = emails;
    },
    setNotifications: (
      state,
      action: PayloadAction<{ businessId: number; notifications: ScheduledNotification[] }>,
    ): void => {
      const { businessId, notifications } = action.payload;
      if (!state.communications[businessId]) state.communications[businessId] = empty;

      state.communications[businessId].notifications = notifications;
    },
    setPostcards: (
      state,
      action: PayloadAction<{ businessId: number; postcards: PostcardOrder[] }>,
    ): void => {
      const { businessId, postcards } = action.payload;
      if (!state.communications[businessId]) state.communications[businessId] = empty;

      state.communications[businessId].postcards = postcards;
    },
    deleteEmail: (state, action: PayloadAction<{ businessId: number; emailId: number }>): void => {
      const { businessId, emailId } = action.payload;
      if (state.communications[businessId])
        state.communications[businessId].emails = state.communications[businessId].emails.filter(
          (e) => e.emailId !== emailId,
        );
    },
    deleteNotification: (
      state,
      action: PayloadAction<{ businessId: number; notificationId: number }>,
    ): void => {
      const { businessId, notificationId } = action.payload;
      if (state.communications[businessId])
        state.communications[businessId].notifications = state.communications[
          businessId
        ].notifications.filter((n) => n.notificationId !== notificationId);
    },

    updateEmail: (
      state,
      action: PayloadAction<{ businessId: number; email: ScheduledEmail }>,
    ): void => {
      const { businessId, email } = action.payload;
      if (!state.communications[businessId]) state.communications[businessId] = empty;
      state.communications[businessId].emails = state.communications[businessId].emails.map((e) =>
        e.emailId === email.emailId ? email : e,
      );
      if (!state.communications[businessId].emails.find((e) => e.emailId === email.emailId))
        state.communications[businessId].emails.push(email);
    },
    updateNotification: (
      state,
      action: PayloadAction<{ businessId: number; notification: ScheduledNotification }>,
    ): void => {
      const { businessId, notification } = action.payload;
      if (!state.communications[businessId]) state.communications[businessId] = empty;
      state.communications[businessId].notifications = state.communications[
        businessId
      ].notifications.map((n) =>
        n.notificationId === notification.notificationId ? notification : n,
      );
      if (
        !state.communications[businessId].notifications.find(
          (n) => n.notificationId === notification.notificationId,
        )
      )
        state.communications[businessId].notifications.push(notification);
    },
    updatePostcard: (
      state,
      action: PayloadAction<{ businessId: number; postcard: PostcardOrder }>,
    ): void => {
      const { businessId, postcard } = action.payload;
      if (!state.communications[businessId]) state.communications[businessId] = empty;
      state.communications[businessId].postcards = state.communications[businessId].postcards.map(
        (p) => (p.orderId === postcard.orderId ? postcard : p),
      );
      if (!state.communications[businessId].postcards.find((p) => p.orderId === postcard.orderId))
        state.communications[businessId].postcards.push(postcard);
    },
  },
});

const communicationsReducer = communicationsSlice.reducer;

export const {
  setEmails,
  setNotifications,
  deleteEmail,
  deleteNotification,
  updateEmail,
  updateNotification,
  setPostcards,
  updatePostcard,
  setTemplatesLoading,
} = communicationsSlice.actions;
export default communicationsReducer;
