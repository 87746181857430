import React from 'react';
import { toast } from 'react-toastify';
import { TbMailShare, TbMailUp, TbTrash } from 'react-icons/tb';
import { Admin, AdminRole } from '../../../types/business';
import adminService from '../../../services/adminService';
import WarningModal from '../modals/WarningModal';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import useAdmins from '../../../hooks/business/useAdmins';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { getUserId } from '../../../services/httpService';
import Button from '../buttons/Button';
import { ActionMenu, ActionMenuButton } from '../menus/ActionMenu';

interface AdminActionsProps {
  admin: Admin;
}
export default function AdminActions({ admin }: AdminActionsProps): JSX.Element {
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  const { t } = useBusinessTranslation();
  const admins = useAdmins().filter((x) => x.role === AdminRole.OWNER && !x.pending);
  const { role } = useSelectedBusiness() || {};

  const handleDelete = async (): Promise<void> => {
    if (!admin.pending) {
      await adminService.deleteAdmins([admin.adminId!]);
    } else {
      await adminService.deleteAdminInvites([admin.inviteId!]);
    }
  };

  const handleInviteResend = async (): Promise<void> => {
    await adminService.resendInviteLink(admin.inviteId!);
    toast.success(t('toast.success.inviteSentAgain'));
  };

  const handleUpgradeToOwner = async (): Promise<void> => {
    await adminService.upgradeAdminToOwner(admin.adminId!);
    toast.success(t('toast.success.upgradedAdmin'));
  };

  const handleLeave = async (): Promise<void> => {
    await adminService.leaveBusiness();
    toast.success(t('toast.success.leftMoment'));
  };

  if (admin.userId === +getUserId()) {
    if (admins.length === 1 && admins[0].userId === +getUserId()) return <></>;
    return (
      <>
        <Button
          variant="danger-v2"
          onClick={(): void => setDeleteDialog(true)}
          className="text-xs">
          {t('component.actions.admin.leave')}
        </Button>
        <WarningModal
          open={deleteDialog}
          setOpen={setDeleteDialog}
          onClose={handleLeave}
          title={t('warning.leaveBusiness.title')}
          saveClassName="bg-danger text-secondary-50"
          description={t('warning.leaveBusiness.description')}
          button={t('warning.leaveBusiness.title')}
        />
      </>
    );
  }

  if (role !== AdminRole.OWNER) return <></>;

  return (
    <>
      <ActionMenu>
        {admin.pending && (
          <ActionMenuButton icon={TbMailShare} text={t('component.actions.admin.resendInvite')} onClick={handleInviteResend} />
        )}
        {!admin.pending && admin.role !== AdminRole.OWNER && (
          <ActionMenuButton icon={TbMailUp} text={t('component.actions.admin.makeOwner')} onClick={handleUpgradeToOwner} />
        )}
        <ActionMenuButton className="text-danger" icon={TbTrash} text={!admin.pending
          ? t('component.actions.admin.removeAdmin')
          : t('component.actions.admin.removeInvite')} onClick={(): void => setDeleteDialog(true)} />
      </ActionMenu>
      <WarningModal
        open={deleteDialog}
        setOpen={setDeleteDialog}
        onClose={(): Promise<void> => handleDelete()}
        title={
          !admin.pending ? t('warning.deleteAdmin.title') : t('warning.deleteAdminInvite.title')
        }
        saveClassName="bg-danger text-secondary-50"
        description={
          !admin.pending
            ? t('warning.deleteAdmin.description')
            : t('warning.deleteAdminInvite.description')
        }
        button={
          !admin.pending ? t('warning.deleteAdmin.title') : t('warning.deleteAdminInvite.title')
        }
      />
    </>
  );
}
