import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineCalendar, HiUserGroup } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { FaRegClock, FaWhatsapp } from 'react-icons/fa';
import classNames from 'classnames';
import { FaArrowRight } from 'react-icons/fa6';
import PageHeader from '../components/misc/PageHeader';
import Avatar from '../components/misc/Avatar';
import { Event } from '../../types/event';
import useSelectedBusiness from '../../hooks/business/useSelectedBusiness';
import IconBadge from '../components/icons/IconBadge';
import Button from '../components/buttons/Button';
import connectionService from '../../services/connectionService';
import { RootState } from '../../redux/reducers';
import {
  PendingBadge,
  UnverifiedBadge,
  VerifiedBadge,
} from '../components/icons/ConnectionTypeBadge';
import { ConnectionType } from '../../redux/slices/connectionsSlice';
import ApiImage from '../components/misc/ApiImage';
import eventService from '../../services/eventService';
import useEvents from '../../hooks/business/useEvents';
import eventUtils from '../../utils/eventUtils';
import { isCommunity } from '../../constants';

export default function OverviewPage() {
  const business = useSelectedBusiness();
  const { t } = useTranslation();
  const {
    connections,
    application: { onboardingStep },
  } = useSelector((state: RootState) => state);
  const role = useSelector((state: RootState) => state.application.role);
  const navigate = useNavigate();
  const event = useEvents()
    .filter((e) => !e.isDatePicker)
    .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())[0] as
    | Event
    | undefined;

  if (!business) return <></>;

  useEffect(() => {
    console.log(onboardingStep);
    if (onboardingStep !== undefined) navigate('/contacts');
  }, []);

  useEffect(() => {
    connectionService.getConnections();
    eventService.getUpcomingEvents();
  }, []);

  return (
    <>
      <PageHeader
        title={t('page.overview.title')}
        className="pb-4"
      />
      <div className="flex flex-col gap-6 lg:flex-row">
        <div className="flex w-full flex-col gap-2 rounded-[20px] border-2 border-secondary-200 bg-secondary-200 bg-opacity-50 px-4 py-5 shadow-[0px_4px_4px_0px_#EDD5C940]">
          <h2 className="font-serif text-[20px]">{t('page.overview.information')}</h2>
          <div className="flex items-center gap-4 text-wrap">
            <Avatar
              src={business.picture ?? ''}
              className="h-[75px] w-[75px] text-2xl"
              alias={business?.name}
            />
            <p className="text-[17px] font-medium">{business.name}</p>
          </div>
          <p className="text-[15px] font-medium">{business.description}</p>
          {business.communityLink && (
            <div className="flex items-center gap-2">
              <IconBadge icon={FaWhatsapp} className="h-7 w-7" />
              <p className="text-[15px] font-medium">{business.communityLink}</p>
            </div>
          )}
          <Link to="/settings" className="ml-auto mt-auto">
            <Button variant="primary" className="mt-2 text-[15px]">
              {t('page.overview.more')}
            </Button>
          </Link>
        </div>
        <div className="flex w-full flex-col gap-3 rounded-[20px] border-2 border-secondary-200 bg-secondary-200 bg-opacity-50 px-4 py-5 shadow-[0px_4px_4px_0px_#EDD5C940]">
          <h2 className="font-serif text-[20px]">Contacts</h2>
          <div className="flex items-center gap-2">
            <IconBadge icon={HiUserGroup} className="h-7 w-7" />
            <p className="text-[15px] font-medium">
              {connections.length} {t('general.total')}
            </p>
          </div>

          <div className="flex items-center gap-2 text-[15px] font-medium">
            <VerifiedBadge />
            <p className="text-[15px] font-medium">
              {
                connections.filter((c) =>
                  [
                    ConnectionType.B2C_CONNECTION,
                    ConnectionType.PERSONAL_MOMENT_CONNECTION,
                  ].includes(c.type),
                ).length
              }{' '}
              {t('component.verifiedDisplay.verified')}
            </p>
          </div>
          <div className="flex items-center gap-2 text-[15px] font-medium">
            <PendingBadge />
            <p className="text-[15px] font-medium">
              {
                connections.filter((c) => c.type === ConnectionType.LOCAL_CONTACT && c.inviteSent)
                  .length
              }{' '}
              {t('component.verifiedDisplay.pending')}
            </p>
          </div>
          <div className="flex items-center gap-2 text-[15px] font-medium">
            <UnverifiedBadge />
            <p className="text-[15px] font-medium">
              {
                connections.filter((c) => c.type === ConnectionType.LOCAL_CONTACT && !c.inviteSent)
                  .length
              }{' '}
              {t('component.verifiedDisplay.unverified')}
            </p>
          </div>
          <Link to="/contacts" className="ml-auto mt-auto">
            <Button variant="primary" className="text-[15px]">
              {t('page.overview.more')}
            </Button>
          </Link>
        </div>
        {isCommunity && (
          <div className="flex w-full flex-col gap-4 rounded-[20px] border-2 border-secondary-200 bg-secondary-200 bg-opacity-50 px-4 py-5 shadow-[0px_4px_4px_0px_#EDD5C940]">
            <div className="flex items-center justify-between gap-2">
              <h2 className="font-serif text-[20px]">{t('page.overview.events')}</h2>
              {event && (
                <p className="text-opacity-50 text-right text-[13px]">
                  {t('page.overview.nextEvent')}
                </p>
              )}
            </div>
            {event ? (
              <>
                <div className="-mt-2 flex items-center gap-4">
                  {event?.picture && (
                    <ApiImage path={event.picture} className="h-[75px] w-[75px] rounded-[8px]" />
                  )}
                  <p className="text-[17px] font-medium">{event?.title}</p>
                </div>
                <p className="-mt-2 text-[15px] font-medium">{event.description}</p>
                <div className="flex items-center gap-2">
                  <IconBadge icon={HiOutlineCalendar} className="h-7 w-7" />
                  <p className="text-[15px] font-medium">{eventUtils.formatDate(event, 'date')}</p>
                </div>
                <div className="flex items-center gap-2">
                  <IconBadge icon={FaRegClock} className="h-7 w-7" />
                  <p className="text-[15px] font-medium">{eventUtils.formatDate(event, 'time')}</p>
                </div>
              </>
            ) : (
              <>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/figures/drop_sad.svg`}
                  alt="Vera"
                  className="mx-auto mt-2 w-[25%]"
                />
                <p className="mx-auto text-[13px] font-medium">{t('page.overview.noEvents')}</p>
              </>
            )}
            <Link to="/events" className="ml-auto mt-auto">
              <Button variant="primary" className="mt-2 text-[15px]">
                {t('page.overview.more')}
              </Button>
            </Link>
          </div>
        )}
      </div>
      {role === 'ADMIN' && (
        <div className="mt-10">
          <div
            className="relative w-full bg-gradient-to-br from-primary-300 to-secondary p-4 h-60 rounded-md
        before:bg-gradient-to-br before:absolute before:inset-0 before:content-[''] before:from-primary-300
        before:to-secondary before:animate-blur-breathe">
            <div className="w-[calc(100%-0.5rem)] h-[14.5rem] left-1 top-1 absolute bg-primary-900 flex flex-col text-secondary-50 rounded-md p-4">
              <h2 className="text-secondary-50 text-2xl font-serif">🚀 A race to the top!</h2>
              <p className="text-secondary-50 w-full md:w-[40rem]">
                Show that your association is the best by inviting as many participating communities
                as possible! We offer you a free month of PRO subscription for every participating
                community that is a part of your association. Terms and conditions apply.
              </p>
              <div className="self-end flex-1 flex items-end flex-wrap gap-2 mt-4">
                <Button onClick={() => navigate('/race')} className="text-secondary-50">
                  Learn more
                </Button>
                <Button className="bg-gradient-to-br from-primary-300 to-secondary-200 shadow-primary-300 shadow-sm hover:shadow-primary-300 hover:shadow-md text-primary-900">
                  Join now!
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
