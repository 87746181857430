import React, { useEffect, useRef, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AiOutlinePlus } from 'react-icons/ai';
import classNames from 'classnames';
import ModalLayout from '../layouts/ModalLayout';
import ModalHeader from '../misc/ModalHeader';
import { isValidEmail } from '../../../utils/stringUtils';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import CancelSaveButton from '../misc/CancelSaveButton';
import Button from '../buttons/Button';
import { RootState } from '../../../redux/reducers';
import usePersonalConnections from '../../../hooks/account/usePersonalConnections';
import ConnectionsList from '../misc/ConnectionsList';
import connectionService from '../../../services/connectionService';
import userService from '../../../services/userService';
import SearchBar from '../forms/SearchBar';
import adminService from '../../../services/adminService';
import { AdminRole } from '../../../types/business';
import useAdmins from '../../../hooks/business/useAdmins';

interface AddAdminModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddAdminModal({ open, setOpen }: AddAdminModalProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const connections = useSelector((state: RootState) => state.connections);
  const personalConnections = usePersonalConnections();
  const adminIds = useAdmins()
    .filter((a) => a.userId)
    .map((a) => a.userId);

  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [userIds, setUserIds] = useState<string[]>([]);
  const [mode, setMode] = useState<'BUSINESS' | 'PERSONAL'>('BUSINESS');
  const [search, setSearch] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!connections.length) connectionService.getConnections();
    if (!personalConnections.length) userService.getConnections();
  }, []);

  useEffect(() => {
    setEmails([]);
    setUserIds([]);
  }, [open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(event.target.value);
  };

  const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault(); // Prevent space from being typed
      const trimmedEmail = currentEmail.trim();
      if (!trimmedEmail) return;
      if (emails.includes(trimmedEmail)) {
        toast.error(t('component.modal.addAdmin.error.emailAdded'));
        return;
      }
      if (trimmedEmail !== '' && isValidEmail(trimmedEmail)) {
        setEmails([...emails, trimmedEmail]);
        setCurrentEmail('');
      } else {
        toast.error(t('component.modal.addAdmin.error.invalidEmail'));
      }
    } else if (event.key === 'Backspace' && currentEmail === '' && emails.length > 0) {
      const newEmails = [...emails];
      const removedEmail = newEmails.pop();
      setCurrentEmail(removedEmail || '');
      setEmails(newEmails);
    }

    const container = containerRef.current;
    if (container) {
      container.scroll({
        left: container.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  const handleRemoveEmail = (index: number) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  const handleSubmit = async () => {
    await Promise.all([
      adminService.inviteByUserIds(
        userIds.map((id) => +id),
        AdminRole.OWNER,
      ),
      adminService.sendAdminInvites(emails, AdminRole.OWNER),
    ]);
    toast.success(t('component.modal.addAdmin.sent', { count: userIds.length + emails.length }));
    setOpen(false);
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="z-50 mx-6 flex w-fit min-w-[450px] max-w-[650px] flex-col gap-[20px] rounded-[20px] bg-secondary-50 px-10 py-6 pb-4 pt-5">
      <ModalHeader
        icon={FaPaperPlane}
        title={t('component.modal.addAdmin.title')}
        subtitle={t('component.modal.addAdmin.subtitle')}
      />

      <div className="w-full border-b border-primary-300" />

      <h2 className="font-serif text-[22px] leading-[24px]">
        {t('component.modal.addAdmin.emailTitle')}
      </h2>
      <div className="flex w-full flex-col">
        <p className="font-medium">{t('component.modal.addAdmin.emails')}</p>
        <div className="no-scrollbar relative flex h-[50px] w-full items-center overflow-x-scroll rounded-[15px] border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 px-4 pr-[100px] text-primary-900 transition-colors focus:border-primary-300 focus:border-opacity-100 md:max-w-full">
          <div
            ref={containerRef}
            className="no-scrollbar mr-[20px] flex w-full items-center overflow-x-scroll">
            {emails.map((email, index) => (
              <div
                key={index}
                className="group relative mr-1 flex items-center rounded-md bg-secondary-200 px-2 py-1 text-sm text-primary-900">
                {email}
                <div
                  className="shadow-3xl absolute right-0 ml-1 flex h-full cursor-pointer items-center rounded-r-md bg-secondary-200 opacity-0 transition-all group-hover:opacity-100"
                  onClick={() => handleRemoveEmail(index)}>
                  <AiOutlinePlus className="h-5 w-5 rotate-45 text-primary-900" />
                </div>
              </div>
            ))}
            <input
              className="!focus:border-transparent !focus:ring-0 h-[50px] !w-full !min-w-[200px] !rounded-none border !border-transparent !bg-opacity-0 !px-0"
              type="text"
              value={currentEmail}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
            />
          </div>
        </div>
        <p className="flex w-full justify-end px-3 text-sm">
          {t('component.modal.addAdmin.instruction')}
        </p>
      </div>

      <div className="w-full border-b border-primary-300" />

      <h2 className="font-serif text-[22px] leading-[24px]">
        {t('component.modal.addAdmin.connectionsTitle')}
      </h2>

      <div className="flex w-full items-center justify-center gap-4">
        <Button
          onClick={() => setMode('BUSINESS')}
          className={classNames({ 'bg-primary-300': mode === 'BUSINESS' })}>
          {t('component.modal.addAdmin.businessButton')}
        </Button>
        <Button
          onClick={() => setMode('PERSONAL')}
          className={classNames({ 'bg-primary-300': mode === 'PERSONAL' })}>
          {t('component.modal.addAdmin.personalButton')}
        </Button>
      </div>
      <SearchBar search={search} setSearch={setSearch} />
      <ConnectionsList
        selected={userIds}
        setSelected={setUserIds}
        hasSearch={false}
        className="border-none py-0"
        search={search}
        connections={
          mode === 'BUSINESS'
            ? connections
                .filter((c) => c.userId)
                .map(({ userId, fields: { FIRST_NAME, LAST_NAME } }) => ({
                  id: userId,
                  alias: `${FIRST_NAME}+${LAST_NAME}`,
                  disabled: adminIds.includes(userId),
                }))
            : personalConnections.map(({ userId, firstName, lastName }) => ({
                id: userId,
                alias: `${firstName}+${lastName}`,
                disabled: adminIds.includes(userId),
              }))
        }
      />

      <div className="w-full border-b border-primary-300" />

      <div className="flex flex-col items-end gap-4 sm:flex-row sm:items-center sm:justify-between">
        <p>
          {userIds.length + emails.length} {t('component.modal.addAdmin.selected')}
        </p>
        <CancelSaveButton
          buttonText={t('component.modal.addAdmin.invite')}
          onCancel={() => setOpen(false)}
          disabled={emails.length === 0 && userIds.length === 0}
          onSave={handleSubmit}
        />
      </div>
    </ModalLayout>
  );
}
