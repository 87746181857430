import React, { useMemo } from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Event, ZERO_PLACEHOLDER } from '../../../types/event';
import { COLOR_PRIMARY_300, COLOR_PRIMARY_900 } from '../../../constants';
import AttendanceTooltip from './components/AttendanceTooltip';
import eventUtils from '../../../utils/eventUtils';
import { decimalToPercentageString } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';

type AttendancePercentageGraphProps = {
  events: Event[];
  type: 'PER_EVENT' | 'PER_USER';
};

export default function AttendanceGraph({
  events,
  type,
}: AttendancePercentageGraphProps): JSX.Element {
  const isLg = useIsScreenSize('lg');
  const isMd = useIsScreenSize('md');

  const attendance = useMemo(() => {
    if (type === 'PER_USER') {
      return eventUtils.transformToAttendanceEntriesPerUser(events);
    }
    return eventUtils.transformToAttendanceEntriesPerEvent(events);
  }, [events, type]);

  const height = Math.max(50 * attendance.length, 350);
  const width = isLg ? '100%' : '50%';

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={attendance.map(([, value]) => ({
          ...value,
          yesFraction: value.yesFraction === 0 ? ZERO_PLACEHOLDER : value.yesFraction,
        }))}
        margin={{ left: 10, top: 20 }}
        layout="vertical">
        <XAxis
          type="number"
          domain={[0, 1]}
          tickFormatter={decimalToPercentageString}
          stroke={COLOR_PRIMARY_900}
        />
        <YAxis
          type="category"
          dataKey="name"
          interval={0}
          textAnchor="end"
          width={isMd ? undefined : 200}
          fontSize={isMd ? 12 : undefined}
          stroke={COLOR_PRIMARY_900}
        />
        <Tooltip cursor={{ fill: 'rgba(31,39,59,0.07)' }} content={AttendanceTooltip} />
        <Bar
          dataKey="yesFraction"
          layout="vertical"
          radius={[0, 20, 20, 0]}
          maxBarSize={20}
          fill={COLOR_PRIMARY_300}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
