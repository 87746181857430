import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { EventParticipationAnswer } from '../../../types/event';
import ParticipationBadge from '../badges/ParticipationBadge';

type SelectAttendanceTypesProps = {
  selectedTypes: EventParticipationAnswer[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<EventParticipationAnswer[]>>;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function SelectAttendanceTypes({
  selectedTypes,
  setSelectedTypes,
  className,
  ...props
}: SelectAttendanceTypesProps): JSX.Element {
  const { t } = useTranslation();

  const toggleAttendanceType = (type: EventParticipationAnswer) => {
    setSelectedTypes((prev) => {
      if (prev.includes(type)) {
        return prev.filter((ans) => ans !== type);
      }
      return [...prev, type];
    });
  };

  return (
    <div className={twMerge('flex flex-col gap-1', className)} {...props}>
      {_.entries(EventParticipationAnswer).map(([key, value]) => (
        <button
          type="button"
          onClick={() => toggleAttendanceType(value)}
          className={classNames(
            'flex items-center justify-between gap-1 rounded-md border border-secondary-200 px-2 py-2 transition',
            { 'bg-secondary-200': selectedTypes.includes(value) },
          )}>
          <div className="flex items-center gap-2">
            <ParticipationBadge type={value} size="sm" />
            {t(`page.eventDetails.answerTitle.${key.toLowerCase()}`)}
          </div>
          <input
            type="checkbox"
            checked={selectedTypes.includes(value)}
            className="m-1 rounded-full border-secondary-200"
          />
        </button>
      ))}
    </div>
  );
}
