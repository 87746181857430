import { Address } from '../types/Profile';
import { PostcardTemplate } from '../types/misc';
import httpService from './httpService';
import { DiscountResponse } from './model/orderService.model';
import { getImagePath } from '../utils/imagePathUtils';
import { getSelectedBusiness } from '../hooks/business/useSelectedBusiness';
import { PostcardOrder } from '../types/communication';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';
import { updatePostcard } from '../redux/slices/communicationsSlice';

export interface UploadCustomPostcardResponse {
  id: string;
  fileName: string;
  size: number;
  fileExtension: string;
}

export interface UploadCustomPostcardImageResponse {
  id: number;
  fileName: string;
  thumbnail: string;
  externalUploadedId: string;
  businessId: number;
}

const orderService = {
  placeAddressLabelOrder: async (
    connectionIds: string[],
    localContactIds: string[],
    momentConnectionIds: string[],
    amount: number,
    addressRecipient: Address,
    clientSecret: string,
    code: string,
  ): Promise<void> => {
    await httpService.post('business/order/address-label', {
      connectionIds,
      localContactIds,
      momentConnectionIds,
      amount,
      addressRecipient,
      clientSecret,
      code,
    });
  },

  placePostcardOrder: async (order: Omit<PostcardOrder, 'orderId'>): Promise<void> => {
    await httpService.post('/business/order/postcard', order);
  },

  createPaymentIntent: async (amount: number): Promise<string> => {
    const res = await httpService.post('/payment-intent', { amount });
    return res.data.clientSecret;
  },

  getPostcardTemplates: async (): Promise<PostcardTemplate[]> => {
    const { data } = await httpService.get<PostcardTemplate[]>('/templates');
    return data.filter((t) => t.format === 'POSTCARD_SQ15');
  },

  getPostcardImagesByBusinessId: async (
    businessId: number,
  ): Promise<UploadCustomPostcardImageResponse[]> => {
    const { data } = await httpService.get<UploadCustomPostcardImageResponse[]>(
      `/postcard/custom/${businessId}`,
    );

    data.forEach((image) => {
      image.thumbnail = getImagePath(image.thumbnail);
    });
    return data;
  },

  uploadCustomPostcard: async (
    file: File,
    businessId: number,
  ): Promise<UploadCustomPostcardResponse> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('businessId', businessId.toString());
    return httpService
      .post('/postcard/custom', formData)
      .then(({ data }) => {
        return {
          id: data.id,
          fileName: data.fileName,
          size: data.size,
          fileExtension: data.fileExtension,
        };
      })
      .catch((error) => error);
  },

  deleteCustomPostcardImage: async (id: number): Promise<void> => {
    await httpService.delete(`/postcard/custom/${id}`);
  },

  getDiscount: async (code: string): Promise<number> => {
    return httpService
      .get<DiscountResponse>(`/business/order/discount/${code}`)
      .then(({ data }) => data.discount);
  },
  getAllPostcardTemplates: async (): Promise<PostcardTemplate[]> => {
    return httpService
      .post<PostcardTemplate[]>('/postcard', { businessIds: [getSelectedBusiness()?.businessId] })
      .then(({ data }) => data);
  },

  updatePostcardcardOrder: async (order: UpdatePostCardOrderRequest): Promise<void> => {
    return httpService.put('/business/order/postcard', order).then(() => {
      const postcard = (store.getState() as RootState).communications.communications[
        getSelectedBusiness()?.businessId!
      ]?.postcards.find((c) => c.orderId === order.orderId);
      store.dispatch(
        updatePostcard({
          businessId: getSelectedBusiness()?.businessId!,
          postcard: { ...postcard!, ...order },
        }),
      );
    });
  },
};

export default orderService;

interface UpdatePostCardOrderRequest {
  message: string;
  sendDate: Date;
  senderAddress: Address;
  template: string;
  orderId: number;
}
