import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FaCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/misc/PageHeader';
import { SubscriptionType } from '../../../types/misc';
import { COLOR_PRIMARY_900 } from '../../../constants';
import { baseSubscriptionPrices, subscriptionFeatures } from '../../../utils/subscriptionUtils';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import businessService from '../../../services/businessService';

export function SubscriptionsPage() {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.subscriptions' });
  const activeSubscription = useActiveSubscription();
  const navigate = useNavigate();

  useEffect(() => {
    businessService.fetchBusinesses();
  }, []);

  // Make grid into grid-cols-5 on the div under PageHeader to use a Base subscription also
  return (
    <>
      <PageHeader
        title={t('title')}
        subtitle={t('subtitle')}
        className="mb-6 border-b border-primary-300 pb-6"
      />
      <div className="grid grid-cols-4">
        <div className="col-span-2" />
        {[SubscriptionType.FREE, SubscriptionType.PRO].map((type) => (
          <div
            key={`${type}_subscription_details`}
            className={classNames(
              'flex flex-col justify-between gap-2 rounded-t-3xl p-4 text-secondary-50',
              {
                'bg-secondary': type === SubscriptionType.FREE,
                'bg-primary-300': type === SubscriptionType.BASE,
                'bg-primary-900': type === SubscriptionType.PRO,
              },
            )}>
            {type === activeSubscription ? (
              <p
                className={classNames(
                  'ml-1 w-fit self-center rounded-md px-2 py-0.5 text-center text-sm font-bold uppercase',
                  {
                    'bg-secondary-50 text-primary-900': type === SubscriptionType.PRO,
                    'bg-primary-900 text-secondary-50':
                      type === SubscriptionType.BASE || type === SubscriptionType.FREE,
                  },
                )}>
                {t('activeSubscription')}
              </p>
            ) : (
              <div />
            )}
            <div className="justify-self-start">
              <h2 className="text-xl font-bold text-secondary-50">
                {t(`${type.toLowerCase()}.title`)}
              </h2>
              <p className="py-2 text-secondary-50">{t(`${type.toLowerCase()}.subtitle`)}</p>
              <p className="pt-1 text-sm text-secondary-50">{t('from')}</p>
              <div className="flex items-end">
                <span className="text-4xl text-secondary-50">
                  &euro;{baseSubscriptionPrices[type as SubscriptionType]}
                </span>
                {t('perMonth')}
              </div>
            </div>
            <div>
              {type !== SubscriptionType.FREE && (
                <button
                  onClick={() => {
                    if (activeSubscription === type) {
                      navigate('/subscriptions/manage');
                    } else {
                      navigate('/subscriptions/upgrade', {
                        state: { subscriptionType: type },
                      });
                    }
                  }}
                  type="button"
                  className="w-full justify-self-end rounded-lg border-2 border-secondary-50 bg-secondary-50 px-2 py-2 text-center text-lg font-extrabold text-primary-900 transition-all hover:bg-transparent hover:text-secondary-50">
                  {activeSubscription === type ? t('manageSubscription') : t('subscribe')}
                </button>
              )}
            </div>
          </div>
        ))}

        <FeatureList />
        {[SubscriptionType.FREE, SubscriptionType.PRO].map((type) => (
          <SubscriptionFeatures key={`${type}_subscription_features`} subscriptionType={type} />
        ))}
      </div>
    </>
  );
}

function FeatureList() {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.subscriptions' });

  return (
    <div className="col-span-2 flex flex-col rounded-l-3xl bg-secondary-200 py-4 pl-4">
      <h3 className="py-4 text-lg font-bold">{t('functionalities')}</h3>
      {subscriptionFeatures.map((feature) => (
        <p className="py-3 pl-2 font-semibold" key={feature.i18nKey}>
          {t(feature.i18nKey)}
          {feature.isComingSoon && (
            <span className="ml-1 rounded-md bg-primary-900 px-2 py-0.5 text-sm text-secondary-50">
              {t('comingSoon')}
            </span>
          )}
        </p>
      ))}
    </div>
  );
}

type SubscriptionFeaturesProps = {
  subscriptionType: SubscriptionType;
};

function SubscriptionFeatures({ subscriptionType }: SubscriptionFeaturesProps) {
  return (
    <div
      className={classNames('flex flex-col items-center py-4', {
        'bg-secondary': subscriptionType === SubscriptionType.FREE,
        'bg-primary-300': subscriptionType === SubscriptionType.BASE,
        'rounded-br-3xl bg-primary-900': subscriptionType === SubscriptionType.PRO,
      })}>
      <div className="h-14" />
      {subscriptionFeatures.map((feature) => (
        <div className="flex h-12 items-center justify-center">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-secondary-200">
            {feature[subscriptionType] ? (
              <FaCheck strokeWidth={18} stroke={COLOR_PRIMARY_900} />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}
