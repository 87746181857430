import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { HiTrash, HiX } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TagState } from '../../../types/Tag';
import { RootState } from '../../../redux/reducers';
import tagService from '../../../services/tagService';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { addTag, removeTag } from '../../../utils/connectionUtils';

interface TagButtonProps {
  children: React.ReactNode;
  connections: Connection[];
  className?: string;
}

export default function TagButton({
  children,
  connections,
  className = '',
}: TagButtonProps): JSX.Element {
  const [visibleTags, setVisibleTags] = React.useState<TagState[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const { t } = useTranslation();

  const tags = useSelector((state: RootState) => state.tags);
  const colors = ['#D9F3F2', '#FFE1E1', '#FFF0E0', '#D8E6F2', '#F2E4D8', '#F2F1D8'];

  useEffect((): void => {
    setVisibleTags(
      tags.filter((tag): boolean => tag.name.toLowerCase().includes(inputValue.toLowerCase())),
    );
  }, [tags]);

  useEffect((): void => {
    if (inputValue) {
      const filteredTags = tags.filter((tag): boolean =>
        tag.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setVisibleTags(filteredTags);
    } else {
      setVisibleTags(tags);
    }
  }, [inputValue]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
    e.preventDefault();
  };

  const onTagClick = async (tagId: string): Promise<void> => {
    await addTag(
      tagId,
      connections.map(({ id, type }) => ({ id, type })),
    );
    setInputValue('');
  };

  const handleTagDelete = async (id: string): Promise<void> => {
    tagService.deleteTag(id);
  };

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === ' ') {
      if (inputValue !== '') setInputValue(`${inputValue} `);
      event.preventDefault();
    }
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      const { tagId } = await tagService.addTag(event.currentTarget.value, calculateColor());
      setInputValue('');
      onTagClick(tagId);
    }
  };

  const calculateColor = (): string => {
    const colorMap = new Map<string, number>();
    const counts: number[] = [];

    for (let i = 0; i < colors.length; i += 1) {
      const color = colors[i];
      colorMap.set(color, i);
      counts.push(0);
    }

    for (let i = 0; i < tags.length; i += 1) {
      const tag = tags[i];
      const colorIndex = colorMap.get(tag.color) ?? -1;
      if (colorIndex !== -1) counts[colorIndex] += 1;
    }
    let leastUsedColor = colors[0];
    let leastUsedColorCount = counts[0];

    for (let i = 0; i < colors.length; i += 1) {
      const color = colors[i];
      const numberOfTags = counts[i];
      if (numberOfTags < leastUsedColorCount) {
        leastUsedColor = color;
        leastUsedColorCount = numberOfTags;
      }
    }

    return leastUsedColor;
  };

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button data-testid="assign-tags-dropdown" className="h-full w-full">
          {children}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items
          className={twMerge(
            'absolute -top-16 right-14 z-[50] flex max-h-52 w-fit min-w-[200px] flex-col overflow-y-auto rounded-lg bg-secondary-50 p-2 shadow-lg ring-1 ring-black ring-opacity-5',
            className,
          )}>
          <div className="flex flex-wrap items-center gap-1 pb-2 pt-1">
            {connections.length === 1 &&
              connections[0].tags
                .map((tagId) => tags.find((x) => x.tagId === tagId))
                .map(
                  (tag) =>
                    tag && (
                      <div className="flex gap-1 rounded-full bg-secondary-200 px-2">
                        <p className="text-sm">{tag.name}</p>
                        <button
                          type="button"
                          onClick={() => {
                            removeTag(tag.tagId, {
                              id: connections[0].id,
                              type: connections[0].type,
                            });
                          }}>
                          <HiX className="h-4 w-4 text-primary-900 hover:opacity-80" />
                        </button>
                      </div>
                    ),
                )}
          </div>
          <Menu.Item key="add-tag" data-testid="add-tag">
            {(): JSX.Element => (
              <input
                type="text"
                value={inputValue}
                onChange={onInputChange}
                onKeyDown={(e): Promise<void> => handleKeyPress(e)}
                onClick={(e): void => e.preventDefault()}
                placeholder={t('page.dashboard.quickAction.addTagToList')}
              />
            )}
          </Menu.Item>
          {visibleTags &&
            visibleTags.map((tag: TagState): JSX.Element | null =>
              connections.length !== 1 || !connections[0].tags.includes(tag.tagId) ? (
                <Menu.Item key={tag.tagId}>
                  {({ active }): JSX.Element => (
                    <div className="flex h-fit w-full items-center justify-between">
                      <span
                        onClick={(e): void => {
                          onTagClick(tag.tagId);
                          e.stopPropagation();
                        }}
                        className={`${
                          active ? 'bg-secondary-200' : ''
                        } flex w-full cursor-pointer items-center justify-between px-4 py-2 text-sm text-primary-900`}
                        data-testid={`tag-${tag.tagId}`}>
                        <p className="mr-10 font-normal">{tag.name}</p>
                        <button
                          data-testid={`delete-tag-${tag.tagId}`}
                          type="button"
                          className={`flex h-full items-center ${
                            active ? 'bg-secondary-200' : 'bg-secondary-50'
                          }`}
                          onClick={(e): void => {
                            handleTagDelete(tag.tagId);
                            e.stopPropagation();
                          }}>
                          <HiTrash
                            className={`h-4 w-4 text-primary-900 ${
                              active ? 'bg-secondary-200' : 'bg-secondary-50'
                            } hover:text-opacity-80`}
                          />
                        </button>
                      </span>
                    </div>
                  )}
                </Menu.Item>
              ) : null,
            )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
