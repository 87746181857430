import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from '../icons/Logo';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import useAuth from '../../../hooks/account/useAuth';
import Language from '../../../types/language';
import useLanguage from '../../../hooks/account/useLanguage';
import { setLanguage } from '../../../redux/slices/applicationSlice';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import authService from '../../../services/authService';

export default function NavBar(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const business = useSelectedBusiness();
  const dispatch = useDispatch();
  const lang = useLanguage();
  const { t } = useBusinessTranslation();

  return (
    <>
      <div className="sticky top-0 z-50 w-full">
        <Disclosure as="nav" className="flex items-center">
          {(): JSX.Element => (
            <>
              <div className="z-50 w-full rounded-b-2xl bg-secondary-200 px-5">
                <div className="z-50 mx-auto w-full max-w-7xl bg-secondary-200">
                  <div className="flex h-16 items-center justify-between">
                    <div
                      onClick={() => navigate(auth && business ? '/overview' : '/')}
                      className="flex max-w-7xl flex-1 items-center justify-start sm:items-stretch">
                      <Logo />
                    </div>
                    <div className="flex items-center gap-4 sm:static sm:inset-auto">
                      {!auth && !location?.pathname?.includes('login') && (
                        <p
                          onClick={() => {
                            navigate('/login');
                          }}
                          className="cursor-pointer font-medium">
                          {t('auth.login')}
                        </p>
                      )}
                      {auth && (
                        <p onClick={authService.logout} className="cursor-pointer font-medium">
                          {t('auth.logout')}
                        </p>
                      )}
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/flags/${
                          lang === Language.NL ? Language.EN : Language.NL
                        }.svg`}
                        alt="Nederlands"
                        onClick={() =>
                          dispatch(setLanguage(lang === Language.NL ? Language.EN : Language.NL))
                        }
                        className="h-6 w-6 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
        <Outlet />
      </div>
    </>
  );
}
