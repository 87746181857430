import React from 'react';
import { FaUserPlus } from 'react-icons/fa';
import AdminsTable from '../../components/tables/AdminsTable';
import adminService from '../../../services/adminService';
import useAdmins from '../../../hooks/business/useAdmins';
import AddAdminModal from '../../components/modals/AddAdminModal';
import SearchBar from '../../components/forms/SearchBar';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { AdminRole } from '../../../types/business';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import Button from '../../components/buttons/Button';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';
import PageHeader from '../../components/misc/PageHeader';
import PrimaryButton from '../../components/buttons/PrimaryButton';

export default function AdminOverviewPage(): JSX.Element {
  const admins = useAdmins();
  const { t } = useBusinessTranslation();
  const { role } = useSelectedBusiness() || {};

  const [modalOpen, setModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useBusinessEffect(() => {
    adminService.getAllAdmins();
    adminService.getAllAdminInvites();
  }, []);

  return (
    <>
      <PageHeader
        title={t('page.settings.admins.title')} subtitle={`${admins.length} ${t('general.total')}`}
        primaryButton={role === AdminRole.OWNER ?
          <PrimaryButton text={t('page.settings.admins.addAdmin')} icon={FaUserPlus} onClick={() => setModalOpen(true)} />
          : undefined}
      />
      <div className="mb-4 flex flex-wrap justify-between gap-2">
        <div className="flex justify-start">
          <SearchBar inputProps={{ placeholder: t('page.settings.admins.search') }} search={search} setSearch={setSearch} className="md:w-80 lg:w-96" />
        </div>
      </div>
      <div className="overflow-x-scroll flex-1">
        <AdminsTable
          data={admins.filter((x) => x.display.toLowerCase().replace('+', ' ').includes(search))}
        />
      </div>
      <AddAdminModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}
