import React, { useState } from 'react';
import { HiLink } from 'react-icons/hi';
import ModalLayout from '../../../layouts/ModalLayout';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';
import Button from '../../../buttons/Button';

interface AuthParamsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmitted: (auth: AuthParams) => void;
}

interface AuthParams {
  Username: string;
  SecurityCode1: string;
  SecurityCode2: string;
}

export default function AuthParamsModal({
  open,
  setOpen,
  onSubmitted,
}: AuthParamsModalProps): JSX.Element {
  const { t } = useBusinessTranslation();

  const [authParams, setAuthParams] = useState<AuthParams>({
    Username: '',
    SecurityCode1: '',
    SecurityCode2: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAuthParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="relative z-50 w-[45rem] min-w-[300px] rounded-2xl bg-secondary-50 p-8">
      <div className="mb-6">
        <h2 className="text-xl font-semibold">
          {t('auth.params.title', 'Authentication Parameters')}
        </h2>
        <p className="text-sm text-gray-600">
          {t('auth.params.description', 'Please enter your credentials below to continue.')}
        </p>
      </div>

      <div className="space-y-4">
        <div key="username-div" className="w-full overflow-visible">
          <p className="mb-1 pr-2 font-medium"> {t('auth.params.username')}*</p>
          <input
            type="text"
            name="Username"
            value={authParams.Username}
            onChange={handleInputChange}
          />
        </div>
        <div key="security-code-1-div" className="w-full overflow-visible">
          <p className="mb-1 pr-2 font-medium"> {t('auth.params.security-code-1')}*</p>
          <input
            type="text"
            name="SecurityCode1"
            value={authParams.SecurityCode1}
            onChange={handleInputChange}
          />
        </div>
        <div key="security-code-2-div" className="w-full overflow-visible">
          <p className="mb-1 pr-2 font-medium"> {t('auth.params.security-code-2')}*</p>
          <input
            type="text"
            name="SecurityCode2"
            value={authParams.SecurityCode2}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-6 flex">
        <Button variant="primary" onClick={() => onSubmitted(authParams)}>
          <HiLink className="inline-block h-5 w-5" />
          {' Connect'}
        </Button>
        <Button onClick={() => setOpen(false)} className="hover:text-opacity-60">
          {t('general.cancel')}
        </Button>
      </div>
    </ModalLayout>
  );
}
