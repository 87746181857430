import httpService from './httpService';
import { ScoreCard, ScoreCardCreateInfo, ScoreCardUpdateInfo, ScoreCardWithParticipationData } from '../types/lists';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';

export async function getAllScoreCards(): Promise<ScoreCardWithParticipationData[]> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .get<ScoreCardWithParticipationData[]>(`/score-cards/${selectedBusiness}`)
    .then(res => res.data);
}

export async function createScoreCard(data: ScoreCardCreateInfo): Promise<ScoreCardWithParticipationData> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .post<ScoreCardWithParticipationData>(`/score-cards/${selectedBusiness}`, data)
    .then(res => res.data);
}

export async function deleteScoreCard(scoreCardId: number): Promise<ScoreCard> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .delete<ScoreCard>(`/score-cards/${selectedBusiness}/${scoreCardId}`)
    .then(res => res.data);
}

export async function updateScoreCardScore(scoreCardId: number, connectionId: number, newScore: number): Promise<ScoreCard> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .put<ScoreCardWithParticipationData>(`/score-cards/${selectedBusiness}/${scoreCardId}/score`, { connectionId, newScore })
    .then(res => res.data);
}

export async function updateScoreCard(scoreCardId: number, data: ScoreCardUpdateInfo): Promise<ScoreCard> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .put<ScoreCard>(`/score-cards/${selectedBusiness}/${scoreCardId}`, data)
    .then(res => res.data);
}
