/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import Labeled from './Labeled';

type InputProps = {
  id?: string;
  label?: string;
  type: React.InputHTMLAttributes<HTMLInputElement>['type'] | 'textarea';
  value?: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  className?: string;
  error?: string;
  appendButton?: React.ReactNode;
  suffix?: string;
} & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

export default function Input({
  id,
  label,
  type,
  value,
  onChange,
  className,
  error,
  appendButton,
  suffix,
  ...props
}: InputProps) {
  if (label && !id) throw new Error('The `id` prop is required when `label` is supplied.');

  if (props.required) label += '*';

  let inputElem =
    type === 'textarea' ? (
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        className={classNames(className, { '!border-danger': !!error })}
        {...props}
      />
    ) : (
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        className={classNames(className, { '!border-danger': !!error })}
        {...props}
      />
    );

  if (suffix) inputElem = WrapWithSuffix(inputElem, suffix);

  if (appendButton) inputElem = WrapWithAppendButton(inputElem, appendButton);

  if (label && id) inputElem = WrapWithLabel(inputElem, label, id);

  inputElem = WrapWithError(inputElem, error);

  return inputElem;
}

function WrapWithLabel(children: React.ReactNode, label: string, id: string) {
  return (
    <Labeled label={label} htmlFor={id}>
      {children}
    </Labeled>
  );
}

function WrapWithError(children: React.ReactNode, error?: string) {
  return (
    <div className="w-full">
      {children}
      {error && <span className="text-sm font-medium text-danger">{error}</span>}
    </div>
  );
}

function WrapWithAppendButton(children: React.ReactNode, appendButton: React.ReactNode) {
  return (
    <div className="flex items-center">
      {children}
      {appendButton}
    </div>
  );
}

function WrapWithSuffix(children: React.ReactNode, suffix: string) {
  return (
    <div className="relative w-full">
      {children}
      <span className="pointer-events-none absolute right-3 top-3 opacity-50">{suffix}</span>
    </div>
  );
}
