import httpService from './httpService';
import version from '../../package.json';

const sendFeedback = async (feedback: string, option: string) => {
  const { userAgent } = navigator;
  const appOs = getOs(userAgent.toLowerCase());
  const appVersion = version.version;
  //navigator.platform is deprecated; for now adding the browser and its version
  const platformVersion = getBrowser(userAgent);
  //Adding user agent data for any additional info necessary
  const data = { feedback, option, appOs, appVersion, platformVersion, userAgent };
  await httpService.post(`/feedback`, data);
};

const getOs = (userAgent: string): string => {
  switch (true) {
    case userAgent.includes('windows'):
      return 'windows';
    case userAgent.includes('mac'):
      return 'macos';
    case userAgent.includes('linux'):
      return 'linux';
    case userAgent.includes('android'):
      return 'android';
    case userAgent.includes('ios'):
      return 'ios';
    default:
      return userAgent;
  }
};

const getBrowser = (userAgent: string): string => {
  if (userAgent.includes('Safari')) {
    if (userAgent.includes('Chrome')) {
      if (userAgent.includes('Edg')) return `Edge${userAgent.split('Edg')[1]}`;
      if (userAgent.includes('OPR')) return `Opera${userAgent.split('OPR')[1]}`;
      return `Chrome${userAgent.split('Chrome')[1].split(' ')[0]}`;
    }
    if (userAgent.includes('Version')) return `Safari${userAgent.split('Version')[1]}`;
  }
  return userAgent;
};

export default sendFeedback;
