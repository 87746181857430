import { useTranslation } from 'react-i18next';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { HiTrash } from 'react-icons/hi';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from '../buttons/Button';
import DropdownSelect from './DropdownSelect';
import DatePicker from './DatePicker';
import { Frequency } from '../../../types/event';
import Labeled from '../misc/Labeled';
import Slider from '../misc/Slider';
import ExtraOption from '../misc/ExtraOption';
import Input from '../misc/Input';

export interface EventDatesInfo {
  startTime?: Date;
  endTime?: Date;
  isRecurring: boolean;
  recurrenceEndDate?: Date;
  frequency?: Frequency;
  deadline?: Date;
  isDatePicker?: boolean;
}

interface EventDatesFormProps {
  info: EventDatesInfo;
  setInfo: (dates: EventDatesInfo) => void;
  staticModal?: boolean;
  setView?: (view: 'FORM' | 'DATE') => void;
  includeRecurrence?: boolean;
  className?: string;
  isDatePicker?: boolean;
  errors?: Record<string, string>;
  includeDeadline?: boolean;
}

export default function EventDatesForm({
  info,
  setInfo,
  includeRecurrence = true,
  staticModal = false,
  className,
  setView = () => {},
  isDatePicker,
  errors,
  includeDeadline = true,
}: EventDatesFormProps) {
  const { startTime, endTime, isRecurring, recurrenceEndDate, frequency, deadline } = info;

  const { t } = useTranslation();

  const minDate = useRef<Date>(new Date());

  const endTimeInputRef = useRef<HTMLInputElement>(null);
  const [addEndFlag, setAddEndFlag] = useState<boolean>(!!info.endTime);
  const [addDeadlineFlag, setAddDeadlineFlag] = useState<boolean>(!!info.deadline);
  const [staticOpen, setStaticOpen] = useState<'start' | 'end' | 'recurrence' | undefined>();

  const [deadLineNumber, setDeadLineNumber] = useState<string | undefined>();

  useLayoutEffect(() => setView(staticOpen ? 'DATE' : 'FORM'), [staticOpen]);

  useEffect(() => {
    if (deadline && startTime) {
      const diff = Math.abs(startTime.getTime() - deadline.getTime());
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      setDeadLineNumber(diffDays.toString());
    }
  }, [deadline, startTime]);

  const setStartTime = (date: Date | undefined) => {
    if (!date) return;
    const endDate = new Date(date);
    endDate.setHours(date.getHours() + 1);
    setInfo({
      ...info,
      startTime: date,
      endTime: endTime ? (new Date(endTime) < date ? endDate : endTime) : undefined,
    });
  };

  const setEndTime = (date: Date | undefined) => setInfo({ ...info, endTime: date });
  const setRecurrenceEndDate = (date: Date | undefined) =>
    setInfo({ ...info, recurrenceEndDate: date });

  const addEndTime = () => {
    if (startTime) {
      const endDate = new Date(startTime);
      endDate.setHours(startTime.getHours() + 1);
      setInfo({
        ...info,
        endTime: endDate,
      });
    }
    setAddEndFlag(true);
    if (!staticModal) setTimeout(() => endTimeInputRef.current?.click(), 10);
  };

  const removeEndTime = () => {
    setAddEndFlag(false);
    setInfo({ ...info, endTime: undefined });
  };

  const switchRecurrence = () => {
    if (isRecurring) {
      setInfo({
        ...info,
        isRecurring: false,
        frequency: undefined,
        recurrenceEndDate: undefined,
      });
      return;
    }
    const endDate = startTime ? new Date(startTime) : new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    setInfo({
      ...info,
      isRecurring: true,
      frequency: Frequency.WEEKLY,
      recurrenceEndDate: endDate,
    });
  };

  function handleChangeDaysBeforeStart(days: number): void {
    if (days === 0) {
      setInfo({ ...info, deadline: undefined });
      return;
    }

    const deadlineDate = new Date(startTime ?? new Date());
    deadlineDate.setDate(deadlineDate.getDate() - days);

    setInfo({ ...info, deadline: deadlineDate });
  }

  const setFrequency = (value: Frequency | undefined) => {
    if (!value) {
      setInfo({ ...info, frequency: value });
      return;
    }
    const endDate = new Date();
    switch (value) {
      case Frequency.DAILY:
        endDate.setDate(endDate.getDate() + 7);
        break;
      case Frequency.WEEKLY:
        endDate.setMonth(endDate.getMonth() + 1);
        break;
      case Frequency.BI_WEEKLY:
        endDate.setMonth(endDate.getMonth() + 2);
        break;
      case Frequency.MONTHLY:
        endDate.setFullYear(endDate.getFullYear() + 1);
        break;
      case Frequency.YEARLY:
        endDate.setFullYear(endDate.getFullYear() + 5);
        break;
      default:
        throw new Error('Invalid frequency');
    }
    setInfo({ ...info, frequency: value, recurrenceEndDate: endDate });
  };

  const handleStaticClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!staticModal) return;
    setStaticOpen((e.target as HTMLInputElement).id as 'start' | 'end' | 'recurrence');
  };

  const minStartDateTime = dayjs(minDate.current);
  const minEndDateTime = startTime ? dayjs(startTime) : minStartDateTime;
  const [staticDate, setStaticDate, staticMinDateTime] = (() => {
    switch (staticOpen) {
      case 'start':
        return [startTime, setStartTime, minStartDateTime];
      case 'end':
        return [endTime, setEndTime, minEndDateTime];
      case 'recurrence':
        return [recurrenceEndDate, setRecurrenceEndDate];
      default:
        return [];
    }
  })();

  const setDeadline = (date: Date | undefined) => {
    setInfo({ ...info, deadline: date });
    setDeadLineNumber(undefined);
  };

  return !staticOpen ? (
    <div className={classNames('flex flex-col gap-4', className)}>
      <Labeled label={t('component.eventDatesForm.start')}>
        <DatePicker
          slotProps={{
            textField: {
              id: 'start',
              ...(staticModal && { onClick: handleStaticClick }),
            },
          }}
          minDateTime={minStartDateTime}
          minutesStep={5}
          date={startTime}
          setDate={setStartTime}
          error={errors?.startTime}
        />
      </Labeled>

      <Slider
        label={t('component.eventDatesForm.addEnd')}
        items={[
          { label: t('general.no'), value: false },
          { label: t('general.yes'), value: true },
        ]}
        value={addEndFlag}
        onChange={(value) => {
          setAddEndFlag(value);
          if (value) addEndTime();
          else removeEndTime();
        }}
      />

      {addEndFlag && (
        <div className="flex w-full gap-4">
          <DatePicker
            ref={endTimeInputRef}
            slotProps={{
              textField: {
                id: 'end',
                ...(staticModal && { onClick: handleStaticClick }),
              },
            }}
            className="w-full"
            minDateTime={minEndDateTime}
            minutesStep={5}
            date={endTime}
            setDate={setEndTime}
            error={errors?.endTime}
          />
          <Button onClick={removeEndTime} className="rounded-full p-3" variant="tertiary">
            <HiTrash className="h-5 w-5" />
          </Button>
        </div>
      )}

      {includeRecurrence && (
        <>
          <Slider
            label={
              !isDatePicker
                ? t('component.eventDatesForm.recurringEvent')
                : t('component.eventDatesForm.recurringOption')
            }
            items={[
              { label: t('general.no'), value: false },
              { label: t('general.yes'), value: true },
            ]}
            value={isRecurring}
            onChange={switchRecurrence}
          />
          {isRecurring && (
            <>
              <Labeled
                label={
                  !isDatePicker
                    ? t('component.eventDatesForm.frequency.title')
                    : t('component.eventDatesForm.frequency.datePickerTitle')
                }
                className="flex w-full flex-col gap-1">
                <DropdownSelect
                  options={Object.values(Frequency).map((type) => ({
                    label: t(`component.eventDatesForm.frequency.${type.toLowerCase()}`),
                    value: type,
                  }))}
                  value={frequency}
                  setValue={setFrequency}
                />
              </Labeled>
              <Labeled
                label={t('component.eventDatesForm.final')}
                className="flex w-full flex-col gap-1">
                <DatePicker
                  includeTime={false}
                  slotProps={{
                    textField: {
                      id: 'recurrence',
                      ...(staticModal && { onClick: handleStaticClick }),
                    },
                  }}
                  date={recurrenceEndDate}
                  minDate={dayjs(startTime ?? new Date())}
                  setDate={setRecurrenceEndDate}
                />
              </Labeled>
            </>
          )}
        </>
      )}

      {includeDeadline && (
        <ExtraOption
          label={t('component.eventDatesForm.deadline')}
          active={addDeadlineFlag}
          onChange={(value) => {
            setAddDeadlineFlag(value);
            if (!value) setDeadline(undefined);
          }}>
          {!isRecurring ? (
            <DatePicker
              className="w-full"
              date={deadline}
              minDateTime={dayjs(new Date())}
              minutesStep={5}
              maxDate={(endTime ?? startTime) ? dayjs(endTime ?? startTime) : undefined}
              setDate={setDeadline}
              error={errors?.deadline}
            />
          ) : (
            <Input
              type="number"
              min="1"
              value={deadLineNumber}
              placeholder={t('component.eventDatesForm.daysBeforePlaceholder')}
              onChange={(e) => handleChangeDaysBeforeStart(Number(e.target.value))}
              error={errors?.deadline}
              suffix={deadLineNumber ? t('page.createEvent.fields.deadline.suffix') : ''}
            />
          )}
        </ExtraOption>
      )}
    </div>
  ) : (
    <DatePicker
      static
      date={staticDate ?? undefined}
      setDate={setStaticDate ?? (() => {})}
      onClose={() => setStaticOpen(undefined)}
      minDateTime={staticMinDateTime}
    />
  );
}
