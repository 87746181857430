import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaDownload, FaLock } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import Button from '../../components/buttons/Button';
import IconBadge from '../../components/icons/IconBadge';
import { downloadConnectionsExcel } from '../../../utils/fileUtils';
import { RootState } from '../../../redux/reducers';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import ChangeSelectionModal from '../../components/modals/ChangeSelectionModal';
import { ConnectionIdentifier, SubscriptionType } from '../../../types/misc';
import SelectExportedFields from '../../components/selection-lists/SelectExportedFields';
import {
  mapFieldIdentifiersToString,
  mapIdentifiersToConnections,
} from '../../../utils/connectionUtils';
import ModalLayout from '../../components/layouts/ModalLayout';
import PremiumRequiredCard from '../../components/cards/PremiumRequiredCard';

export default function ExportExcelPage(): JSX.Element {
  const { t } = useTranslation();
  const connections = useSelector((state: RootState) => state.connections);
  const business = useSelectedBusiness();

  const [selectedConnections, setSelectedConnections] = useState<ConnectionIdentifier[]>(
    connections.map(({ id, type, userId }) => ({ id, type, userId })),
  );

  const [selectedFields, setSelectedFields] = useState<(string | number)[]>([]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedConnections(connections.map(({ id, type, userId }) => ({ id, type, userId })));
  }, [connections]);

  if (!business) {
    return <></>;
  }

  const customFields = [...(business.inviteFields?.customFields ?? [])];

  return (
    <div className="grid grid-cols-8">
      <div className="col-span-8 mb-6 flex flex-col gap-2 border-b border-primary-300 pb-6 lg:col-span-5">
        <div className="mb-2 ml-1 flex items-center">
          <div className="flex w-full flex-col gap-4">
            <h1 className="flex items-center gap-4 font-serif text-3xl">
              <IconBadge icon={FaDownload} />
              {t('page.dashboard.downloadExcel.title')}
            </h1>
            <div className="rounded-2xl bg-secondary-200 p-4 text-justify">
              <Trans i18nKey="page.dashboard.downloadExcel.subtitle">
                You are about to download <strong>personal information</strong>. At Vera Connect, we
                understand that this is sometimes needed for practical reasons. However, please be
                very careful with this information and prevent that this information ends up with
                people that shouldn&apos;t have access to it. Also, please delete all copies if
                people that included ask you to do so.
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-8 flex flex-col gap-2 lg:col-span-5">
        <h2 className="ml-2 font-serif text-xl">
          {t('page.dashboard.downloadExcel.selectContacts')}
        </h2>
        <div className="mb-2 flex justify-between pl-2">
          <span className="flex">
            <p className="font-semibold text-primary">{selectedConnections.length}</p>/
            {connections.length}
            {t('page.payment.selected')}
          </span>
          <p
            data-testid="edit-selection"
            onClick={(): void => setModalOpen(true)}
            className="cursor-pointer underline">
            {t('page.payment.editSelection')}
          </p>
        </div>
        <ChangeSelectionModal
          selection={selectedConnections}
          setSelection={setSelectedConnections}
          open={modalOpen}
          setOpen={setModalOpen}
          type="none"
        />
        <h2 className="ml-2 font-serif text-xl">{t('page.dashboard.downloadExcel.selectData')}</h2>
        <SelectExportedFields
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          className="mb-2 px-2"
        />
        <DownloadExcelButton
          selectedConnections={selectedConnections}
          selectedColumns={mapFieldIdentifiersToString(customFields, selectedFields)}
        />
      </div>
    </div>
  );
}

type DownloadExcelButtonProps = {
  selectedConnections: ConnectionIdentifier[];
  selectedColumns: string[];
};

function DownloadExcelButton({
  selectedConnections,
  selectedColumns,
}: DownloadExcelButtonProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.dashboard.downloadExcel' });
  const business = useSelectedBusiness();
  const connections = useSelector((state: RootState) => state.connections);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const activeSubscription = SubscriptionType.PRO as SubscriptionType;

  return (
    <>
      <Button
        type="button"
        className="col-span-8 flex h-12 items-center justify-center self-end rounded-full bg-primary-300 text-white lg:col-span-3"
        onClick={() => {
          if (activeSubscription === SubscriptionType.FREE) {
            setModalOpen(true);
          } else {
            downloadConnectionsExcel(
              mapIdentifiersToConnections(connections, selectedConnections),
              business?.name ?? '',
              selectedColumns,
            );
          }
        }}
        disabled={selectedConnections.length === 0}>
        {activeSubscription === SubscriptionType.FREE && <FaLock />} {t('button')}
      </Button>
      <ModalLayout closeButton open={modalOpen} setOpen={setModalOpen}>
        <PremiumRequiredCard />
      </ModalLayout>
    </>
  );
}
