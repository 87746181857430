import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { isAndroid, isIOS } from 'react-device-detect';
import useAuth from '../../hooks/account/useAuth';
import userService from '../../services/userService';
import useLanguage from '../../hooks/account/useLanguage';
import ProfilePicture from '../components/misc/ProfilePicture';
import useProfilePicture from '../../hooks/account/useProfilePicture';

export default function ProfilePage() {
  const auth = useAuth();
  const { t } = useTranslation();
  const profilePicture = useProfilePicture();
  const lang = useLanguage();

  useEffect(() => {
    userService.getProfilePicture();
  }, []);

  const name = `${auth?.firstName} ${auth?.lastName}`;

  return (
    <>
      <h1 className="mb-4 text-2xl font-semibold">{t('page.profile.subtitle')}</h1>
      <ProfilePicture
        picture={profilePicture}
        alias={name.replace(' ', '+')}
        className="h-40 w-40 text-[50px]"
      />
      <div className="mt-4 flex flex-col">
        <h2 className="text-xl font-semibold">{name}</h2>
        <div className="flex items-center gap-2">
          <HiOutlineEnvelope
            className="icon mt-[1px] h-5 w-5 font-bold text-primary-900"
            strokeWidth="2"
          />
          <h3 className="text-lg font-medium">{auth?.email}</h3>
        </div>
      </div>

      <p>{t('page.profile.downloadApp')}</p>

      <div className="mt-4 flex flex-col gap-2 lg:flex-row lg:gap-4">
        {!isIOS && (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.VeraConnect.Frontend&hl=nl&pcampai[…]ampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              className="h-[56px]"
              alt="Ontdek het op Google Play"
              src={`${process.env.PUBLIC_URL}/assets/ps/${lang}.svg`}
            />
          </a>
        )}
        {!isAndroid && (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/vera-connect/id1667301680?itsct=apps_box_badge&amp;itscg=30200">
            <img
              className="h-[56px]"
              alt="App store badge"
              src={`${process.env.PUBLIC_URL}/assets/as/${lang}.svg`}
            />
          </a>
        )}
      </div>
    </>
  );
}
