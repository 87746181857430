import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { HiArrowRight } from 'react-icons/hi';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { store } from '../../redux/store';
import { setSelectedBusiness, wipeSelectedBusiness } from '../../redux/slices/applicationSlice';
import { setConnections } from '../../redux/slices/connectionsSlice';
import useSelectedBusiness from '../../hooks/business/useSelectedBusiness';
import useBusinessTranslation from '../../hooks/useBusinessTranslation';
import ApiImage from '../components/misc/ApiImage';
import { businessTypeIcons } from '../../utils/iconUtils';
import Icon from '../components/icons/Icon';

export default function BusinessOverviewPage(): JSX.Element {
  const { t } = useBusinessTranslation();
  const businesses = useSelector((state: RootState) => state.application.businesses);
  const navigate = useNavigate();
  const selectedBusiness = useSelectedBusiness();

  useEffect(() => {
    if (!businesses) return;
    store.dispatch(wipeSelectedBusiness());
    store.dispatch(setConnections([]));
  }, [selectedBusiness]);

  const handleBusinessSelect = (businessId: number) => {
    store.dispatch(setSelectedBusiness(businessId));
    navigate('/overview');
  };

  return (
    <>
      <div className="mb-2 ml-2">
        <h1 className="font-serif text-3xl font-semibold">{t('page.businessOverview.title')}</h1>
        <p className="py-1">{t('page.businessOverview.subtitle')}</p>
      </div>
      <div className="flex flex-wrap gap-8">
        {businesses?.map((business) => (
          <div
            onClick={() => handleBusinessSelect(business.businessId!)}
            className="group relative w-48 cursor-pointer rounded-lg border border-primary-900 transition-all hover:bg-primary-300 hover:bg-opacity-10">
            {business.picture ? (
              <ApiImage
                alt="business-profile"
                path={business.picture}
                className="h-48 w-full rounded-t-lg object-cover"
              />
            ) : (
              <div className="mx-auto flex h-48 w-full items-center rounded-t-lg text-primary-900">
                <Icon
                  className="mx-auto my-auto h-20 w-20 text-primary-900"
                  icon={businessTypeIcons[business.type]}
                />
              </div>
            )}

            <div className="wrap flex w-full flex-col flex-wrap justify-between gap-2 border-t border-primary-900 p-4 py-3">
              <p className="w-full overflow-hidden break-words font-medium">{business.name}</p>
              <button type="button" className="bg-vera-green-lightest w-fit rounded-lg p-1">
                <HiArrowRight className="mx-auto h-6 w-6 text-black" />
              </button>
            </div>
          </div>
        ))}
        <div
          onClick={() => navigate('/onboarding')}
          className="group flex w-48 cursor-pointer items-center rounded-lg border-2 border-dashed border-primary-900 hover:border-opacity-80 hover:bg-primary-300 hover:bg-opacity-10">
          <div className="text-md mx-auto flex flex-col items-center font-medium text-primary-900 group-hover:text-opacity-80">
            <AiOutlinePlus className="h-20 w-20 stroke-1" />
            {t('page.businessOverview.new')}
          </div>
        </div>
      </div>
    </>
  );
}
