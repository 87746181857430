/* eslint-disable react/destructuring-assignment */
import React, { HTMLAttributes } from 'react';
import { FaPaperPlane } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import { ConnectionType } from '../../../redux/slices/connectionsSlice';

interface ConnectionTypeBadgeProps {
  type: ConnectionType;
  pending?: boolean;
  className?: string;
}

export default function ConnectionTypeBadge({
  type,
  className = '',
  pending = false,
}: ConnectionTypeBadgeProps) {
  if (type === ConnectionType.B2C_CONNECTION) return <VerifiedBadge className={className} />;
  if (type === ConnectionType.PERSONAL_MOMENT_CONNECTION)
    return <VerifiedBadge className={className} />;
  if (pending) return <PendingBadge className={className} />;
  return <UnverifiedBadge className={className} />;
}

function BadgeDiv(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={twMerge(
        'relative flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-[6px] p-[6px]',
        props.className,
      )}
    />
  );
}

interface BadgeProps {
  className?: string;
}

export function VerifiedBadge({ className = '' }: BadgeProps) {
  return (
    <BadgeDiv className={twMerge('bg-primary-300', className)}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/vera_smile.svg`}
        alt="Verified"
        className="w-full"
      />
    </BadgeDiv>
  );
}

export function PendingBadge({ className = '' }: BadgeProps) {
  return (
    <BadgeDiv className={twMerge('bg-black', className)}>
      <FaPaperPlane className="peer w-full text-secondary-50" />
    </BadgeDiv>
  );
}

export function UnverifiedBadge({ className = '' }: BadgeProps) {
  return (
    <BadgeDiv className={twMerge('bg-secondary', className)}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/vera_sad.svg`}
        alt="connection"
        className="peer w-full"
      />
    </BadgeDiv>
  );
}
