import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CgSpinner } from 'react-icons/cg';
import classNames from 'classnames';
import { FaCircleCheck } from 'react-icons/fa6';
import { Trans } from 'react-i18next';
import authService from '../../../services/authService';
import { ApplicationType } from '../../../redux/slices/applicationSlice';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import Button from '../../components/buttons/Button';

/* Login steps */
type LoginStep = 'EMAIL_FORM' | 'SENDING_EMAIL' | 'EMAIL_SENT' | 'EMAIL_ERROR';

/* Login page (parent component) */
export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();

  const [step, setStep] = useState<LoginStep>('EMAIL_FORM');
  const [email, setEmail] = useState<string>('');
  const { t } = useBusinessTranslation();

  const { isAuthenticated: auth } = useSelector((state: any): ApplicationType => state.application);

  useEffect((): void => {
    if (auth) navigate('/');
  }, [auth]);

  return (
    <div className="mx-auto my-10 flex h-full min-h-[calc(100vh-9rem)] w-full max-w-7xl flex-1 flex-col gap-4 md:flex-row md:justify-between md:gap-10">
      <div className="mx-auto flex w-[90%] flex-col items-center justify-center gap-4 rounded-3xl bg-secondary-200 px-8 py-8 md:w-[65%] md:gap-16 md:py-4">
        <h1 className="text-center font-serif text-3xl font-medium md:text-4xl">
          {t('page.login.welcomeMessage')}
        </h1>
        <img
          src={`${process.env.PUBLIC_URL}/assets/landing/dashboard.png`}
          alt="Dashboard"
          className="w-[50%] md:w-[70%]"
        />
        <a
          className="text-center"
          href="https://www.veraconnect.com/students"
          target="_blank"
          rel="noopener noreferrer">
          <Trans i18nKey="page.login.moreInformation">
            Do you want to know more about Vera Community?{' '}
            <span className="font-semibold underline">Click here</span>
          </Trans>
        </a>
      </div>
      <div className="flex h-fit w-full flex-col justify-center gap-4 rounded-3xl bg-secondary-50 pt-4 md:min-h-[600px] md:w-[43%] md:min-w-[420px]">
        <div className="flex w-full items-center gap-2 px-4 md:p-0">
          <div className="flex flex-col gap-2">
            <h1 className="font-serif text-2xl font-medium">
              {step === 'EMAIL_SENT' ? t('page.login.emailSentTitle') : t('auth.login')}
            </h1>
            <p className="text-sm">
              {step === 'EMAIL_SENT'
                ? t('page.login.emailSentSubtitle')
                : t('page.login.description')}
            </p>
          </div>
        </div>
        <div className="flex h-full w-full flex-col rounded-b-3xl px-4 md:px-0">
          <div className="-mt-2 flex h-full flex-1 flex-col gap-4">
            {step === 'EMAIL_SENT' ? (
              <EmailResend email={email} />
            ) : (
              <LoginForm step={step} setStep={setStep} email={email} setEmail={setEmail} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/* Login form (shown initially, while sending and on email error) */
type LoginFormProps = {
  step: LoginStep;
  setStep: Dispatch<SetStateAction<LoginStep>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
};

function LoginForm({ step, setStep, email, setEmail }: LoginFormProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const navigate = useNavigate();

  /*
   * Handles the login, doesn't allow empty fields.
   * @returns {void}
   */
  const handleLogin = async (): Promise<void> => {
    if (email === '') {
      toast.error(t('toast.error.email'));
      setStep('EMAIL_ERROR');
      return;
    }
    setStep('SENDING_EMAIL');
    try {
      await authService.generateMagicLink(email);
      setStep('EMAIL_SENT');
    } catch (_error) {
      setStep('EMAIL_ERROR');
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <label className="mb-1 pr-2 font-medium">{t('dataType.email')}</label>
        <input
          placeholder={t('page.login.emailPlaceholder')}
          name="email"
          type="email"
          disabled={step === 'SENDING_EMAIL' || step === 'EMAIL_SENT'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="flex h-full min-h-10 flex-1 flex-col items-end justify-end gap-5 align-bottom">
        <Button
          variant="primary"
          disabled={step === 'SENDING_EMAIL' || step === 'EMAIL_SENT'}
          onClick={handleLogin}>
          <CgSpinner
            className={classNames('animate-spin', {
              hidden: step !== 'SENDING_EMAIL',
            })}
          />
          {t('page.login.button')}
        </Button>
        <div className="-mt-2 flex justify-end">
          <span className="text-sm text-primary-900">
            {t('auth.noAccountYet')}{' '}
            <b
              className="cursor-pointer border-b border-primary-900 font-semibold"
              onClick={() => navigate('/onboarding')}>
              {t('page.login.make')}
            </b>
          </span>
        </div>
      </div>
    </>
  );
}

/* Email sent message (shown after email is sent successfully) */
type EmailSentMessageProps = {
  email: string;
};

function EmailResend({ email }: EmailSentMessageProps): JSX.Element {
  const { t } = useBusinessTranslation();

  const sendAgain = async (): Promise<void> => {
    authService.generateMagicLink(email).then(() => toast.success(t('toast.success.emailSent')));
  };

  return (
    <>
      <div className="relative w-full">
        <input name="email" type="email" value={email} disabled />
        <FaCircleCheck
          className="absolute right-2 top-[50%] -translate-y-[50%] text-primary"
          size={24}
        />
      </div>
      <div className="flex flex-col items-end justify-end gap-2">
        <div className="mr-1 text-sm text-primary-900">
          {t('page.login.noEmailQuestion')}{' '}
          <button
            type="button"
            className="cursor-pointer font-bold hover:opacity-80"
            onClick={sendAgain}>
            {t('page.login.sendAgain')}
          </button>
        </div>
      </div>
    </>
  );
}
