export function getExpectedDeliveryDate(date: Date | undefined, firstPotentialDeliveryDate: Date) {
  if (!date) {
    return undefined;
  }
  date = new Date(date);
  const weekday = date.getDay();
  let expectedDeliveryDate = date;
  if (weekday === 0) {
    expectedDeliveryDate.setDate(expectedDeliveryDate.getDate() - 1);
  } else if (weekday === 1) {
    expectedDeliveryDate.setDate(expectedDeliveryDate.getDate() - 2);
  }

  if (expectedDeliveryDate < firstPotentialDeliveryDate) {
    expectedDeliveryDate = firstPotentialDeliveryDate;
  }

  return expectedDeliveryDate;
}

export function getFirstPotentialDeliveryDate() {
  const today = new Date();
  const firstPotentialDeliveryDate = new Date(today.setDate(today.getDate() + 2));
  const weekday = firstPotentialDeliveryDate.getDay();

  if (weekday === 0) {
    firstPotentialDeliveryDate.setDate(firstPotentialDeliveryDate.getDate() + 2);
  } else if (weekday === 1) {
    firstPotentialDeliveryDate.setDate(firstPotentialDeliveryDate.getDate() + 1);
  }
  return firstPotentialDeliveryDate;
}

export function getSendDateFromDeliveryDate(date: Date) {
  const sendDate = new Date(date);
  sendDate.setDate(sendDate.getDate() - 1);
  return sendDate;
}
