import { SubscriptionType } from '../types/misc';
import { subscriptionService } from '../services/subscriptionService';
import { CommunicationType } from '../types/communication';

export const subscriptionFeatures = [
  // Free
  {
    i18nKey: 'memberList',
    [SubscriptionType.FREE]: true,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  {
    i18nKey: 'importExcel',
    [SubscriptionType.FREE]: true,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  {
    i18nKey: 'datepicker',
    [SubscriptionType.FREE]: true,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  {
    i18nKey: 'events',
    [SubscriptionType.FREE]: true,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  // Base
  {
    i18nKey: 'exportMemberList',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  {
    i18nKey: 'exportAttendance',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  {
    i18nKey: 'attendanceDashboard',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  {
    i18nKey: 'sendPushNotifications',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: true,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  // Pro
  {
    i18nKey: 'sendEmails',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: false,
    [SubscriptionType.PRO]: true,
    isComingSoon: false,
  },
  {
    i18nKey: 'lists',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: false,
    [SubscriptionType.PRO]: true,
    isComingSoon: true,
  },
  {
    i18nKey: 'plannedCommunication',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: false,
    [SubscriptionType.PRO]: true,
    isComingSoon: true,
  },
  {
    i18nKey: 'aiCommunication',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: false,
    [SubscriptionType.PRO]: true,
    isComingSoon: true,
  },
  {
    i18nKey: 'crmConnection',
    [SubscriptionType.FREE]: false,
    [SubscriptionType.BASE]: false,
    [SubscriptionType.PRO]: true,
    isComingSoon: true,
  },
];

export const baseSubscriptionPrices = {
  [SubscriptionType.FREE]: 0,
  [SubscriptionType.BASE]: 10,
  [SubscriptionType.PRO]: 10,
};

type PriceRangeIdentifier = {
  priceId: string | undefined;
  min: number;
  max: number;
  fee: number;
};

const baseId = process.env.REACT_APP_SUBSCRIPTIONS_BASE_ID;
const proId = process.env.REACT_APP_SUBSCRIPTIONS_PRO_ID;

export const memberCountPrices: { [key in SubscriptionType]: PriceRangeIdentifier[] } = {
  [SubscriptionType.FREE]: [{ priceId: undefined, min: 0, max: Infinity, fee: 0 }],
  [SubscriptionType.BASE]: [
    { priceId: baseId, min: 0, max: 50, fee: 10 },
    { priceId: baseId, min: 51, max: 100, fee: 15 },
    { priceId: baseId, min: 101, max: 250, fee: 20 },
    { priceId: baseId, min: 251, max: 1000, fee: 25 },
    { priceId: baseId, min: 1001, max: Infinity, fee: -1 },
  ],
  [SubscriptionType.PRO]: [
    { priceId: proId, min: 0, max: 50, fee: 10 },
    { priceId: proId, min: 51, max: 100, fee: 15 },
    { priceId: proId, min: 101, max: 250, fee: 20 },
    { priceId: proId, min: 251, max: 1000, fee: 25 },
    { priceId: proId, min: 1001, max: Infinity, fee: -1 },
  ],
};

export const subscriptionColors = {
  [SubscriptionType.FREE]: {
    bg: 'bg-secondary',
    text: 'text-secondary-50',
    svg: 'text-secondary',
    border: 'border-secondary-50',
  },
  [SubscriptionType.BASE]: {
    bg: 'bg-primary-300',
    text: 'text-secondary-50',
    svg: 'text-primary-300',
    border: 'border-primary-900',
  },
  [SubscriptionType.PRO]: {
    bg: 'bg-primary-900',
    text: 'text-secondary-50',
    svg: 'text-primary-900',
    border: 'border-secondary-50',
  },
};

export function getUsageLimit(
  type: SubscriptionType,
  numberOfMembers: number,
): PriceRangeIdentifier {
  console.assert(
    numberOfMembers >= 0 && numberOfMembers <= Infinity,
    'Number of members must be a non-negative number',
  );

  return memberCountPrices[type].find(
    ({ min, max }) => numberOfMembers >= min && numberOfMembers <= max,
  )!;
}

export async function downloadInvoice(invoiceId: string) {
  const blob = await subscriptionService.fetchInvoicePdf(invoiceId);

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `invoice_${invoiceId}.pdf`;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function hasRequiredSubscriptionForCommunication(type: CommunicationType, subscription: SubscriptionType): boolean {
  return type === CommunicationType.POSTCARD
    || (
      (type === CommunicationType.EMAIL || type === CommunicationType.NOTIFICATION)
      && subscription === SubscriptionType.PRO
    );
}
