export enum BasicInfo {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  BUSINESSNAME = 'BUSINESSNAME',
  COMMUNICATION_NAME = 'COMMUNICATION_NAME',
  ADDRESS = 'ADDRESS',
  PHONENUMBER = 'PHONENUMBER',
  EMAIL = 'EMAIL',
  BIRTHDATE = 'BIRTHDATE',
}
