import React, { useRef } from 'react';

type ProgressBarProps = {
  progress: number;
  variant?: 'primary' | 'secondary';
};

export default function ProgressBar({ progress, variant = 'primary' }: ProgressBarProps) {
  progress = Math.round(progress || 0);

  const textRef = useRef<HTMLParagraphElement>() as React.MutableRefObject<HTMLInputElement>;

  const filledWidth = `${Math.min(Math.max(progress, 0), 100)}%`;
  const textPosition = (100 * progress) / 100 / 2;
  const textWidthOffset = ((textRef.current?.offsetWidth ?? 0) / 2) * -1;

  const backgroundColor = variant === 'primary' ? 'bg-primary' : 'bg-secondary';
  const textColor = variant === 'primary' ? 'text-secondary-50' : 'text-primary-900';

  return (
    <div
      className={`relative flex h-6 w-full overflow-hidden rounded-full bg-opacity-20 ${
        progress === 0 && 'flex-row justify-center'
      } ${backgroundColor}`}>
      {progress === 0 && <p className="leading-2 mx-auto my-auto text-xs">{progress}%</p>}
      <div className={`h-full ${backgroundColor}`} style={{ width: filledWidth }} />
      {progress >= 10 && (
        <p
          ref={textRef}
          style={{
            left: `${textPosition}%`,
            transform: `translate(${textWidthOffset}px, 0px)`,
          }}
          className={`leading-2 absolute top-0 flex h-full items-center justify-center text-xs ${textColor}`}>
          {progress}%
        </p>
      )}
    </div>
  );
}
